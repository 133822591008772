import React, { useEffect, useState } from "react"
import { Row, Col, Dropdown, DropdownMenu, DropdownItem } from "reactstrap"
import { Link } from "react-router-dom"
// import week days and month array
import weekDays from "common/weekDays"
import months from "common/months"

import SweetAlert from "react-bootstrap-sweetalert"
import { deleteEvent } from "../../store/events/actions"
import { useDispatch } from "react-redux"

function EventOrganizerCard(props) {
  const dispatch = useDispatch()
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [selectedEventId, setSelectedEventId] = useState(null)

  useEffect(() => {
    let dateObj = new Date(props?.data?.eventDate)
    let day = dateObj.getDay()
    let month = dateObj.getMonth() + 1
    let year = dateObj.getFullYear()
    let date = dateObj.getDate()
    //
    day = weekDays.find(d => d.value == day)
    month = months.find(m => m.value == month)
    //
    setDay(day?.flag)
    setDate(date)
    setMonth(month?.label)
    setYear(year)
  }, [day, date, month, year])

  const [singlebtn, setSinglebtn] = useState(false)
  const deleteEventHandler = id => {
    dispatch(deleteEvent(id))
  }
  return (
    <>
      <Col xl={3} lg={4} sm={6} className="mb-2">
        {deleteAlert && (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setDeleteAlert(false)
              deleteEventHandler(selectedEventId)
            }}
            onCancel={() => setDeleteAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        )}
      </Col>
      <Row className="my-5">
        <Col sm={1} md={1} lg={1} className="order-1 mb-2  mb-lg-0">
          <div className="d-flex flex-column align-items-center">
            <p style={{ marginBottom: "2px" }}>{day}</p>
            <h6
              style={{ fontWeight: "bold", fontSize: "20px", color: "black" }}
            >
              {date}
            </h6>
          </div>
        </Col>
        <Col
          sm={12}
          md={12}
          lg={6}
          className="order-3 order-md-2 mt-sm-3 mb-3 mb-lg-0 mt-lg-0 "
        >
          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap justify-content-between">
              <div
                className="d-flex flex-wrap align-items-center text-muted"
                style={{ fontSize: "14px" }}
              >
                {props?.data?.featured ? (
                  <div style={{ color: "black" }} className="me-4">
                    <i className="mdi mdi-bookmark h5 me-1 text-success"></i>
                    Featured
                  </div>
                ) : (
                  ""
                )}
                <div className="me-4">
                  {props.data.status == "completed" ? (
                    <span className="badge rounded-pill badge-soft-success font-size-12">
                      Completed
                    </span>
                  ) : (
                    <span className="badge rounded-pill badge-soft-warning font-size-12">
                      {props?.status}
                    </span>
                  )}
                </div>

                <div style={{ color: "#afafc0" }}>
                  <i className="mdi mdi-calendar-check h5 me-2 text-success"></i>
                  {month}
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  {date}
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  at
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  {props?.startTime}
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  -
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  {props?.data?.requiredTime}
                </div>
              </div>
              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
              >
                <i
                  className="mdi mdi-pencil fs-3"
                  style={{ cursor: "pointer", color: "#afafc0" }}
                  onClick={() => setSinglebtn(!singlebtn)}
                />
                <DropdownMenu right>
                  <DropdownItem>
                    <Link
                      to={{
                        pathname: `/organizer-edit-event/${props.id}`,
                        state: props.data,
                      }}
                    >
                      <div className="d-flex">
                        <span>
                          <i
                            className="mdi mdi-border-color me-2"
                            style={{
                              color: "green",
                              fontSize: "18px",
                            }}
                          ></i>
                        </span>{" "}
                        Edit
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <div
                      className="d-flex align-items-center"
                      //  onClick={() => deleteEventHandler(props?.id)}
                      onClick={() => {
                        setSelectedEventId(props?.id)
                        setDeleteAlert(true)
                      }}
                    >
                      <span>
                        <i
                          className="mdi mdi-delete me-2"
                          style={{
                            color: "#FF0000",
                            fontSize: "18px",
                          }}
                        ></i>
                      </span>
                      Delete
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="my-2">
              <Link
                to={{
                  pathname: `/organizer-event-details/${props.id}`,
                  state: props.data,
                }}
                style={{ cursor: "pointer" }}
              >
                <h1
                  style={{
                    fontSize: "22px",
                    color: "black",
                  }}
                >
                  {props?.title}
                </h1>
              </Link>
            </div>
            <div>
              <p
                style={{
                  fontSize: "16px",
                }}
              >
                {props?.eventLocation}
              </p>
            </div>
            <p style={{ fontSize: "16px" }} className="text-muted">
              {props?.description?.substring(0, 250)}
              {" ..."}
            </p>
          </div>
        </Col>
        <Col sm={11} md={12} lg={5} className="mb-3 order-2 order-md-3">
          <div>
            <Link
              to={{
                pathname: `/event-details/${props.id}`,
                state: props.data,
              }}
            >
              <img
                src={props?.attachments?.[0]}
                alt="event"
                style={{
                  display: "block",
                  minWidth: "100%",
                  width: "100%",
                  minHeight: "350px",
                  maxHeight: "350px",
                  height: "100%",
                  borderRadius: "20px",
                }}
              />
            </Link>
          </div>
        </Col>
      </Row>
      <hr className="mt-3" />
    </>
  )
}

export default EventOrganizerCard
