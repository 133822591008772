export const CREATE_ORDER_BY_EVENT_MANAGER = "CREATE_ORDER_BY_EVENT_MANAGER"
export const ON_CREATE_ORDER_BY_EVENT_MANAGER =
  "ON_CREATE_ORDER_BY_EVENT_MANAGER"

export const CREATE_ORDER_BY_EVENT_ORGANIZER = "CREATE_ORDER_BY_EVENT_ORGANIZER"
export const ON_CREATE_ORDER_BY_EVENT_ORGANIZER =
  "ON_CREATE_ORDER_BY_EVENT_ORGANIZER"

export const CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE =
  "CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE"
export const ON_CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE =
  "ON_CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE"

export const CREATE_ORDER_FOR_EVENT_MANAGER = "CREATE_ORDER_FOR_EVENT_MANAGER"
export const ON_CREATE_ORDER_FOR_EVENT_MANAGER =
  "ON_CREATE_ORDER_FOR_EVENT_MANAGER"

export const CREATE_ORDER_FOR_VENUE_OWNER = "CREATE_ORDER_FOR_VENUE_OWNER"
export const ON_CREATE_ORDER_FOR_VENUE_OWNER = "ON_CREATE_ORDER_FOR_VENUE_OWNER"

export const CREATE_ORDER_BY_VENUE_OWNER = "CREATE_ORDER_BY_VENUE_OWNER"
export const ON_CREATE_ORDER_BY_VENUE_OWNER = "ON_CREATE_ORDER_BY_VENUE_OWNER"

export const GET_ORDER_BY_EVENT_ID = "GET_ORDER_BY_EVENT_ID"
export const ON_GET_ORDER_BY_EVENT_ID = "ON_GET_ORDER_BY_EVENT_ID"

export const GET_ORDER_BY_EVENT_ID_VO = "GET_ORDER_BY_EVENT_ID_VO "
export const ON_GET_ORDER_BY_EVENT_ID_VO = "ON_GET_ORDER_BY_EVENT_ID_VO "

export const GET_ORDERS = "GET_ORDERS"
export const ON_GET_ORDERS = "ON_GET_ORDERS"

export const GET_MY_ORDERS = "GET_MY_ORDERS"
export const ON_GET_MY_ORDERS = "ON_GET_MY_ORDERS"

export const GET_ORDERS_STATISTICS = "GET_ORDERS_STATISTICS"
export const ON_GET_ORDERS_STATISTICS = "ON_GET_ORDERS_STATISTICS"

export const API_ERROR = "API_ERROR"
export const API_SUCCESS = "API_SUCCESS"
