import React from "react"
import MetaTags from "react-meta-tags"
import OrderCard from "./OrderCard"
import NoData from "pages/Utility/NoData"

const OrderList = ({ orders }) => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>My Orders</title>
      </MetaTags>
      {!orders.length > 0 ? (
        <NoData />
      ) : (
        <div>
          {orders.map((order, index) => (
            <OrderCard order={order} key={index} />
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

export default OrderList
