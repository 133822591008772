export const ADD_AVAILABILITIES = "ADD_AVAILABILITIES"
export const ON_ADD_AVAILABILITIES = "ON_ADD_AVAILABILITIES"

export const DELETE_AVAILABILITIES = "DELETE_AVAILABILITIES"
export const ON_DELETE_AVAILABILITIES = "ON_DELETE_AVAILABILITIES"

export const GET_AVAILABILITIES = "GET_AVAILABILITIES"
export const ON_GET_AVAILABILITIES = "ON_GET_AVAILABILITIES"

export const ADD_VENUE_DETAILS = "ADD_VENUE_DETAILS"
export const ON_ADD_VENUE_DETAILS = "ON_ADD_VENUE_DETAILS"

export const GET_VENUE_DETAILS = "ADD_VENUE_DETAILS"
export const ON_GET_VENUE_DETAILS = "ON_ADD_VENUE_DETAILS"

export const GET_MANAGERS = "GET_MANAGERS"
export const ON_GET_MANAGERS = "ON_ADD_MANAGERS"

export const API_SUCCESS = "API_SUCCESS"
export const API_ERROR = "API_ERROR"

export const GET_ALL_MANAGERS = "GET_ALL_MANAGERS"
export const ON_GET_ALL_MANAGERS = "ON_GET_ALL_MANAGERS"

export const GET_MANAGER = "GET_MANAGER"
export const ON_GET_MANAGER = "ON_GET_MANAGER"

export const GET_EVENT_MANAGERS = "GET_EVENT_MANAGERS"
export const ON_GET_EVENT_MANAGERS = "ON_GET_EVENT_MANAGERS"
