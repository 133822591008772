import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  Button,
  Label,
  FormFeedback,
  Container,
  Input,
} from "reactstrap"
// Formik import * as Yup from "yup"
import { Formik } from "formik"
import * as Yup from "yup"
import { useHistory } from "react-router-dom"
// import images
import logoImg from "../../../assets/images/Default.png"
// actions
import {
  editProfile,
  resetProfileFlag,
} from "../../../store/auth/profile/actions"
import { useDispatch, useSelector } from "react-redux"
import "react-toastify/dist/ReactToastify.css"
// Alert
import { UncontrolledAlert } from "reactstrap"
// css
import "./Profile.css"

const validationSchema = Yup.object({
  number: Yup.string().required("Please Enter Your Number"),
  name: Yup.string().required("Please Enter Your Name"),
})
const VenueOwnerProfile = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [newImage, setNewImage] = useState(null)

  const { loading, error, success } = useSelector(state => ({
    loading: state.Profile.loading,
    error: state.Profile.error,
    success: state.Profile.success,
  }))

  var user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      user = JSON.parse(localStorage.getItem("authUser"))
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [success, error])

  const onImageChange = event => {
    if (event.target.files[0]) {
      setNewImage(URL.createObjectURL(event.target.files[0]))
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          img: user?.img || {},
          name: user?.name || "",
          number: user?.number || "",
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          dispatch(editProfile({ user: values, history }))
        }}
      >
        {({
          errors,
          setFieldValue,
          values,
          handleBlur,
          handleChange,
          touched,
          handleSubmit,
        }) => {
          return (
            <div
              className="account-pages mt-sm-5 pb-5"
              style={{ width: "100%", backgroundColor: "#ffffff" }}
            >
              <Container>
                <Row className="justify-content-center">
                  <Col sm={12} md={12} lg={10} xl={8}>
                    <Card>
                      <Row>
                        <Col sm={12} md={5} xl={4}>
                          <div
                            className="bg py-4 d-flex flex-column align-items-center"
                            style={{ height: "100%", color: "#fff" }}
                          >
                            <div className="d-flex flex-column mb-4">
                              {newImage ? (
                                <img
                                  className="rounded-circle"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  src={newImage}
                                  alt="Header Avatar"
                                />
                              ) : (
                                <img
                                  className="rounded-circle"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  src={user?.img ? user?.img : logoImg}
                                  alt="Header Avatar"
                                />
                              )}
                              <div>
                                <Label className="form-label" for="img">
                                  <i
                                    className="mdi mdi-camera h5"
                                    style={{ cursor: "pointer", color: "#fff" }}
                                  ></i>
                                </Label>
                                <Input
                                  id="img"
                                  name="img"
                                  className="form-control"
                                  type="file"
                                  accept=".png, .jpg, .jpeg"
                                  style={{ display: "none" }}
                                  onChange={e => {
                                    setFieldValue("img", e.target.files[0])
                                    onImageChange(e)
                                  }}
                                />
                              </div>
                            </div>
                            <h5>{user.name}</h5>
                            <h4>Event Organizer</h4>
                          </div>
                        </Col>
                        <Col sm={12} md={7} xl={8}>
                          <div className="py-3 ps-md-0 ps-3 pe-3 pe-lg-5">
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold">Update Profile</h5>
                            </div>
                            <hr className="mt-0" />
                            <div className="mb-5">
                              <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                  name="name"
                                  type="text"
                                  placeholder="Enter Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name || ""}
                                  invalid={
                                    touched.name && errors.name ? true : false
                                  }
                                />
                                {touched.name && errors.name ? (
                                  <FormFeedback type="invalid">
                                    {errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Number</Label>
                                <Input
                                  name="number"
                                  type="text"
                                  placeholder="Enter Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.number || ""}
                                  invalid={
                                    touched.number && errors.number
                                      ? true
                                      : false
                                  }
                                />
                                {touched.number && errors.number ? (
                                  <FormFeedback type="invalid">
                                    {errors.number}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  type="text"
                                  readOnly
                                  disabled
                                  value={user.email}
                                />
                              </div>
                            </div>
                            <div className="text-center mt-2 mb-3">
                              {loading ? (
                                <Button
                                  disabled
                                  className="w-100"
                                  color="primary"
                                >
                                  Updating...
                                </Button>
                              ) : (
                                <Button
                                  className="w-100"
                                  onClick={handleSubmit}
                                  color="primary"
                                >
                                  Update
                                </Button>
                              )}
                            </div>
                            <div>
                              {success && (
                                <UncontrolledAlert color="success">
                                  {success}
                                </UncontrolledAlert>
                              )}
                              {error && (
                                <UncontrolledAlert color="danger">
                                  {error}
                                </UncontrolledAlert>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

export default VenueOwnerProfile
