import React from "react"
import { Col, Row } from "reactstrap"
import Slider from "components/Common/Slider"

const EventManagerDetails = ({ venue, eventDate }) => {
  return (
    <div>
      <Row className="mb-5 mt-5">
        <h4 className="mb-5">Booked venue</h4>
        <Col sm={12} md={12} lg={6} className="mt-sm-3 mb-3 mb-lg-0 mt-lg-0 ">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div>
                {" "}
                <div className="mb-2">
                  <h1
                    style={{
                      fontSize: "22px",
                      color: "black",
                    }}
                  >
                    {venue.venueName}
                  </h1>
                </div>
                <div>
                  <p
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {venue.address}
                  </p>
                </div>
              </div>
              <div>
                {" "}
                <div className="d-flex justify-content-between">
                  <div
                    className="d-flex text-muted"
                    style={{ fontSize: "14px" }}
                  >
                    <i className="mdi mdi-calendar-check h4 me-2 text-success"></i>
                    <div style={{ color: "#afafc0" }}>{eventDate.month}</div>
                    <div className="ms-1" style={{ color: "#afafc0" }}>
                      {eventDate.date}
                    </div>{" "}
                    <div className="ms-1" style={{ color: "#afafc0" }}>
                      {eventDate.year}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p style={{ fontSize: "16px" }} className="text-muted">
              {venue.description?.substring(0, 250)}
              {" ..."}
            </p>
            <div className="d-flex flex-wrap align-items-center text-muted mt-2">
              {venue.bookedSlots.map((slot, index) => (
                <div className="d-flex text-muted mt-2" key={index}>
                  {" "}
                  <i className="mdi mdi-timetable h4 me-2 text-success"></i>
                  <p
                    className=""
                    style={{
                      color: "#afafc0",
                      fontSize: "14px",
                      marginBottom: "0px",
                    }}
                  >
                    {slot?.startTime} - {slot?.endTime}
                  </p>
                </div>
              ))}
            </div>
            <div>
              <p
                style={{
                  marginBottom: "1px",
                  fontSize: "16px",
                }}
              >
                Total Payment :
              </p>
              <p
                className="fs-3 fw-bold text-secondary"
                style={{ color: "black" }}
              >
                &#163;{" "}
                {venue.bookedSlots.map(slot => {
                  var p = 0
                  p = p + Number(slot.price)
                  return p
                })}
              </p>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} lg={4} className="mb-3 ms-lg-5">
          <Slider images={venue.attachments} />
        </Col>
      </Row>
    </div>
  )
}

export default EventManagerDetails
