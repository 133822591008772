import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import { logoutUser } from "./store/actions"

// Import scss
import "./assets/scss/theme.scss"

const App = props => {
  const dispatch = useDispatch()
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = VerticalLayout
        break
      default:
        layoutCls = VerticalLayout
        // layoutCls = HorizontalLayout
        break
    }
    return layoutCls
  }

  const handleCloseTab = () => {
    dispatch(logoutUser())
  }
  useEffect(() => {
    window.addEventListener("unload", handleCloseTab)
    return () => {
      window.removeEventListener("unload")
    }
  }, [])

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
