import React, { useEffect, useRef, useState } from "react"
import { Row } from "reactstrap"
import { Html5QrcodeScanner } from "html5-qrcode"
function ScanQR({ handleScanQr, openQrHandler }) {
  const scanRef = useRef()
  const [onScan, setOnScan] = useState(false)
  let qrScanner = null
  useEffect(() => {
    if (onScan) {
      qrScanner = new Html5QrcodeScanner(
        scanRef.current.id,
        { fps: 10, qrbox: 250 },
        false
      )
      qrScanner.render(
        (decodeText, result) => {
          handleScanQr(decodeText)
          openQrHandler()
          qrScanner.clear()
          qrScanner.resume()
          setOnScan(false)
        },
        errorMessage => {
          // qrScanner.clear()
          // qrScanner.resume()
        }
      )
    }
    return () => {
      if (qrScanner) qrScanner.clear()
    }
  }, [onScan])

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="p-1">Click to Scan</div>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => setOnScan(true)}
          >
            <i className="mdi mdi-camera" style={{ fontSize: 34 }}></i>
          </button>
        </div>
      </div>
      <Row className="my-3 d-flex justify-content-center">
        <div
          // id="reader"
          id="scanner"
          ref={scanRef}
          style={{ width: "300px" }}
        ></div>
      </Row>
    </div>
  )
}

export default ScanQR
