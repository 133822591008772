import React, { useEffect, useState } from "react"
import { Col, Row, Label, Input, Table, Card } from "reactstrap"
import * as Yup from "yup"
import { Formik } from "formik"
import Error from "components/Common/Error"
import { addAvailabilities } from "../../store/managers/actions"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { v4 } from "uuid"
import { getDay } from "common/getDay"
import { getWeeksDiff } from "common/getWeeks"
import { addDays } from "common/addDays"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
// time picker
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import dayjs from "dayjs"
// theme
import { ThemeProvider, createTheme } from "@mui/material/styles"

const slotValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  type: Yup.string().required("Type is required"),
  startTime: Yup.string().required("Start time is required"),
  endTime: Yup.string().required("End time is required"),
  price: Yup.number().required("Price is required"),
})

const avaiabilityValidationSchema = Yup.object().shape({
  startDate: Yup.string().required("Starting date of slots is required"),
  slots: Yup.array().min(1, "Min 1 Slot is required"),
})

const themePink = createTheme({ palette: { primary: { main: "#f0876f" } } })

const AddAvailability = props => {
  //
  const Type = ["For Venue", "For Private", "Any"]
  const [reoccur, setReoccur] = useState(false)
  // dates
  const [date, setDate] = useState("")
  const [eDates, setEDates] = useState("")
  const [startDate, setSTartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const dispatch = useDispatch()
  const history = useHistory()

  // time picker
  const [openStart, setOpenStart] = useState(false)
  const [openEnd, setOpenEnd] = useState(false)
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [endTimeValid, setEndTimeValid] = useState("")

  const { btnLoading } = useSelector(state => ({
    btnLoading: state.Managers.btnLoading,
  }))

  const getEDates = (startDate, endDate) => {
    var f = getWeeksDiff(startDate, endDate)
    var e = addDays(startDate, f)
    setEDates(e)
  }

  useEffect(() => {
    var t = new Date(start).setMinutes(new Date(start).getMinutes() + 30)
    setEndTimeValid(new Date(t))
  }, [start])

  return (
    <div>
      <Card>
        <Formik
          initialValues={{
            day: "",
            startDate: "",
            slots: [],
          }}
          validationSchema={avaiabilityValidationSchema}
          onSubmit={values => {
            dispatch(addAvailabilities({ data: values, history }))
          }}
        >
          {({
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            values: avValues,
            touched,
            errors,
          }) => {
            const onDeleteSlot = index => {
              setFieldValue(
                "slots",
                avValues.slots.filter((v, i) => i !== index)
              )
            }
            const handleChangeDate = e => {
              var date = new Date(e[0])
              let d = getDay(new Date(e))
              setFieldValue("day", d)
              var fDate =
                date.getFullYear() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getDate()
              setFieldValue("startDate", fDate)
              setDate(fDate)
              getEDates(fDate, endDate)
              setSTartDate(fDate)
            }

            return (
              <div className="p-5">
                <h4 className="mb-4">Add Availabilities</h4>
                <Row className="mb-3">
                  <Col md="4" className="me-md-5">
                    <Label>Available From</Label>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Date"
                      min={new Date().toISOString().split("T")[0]}
                      onChange={handleChangeDate}
                      value={avValues.startDate}
                      options={{
                        altFormat: "F j, Y",
                        dateFormat: "Y-m-d",
                        minDate: "today",
                      }}
                      disabled={avValues.slots.length > 0 ? true : false}
                    />
                    {errors.startDate && touched.startDate && (
                      <Error text={errors.startDate} />
                    )}
                  </Col>
                  <Col md="4">
                    <Label>Day</Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-day-input2"
                      placeholder="Day"
                      name="day"
                      readOnly
                      value={avValues.day}
                    />
                  </Col>
                </Row>
                <Formik
                  initialValues={{
                    title: "",
                    type: "",
                    startTime: "",
                    endTime: "",
                    price: "",
                    availableTill: "",
                    recurring: false,
                  }}
                  validationSchema={slotValidationSchema}
                  onSubmit={(v, { resetForm }) => {
                    if (!reoccur || !v.availableTill) {
                      setFieldValue("slots", [
                        ...avValues.slots,
                        { ...v, id: v4(), availableTill: date },
                      ])
                    } else {
                      setFieldValue("slots", [
                        ...avValues.slots,
                        { ...v, id: v4() },
                      ])
                    }
                    setReoccur(false)
                    setStart(null)
                    setEnd(null)
                    setEDates("")
                    resetForm()
                  }}
                >
                  {({
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    touched,
                    errors,
                  }) => {
                    return (
                      <>
                        <Row>
                          <Col md="4" className="me-md-5">
                            <div className="mb-3">
                              <Label>
                                Title
                                <small>
                                  (Availability Name to differentiate)
                                </small>
                              </Label>
                              <input
                                className="form-control"
                                type="text"
                                id="example-title-input"
                                name="title"
                                placeholder="Enter title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}
                              />
                              {errors.title && touched.title && (
                                <Error text={errors.title} />
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label>Type</Label>
                              <select
                                className="form-select"
                                autoComplete="off"
                                onChange={e => {
                                  setFieldValue("type", e.target.value)
                                }}
                                value={values.type}
                                placeholder="Select Type"
                              >
                                <option
                                  disabled
                                  value=""
                                  style={{ display: "none" }}
                                >
                                  {" "}
                                </option>
                                {Type.map(type => (
                                  <option value={type} key={type}>
                                    {type}
                                  </option>
                                ))}
                              </select>
                              {errors.type && touched.type && (
                                <Error text={errors.type} />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4" className="me-md-5">
                            <div className="mb-3">
                              <Label>Start Time</Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ThemeProvider theme={themePink}>
                                  <TimePicker
                                    open={openStart}
                                    onOpen={() => setOpenStart(true)}
                                    onClose={() => setOpenStart(false)}
                                    minutesStep={15}
                                    value={start}
                                    onChange={newValue => {
                                      setEnd(null)
                                      setStart(newValue.$d)
                                      const dateObj = newValue.$d
                                      let hours = dateObj.getHours()
                                      let minutes = dateObj.getMinutes()
                                      let ampm = hours >= 12 ? "PM" : "AM"
                                      hours = hours % 12
                                      hours = hours ? hours : 12
                                      minutes =
                                        minutes < 10 ? "0" + minutes : minutes
                                      let timeStr =
                                        hours + ":" + minutes + " " + ampm
                                      setFieldValue("startTime", timeStr)
                                    }}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        onClick={e => setOpenStart(true)}
                                        sx={{
                                          "& .MuiOutlinedInput-root.Mui-focused":
                                            {
                                              "& > fieldset": {
                                                borderColor: "black",
                                                border: "1px solid silver",
                                              },
                                            },
                                          width: "100%",
                                          fontSize: "10px",
                                          input: {
                                            fontSize: "13px",
                                            color: "#757575",
                                            "&::placeholder": {
                                              opacity: 1,
                                            },
                                          },
                                          label: {
                                            fontSize: "13px",
                                            color: "black",
                                          },
                                        }}
                                        size="small"
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "Select Time",
                                        }}
                                      />
                                    )}
                                    placeholder="Select Time"
                                  />
                                </ThemeProvider>
                              </LocalizationProvider>
                              {errors.startTime && touched.startTime && (
                                <Error text={errors.startTime} />
                              )}
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label for="basicpill-startTime-input1">
                                End Time
                              </Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ThemeProvider theme={themePink}>
                                  <TimePicker
                                    open={openEnd}
                                    onOpen={() => setOpenEnd(true)}
                                    onClose={() => setOpenEnd(false)}
                                    minutesStep={15}
                                    value={end}
                                    minTime={dayjs(endTimeValid)}
                                    disabled={!start}
                                    onChange={newValue => {
                                      setEnd(newValue.$d)
                                      const dateObj = newValue.$d
                                      let hours = dateObj.getHours()
                                      let minutes = dateObj.getMinutes()
                                      let ampm = hours >= 12 ? "PM" : "AM"
                                      hours = hours % 12
                                      hours = hours ? hours : 12
                                      minutes =
                                        minutes < 10 ? "0" + minutes : minutes
                                      let timeStr =
                                        hours + ":" + minutes + " " + ampm
                                      setFieldValue("endTime", timeStr)
                                    }}
                                    renderInput={params => (
                                      <TextField
                                        {...params}
                                        onClick={e => setOpenEnd(true)}
                                        sx={{
                                          "& .MuiOutlinedInput-root.Mui-focused":
                                            {
                                              "& > fieldset": {
                                                borderColor: "black",
                                                border: "1px solid silver",
                                              },
                                            },
                                          width: "100%",
                                          fontSize: "10px",
                                          input: {
                                            fontSize: "13px",
                                            color: "#757575",
                                            "&::placeholder": {
                                              opacity: 1,
                                            },
                                          },
                                          label: {
                                            fontSize: "13px",
                                            color: "black",
                                          },
                                        }}
                                        size="small"
                                        inputProps={{
                                          ...params.inputProps,
                                          placeholder: "Select Time",
                                        }}
                                      />
                                    )}
                                    placeholder="Select Time"
                                  />
                                </ThemeProvider>
                              </LocalizationProvider>
                              {errors.endTime && touched.endTime && (
                                <Error text={errors.endTime} />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4" className="me-md-5">
                            <div className="mb-3">
                              <Label for="basicpill-price-input2">
                                Price <small>(GBP)</small>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-price-input2"
                                placeholder="Enter price"
                                name="price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                                min={1}
                              />
                              {errors.price && touched.price && (
                                <Error text={errors.price} />
                              )}
                            </div>
                          </Col>
                        </Row>
                        {startDate && (
                          <Row>
                            <Col md={4} className="me-md-5">
                              <div className="form-check ">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={values.recurring}
                                  id="defaultCheck1"
                                  name="recurring"
                                  onChange={() => {
                                    setReoccur(!reoccur)
                                    setFieldValue("recurring", true)
                                  }}
                                  checked={values.recurring}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  Recurring
                                </label>
                              </div>
                              <Label for="basicpill-price-input2">
                                <small>
                                  (if the availability will reoccur)
                                </small>
                              </Label>
                            </Col>
                          </Row>
                        )}
                        {reoccur ? (
                          <Row>
                            <Col md="4">
                              <div className="mb-3">
                                <Label for="basicpill-price-input2">
                                  Valid Until
                                </Label>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Date"
                                  onChange={e => {
                                    var date = new Date(e[0])
                                    var fDate =
                                      date.getFullYear() +
                                      "/" +
                                      (date.getMonth() + 1) +
                                      "/" +
                                      date.getDate()
                                    setFieldValue("availableTill", fDate)
                                    setEndDate(fDate)
                                    getEDates(startDate, fDate)
                                  }}
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d",
                                    minDate: "today",
                                  }}
                                />
                                {errors.availableTill &&
                                  touched.availableTill && (
                                    <Error text={errors.availableTill} />
                                  )}
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        <div className="mb-3 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={handleSubmit}
                            disabled={!avValues.startDate ? true : false}
                          >
                            Add Slot
                          </button>
                        </div>
                      </>
                    )
                  }}
                </Formik>
                {eDates.length > 0 && (
                  <Row>
                    <Col md={4} className="me-md-5">
                      <div className="my-3">
                        <div className="table-responsive">
                          <Table className="align-middle table-bordered table-striped mb-0">
                            <thead className="bg-secondary text-white">
                              <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Day</th>
                              </tr>
                            </thead>
                            <tbody>
                              {eDates?.length > 0
                                ? eDates.map((item, index) => (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{item?.date}</td>
                                      <td>{item?.day}</td>
                                    </tr>
                                  ))
                                : null}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}

                {avValues.slots.length > 0 ? (
                  <div className="p-3">
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-bordered table-striped"
                          size="sm"
                        >
                          <thead className="bg-secondary text-white">
                            <tr>
                              <th>#</th>
                              <th>Title</th>
                              <th>Type</th>
                              <th>Slot Start</th>
                              <th>Slot End</th>
                              <th>End Date</th>
                              <th>Price</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {avValues.slots.length > 0
                              ? avValues.slots.map((slot, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{slot?.title}</td>
                                    <td>{slot?.type}</td>
                                    <td>{slot.startTime}</td>
                                    <td>{slot.endTime}</td>
                                    <td>{slot?.availableTill}</td>
                                    <td>{slot?.price} &#163;</td>
                                    <td>
                                      <i
                                        className="mdi mdi-delete mx-2 font-size-18"
                                        id="edittooltip"
                                        style={{
                                          cursor: "pointer",
                                          color: "#ff5252",
                                        }}
                                        onClick={() => onDeleteSlot(index)}
                                      />
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {errors.slots && <Error text={errors.slots} />}
                <div className="mt-3 mb-3 d-flex justify-content-center">
                  {btnLoading ? (
                    <button type="button" className="btn btn-primary " disabled>
                      Adding Availability...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={handleSubmit}
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            )
          }}
        </Formik>
      </Card>
    </div>
  )
}

export default AddAvailability
