import React, { useState } from "react"
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete"
function GooglePlacesAutoComplete(props) {
  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyBL4JbKL4SotWhSAnoYflXy9fnHrmT52Lg"
      selectProps={{
        onChange: val => {
          geocodeByAddress(val.label)
            .then(results => {
              return getLatLng(results[0])
            })
            .then(({ lat, lng }) => {
              props?.setLocation({
                latitude: lat,
                longitude: lng,
              })
            })
            .catch(err => {
              console.log(err.message)
            })
        },
      }}
    />
  )
}

export default GooglePlacesAutoComplete
