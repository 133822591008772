import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table, Badge } from "reactstrap"
import { isEmpty, map } from "lodash"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { setBreadcrumbItems } from "../../store/Breadcrumb/actions"
import Loader from "components/Common/Loader"

import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

import firebaseService from "services/firebaseService"
import { COLLECTIONS } from "constants/firebase.constants"

function PaymentsDetail() {
  const dispatch = useDispatch()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const [totalCredits, setTotalCredits] = useState(0)
  const [deducted, setDeducted] = useState(0)
  const [recieved, setRecieved] = useState(0)

  const [event, setEvent] = useState({})

  useEffect(() => {
    dispatch(
      setBreadcrumbItems("IGP", [{ title: "IGP" }, { title: "Payments" }])
    )

    async function handleEvent() {
      try {
        setLoading(true)
        const eventId = params.eventId
        const event = await firebaseService.getDocumentById(
          COLLECTIONS.EVENTS,
          eventId
        )
        setEvent(event)
        /* charges required */
        let ticketsPrice = 0
        let ticketsChargesByPlatform = 0
        let isFeaturedCharges = 0
        let orders = []

        // get manager data
        const manager = await firebaseService.getDocumentById(
          COLLECTIONS.MANAGERS,
          event.createdBy
        )
        const plaformConfig = manager.config

        const tickets = await firebaseService.queryDocuments(
          COLLECTIONS.TICKETS,
          "eventId",
          "==",
          event.id
        )
        for (let i = 0; i < tickets.length; i++) {
          if (tickets[i].isPaid) ticketsPrice += tickets[i].amountPaid
        }

        ticketsChargesByPlatform =
          (ticketsPrice / 100) * plaformConfig.perTicketPercentage
        if (event.featured) {
          isFeaturedCharges =
            (ticketsPrice / 100) * plaformConfig.featuredEventsTaxPercentage
        }
        setTotalCredits(ticketsPrice)
        // set Data
        setData(prev => [
          ...prev,
          {
            name: "Tickets",
            quantity: tickets.length,
            totalCredits: ticketsPrice,
            platformCredits: ticketsChargesByPlatform,
            transferCredits: "",
          },
          {
            name: "Featured",
            quantity: "",
            totalCredits: isFeaturedCharges?.toFixed(1),
            platformCredits: isFeaturedCharges?.toFixed(1),
            transferCredits: "",
          },
        ])
        // handle orders
        orders = await firebaseService.queryDocuments(
          COLLECTIONS.ORDERS,
          "event",
          "==",
          event.id
        )
        // check if event manager have enough balance
        let balanceRequired = 0
        balanceRequired = ticketsChargesByPlatform + isFeaturedCharges
        orders.forEach(async order => {
          let price = order?.slot?.price || 0
          const createdTo = await firebaseService.getDocumentById(
            COLLECTIONS.MANAGERS,
            order.createdTo
          )
          if (typeof price === "string") price = parseFloat(price)
          balanceRequired += price
          setDeducted(balanceRequired)
          setRecieved((ticketsPrice - balanceRequired).toFixed(1))
          const orderPlatformCharges =
            (price / 100) * plaformConfig.ordersPercantage
          const orderTransferPrice = price - orderPlatformCharges
          setData(prev => [
            ...prev,
            {
              name: createdTo.name,
              role: createdTo.role,
              quantity: "",
              totalCredits: price?.toFixed(1),
              platformCredits: orderPlatformCharges?.toFixed(1),
              transferCredits: orderTransferPrice?.toFixed(1),
            },
          ])
        })

        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.log("Error", err.message)
      }
    }
    handleEvent()
  }, [])

  //Print the Invoice
  const printInvoice = () => {
    window.print()
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>Payment Detail | IGP</title>
      </MetaTags>
      <Breadcrumbs title="Payments" breadcrumbItem="Payment Detail" />
      <Container fluid>
        {/* Render Breadcrumbs */}
        {loading && <Loader text={"Loading..."} />}
        {true && (
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <hr /> */}

                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">Payment Summary</h3>
                  </div>
                  {!loading && (
                    <div className="my-2">
                      Clearance Status :{" "}
                      {event?.isPaymentCleared &&
                      event?.status === "completed" ? (
                        <Badge
                          className={`font-size-12 badge-soft-success`}
                          color="#fff"
                          pill
                        >
                          Success
                        </Badge>
                      ) : !event?.isPaymentCleared &&
                        event?.status == "completed" ? (
                        <Badge
                          className={`font-size-12 badge-soft-danger`}
                          color="#fff"
                          pill
                        >
                          Failed
                        </Badge>
                      ) : (
                        <Badge
                          className={`font-size-12 badge-soft-secondary`}
                          color="#fff"
                          pill
                        >
                          Pending
                        </Badge>
                      )}
                    </div>
                  )}
                  <div className="table-responsive">
                    <Table className="table-nowrap">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>Total Credits</th>
                          <th>Platfrom Credits</th>
                          <th>Transfer Credits</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.quantity}</td>
                              <td>{item.totalCredits}</td>
                              <td>{item.platformCredits}</td>
                              <td>{item.transferCredits}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>

                  {!loading ? (
                    <div className="float-end">
                      <div className="my-1 d-flex justify-content-between">
                        <div className="mx-5 fs-6">Total Income</div>
                        <div className="mx-5 fs-6">{totalCredits}</div>
                      </div>

                      <div className="my-1 d-flex justify-content-between">
                        <div className="mx-5 fs-6">Charges Deducted</div>
                        <div className="mx-5 fs-6">{deducted}</div>
                      </div>
                      <div className="my-1 d-flex justify-content-between">
                        <div className="mx-5 fs-6 text-primary">
                          Recieved Income
                        </div>
                        <div className="mx-5 fs-6 text-primary">{recieved}</div>
                      </div>
                    </div>
                  ) : null}
                  {/* <div className="d-print-none">
                    <div className="float-end">
                      <Link to="#" className="btn btn-primary w-md ">
                        Send
                      </Link>
                    </div>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  )
}

export default PaymentsDetail
