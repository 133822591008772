import {
  ADD_VENUE,
  ON_ADD_VENUE,
  GET_VENUES,
  ON_GET_VENUES,
  GET_ALL_VENUES,
  ON_GET_ALL_VENUES,
  API_ERROR,
  API_SUCCESS,
  UPDATE_VENUE,
  ON_UPDATE_VENUE,
  UPDATE_VENUE_STATUS,
  ON_UPDATE_VENUE_STATUS,
  DELETE_VENUE,
  ON_DELETE_VENUE,
  GET_VENUE_DETAILS,
  ON_GET_VENUE_DETAILS,
  ACTIVE_SLOT,
  ON_ACTIVE_SLOT,
  IN_ACTIVE_SLOT,
  ON_IN_ACTIVE_SLOT,
} from "./actionTypes"

export const addVenues = data => ({
  type: ADD_VENUE,
  payload: data,
})

export const onAddVenues = data => ({
  type: ON_ADD_VENUE,
  payload: data,
})

export const updateVenue = data => ({
  type: UPDATE_VENUE,
  payload: data,
})

export const onUpdateVenue = data => ({
  type: ON_UPDATE_VENUE,
  payload: data,
})

export const activeSlot = data => ({
  type: ACTIVE_SLOT,
  payload: data,
})

export const onActiveSlot = data => ({
  type: ON_ACTIVE_SLOT,
  payload: data,
})

export const inActiveSlot = data => ({
  type: IN_ACTIVE_SLOT,
  payload: data,
})

export const onInActiveSlot = data => ({
  type: ON_IN_ACTIVE_SLOT,
  payload: data,
})

export const updateVenueStatus = data => ({
  type: UPDATE_VENUE_STATUS,
  payload: data,
})

export const onUpdateVenueStatus = data => ({
  type: ON_UPDATE_VENUE_STATUS,
  payload: data,
})

export const deleteVenue = data => ({
  type: DELETE_VENUE,
  payload: data,
})

export const onDeleteVenue = data => ({
  type: ON_DELETE_VENUE,
  payload: data,
})

export const getVenues = data => ({
  type: GET_VENUES,
  payload: data,
})

export const onGetVenues = data => ({
  type: ON_GET_VENUES,
  payload: data,
})

export const getAllVenues = data => ({
  type: GET_ALL_VENUES,
  payload: data,
})

export const onGetAllVenues = data => ({
  type: ON_GET_ALL_VENUES,
  payload: data,
})

export const getVenueDetails = data => ({
  type: GET_VENUE_DETAILS,
  payload: data,
})

export const onGetVenueDetails = data => ({
  type: ON_GET_VENUE_DETAILS,
  payload: data,
})

export const apiSuccess = success => ({
  type: API_SUCCESS,
  payload: success,
})

export const apiError = error => ({
  type: API_ERROR,
  payload: error,
})
