import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Configurations from "./Configurations"
import UpdatePassword from "./UpdatePassword"
import Profile from "./Profile"

const index = () => {
  const [activeTab, setActiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            {" "}
            <h4 className="card-title mb-3">Profile</h4>
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1")
                  }}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2")
                  }}
                >
                  Configurations
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggleTab("3")
                  }}
                >
                  Change Password
                </NavLink>
              </NavItem>
            </ul>
            <TabContent activeTab={activeTab} className="p-3">
              <TabPane tabId="1" id="profile">
                {activeTab == 1 && <Profile />}
              </TabPane>
              <TabPane tabId="2" id="config">
                {activeTab == 2 && <Configurations />}
              </TabPane>
              <TabPane tabId="3" id="update-password">
                {activeTab == 3 && <UpdatePassword />}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default index
