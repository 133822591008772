import React, { useState, useEffect } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import Slider from "components/Common/Slider"
import weekDays from "common/weekDays"
import months from "common/months"

const VenueOwnerDetails = ({ booking, createdBy, bookingDate, venueData }) => {
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  useEffect(() => {
    let dateObj = new Date(bookingDate)
    let day = dateObj.getDay()
    let month = dateObj.getMonth() + 1
    let year = dateObj.getFullYear()
    let date = dateObj.getDate()
    //
    day = weekDays.find(d => d.value == day)
    month = months.find(m => m.value == month)
    //
    setDay(day?.flag)
    setDate(date)
    setMonth(month?.label)
    setYear(year)
  }, [day, date, month, year])

  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <Row className="mb-5 mt-5">
              <h4 className="mb-5">Booking Details</h4>
              <Col
                sm={12}
                md={12}
                lg={6}
                className="mt-sm-3 mb-3 mb-lg-0 mt-lg-0 "
              >
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <div>
                      {" "}
                      <div className="mb-2">
                        <h1
                          style={{
                            fontSize: "22px",
                            color: "black",
                          }}
                        >
                          {venueData?.venueName}
                        </h1>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          {venueData?.address}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div
                        className="d-flex align-items-center text-muted"
                        style={{ fontSize: "14px" }}
                      >
                        <i className="mdi mdi-calendar-check h4 me-2 text-success"></i>
                        <div style={{ color: "#afafc0" }}>{month}</div>
                        <div className="ms-1" style={{ color: "#afafc0" }}>
                          {date}
                        </div>{" "}
                        <div className="ms-1" style={{ color: "#afafc0" }}>
                          {year}
                        </div>
                      </div>

                      <div
                        className="text-muted mt-2"
                        style={{ fontSize: "14px" }}
                      >
                        <div className="" style={{ color: "#afafc0" }}>
                          <i className="mdi mdi-timetable h4 me-2 text-success"></i>
                          {booking?.startTime} - {booking?.endTime}
                        </div>
                      </div>
                    </div>
                  </div>

                  <p style={{ fontSize: "16px" }} className="text-muted">
                    {venueData?.description?.substring(0, 250)}
                    {" ..."}
                  </p>
                  <div>
                    <p style={{ marginBottom: "1px", fontSize: "16px" }}>
                      Total Payment :
                    </p>
                    <p
                      className="fs-3 fw-bold text-secondary"
                      style={{ color: "black" }}
                    >
                      &#163; {booking?.price}
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={4} className="mb-3 ms-lg-5">
                <Slider images={venueData?.attachments} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default VenueOwnerDetails
