import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Row } from "reactstrap"
import { useLocation } from "react-router"
import Loader from "components/Common/Loader"
import Slider from "./Slider"
// manual event details
import ManualVenueDetails from "./ManualVenueDetails"
// booking details
import VenueOwnerDetails from "./VenueOwnerDetails"
import EventManagerDetails from "./EventManagerDetails"
// import week days and month array
import weekDays from "common/weekDays"
import months from "common/months"
// import simple map
import MapsGoogle from "components/Common/MapsGoogle"
// events tickets details
import Tickets from "./tickets/Tickets"

const EventDetails = () => {
  const location = useLocation()
  const [eventData, setEventData] = useState(location.state)
  const [role, setRole] = useState("")
  const eventId = eventData.id

  //
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  useEffect(() => {
    const manager = JSON.parse(localStorage.getItem("authUser"))
    setRole(manager.role)
  }, [])

  useEffect(() => {
    let dateObj = new Date(eventData?.eventDate)
    let day = dateObj.getDay()
    let month = dateObj.getMonth() + 1
    let year = dateObj.getFullYear()
    let date = dateObj.getDate()
    //
    day = weekDays.find(d => d.value == day)
    month = months.find(m => m.value == month)
    //
    setDay(day?.flag)
    setDate(date)
    setMonth(month?.label)
    setYear(year)
  }, [day, date, month, year])

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Event Details</title>
        </MetaTags>

        {!eventData && <Loader />}
        {eventData && (
          <Card className="px-md-2">
            <CardBody>
              <div className="pt-3">
                <div className="row justify-content-center">
                  <div>
                    <div className="text-center" style={{ color: "black" }}>
                      <h1>{eventData.title}</h1>
                      <p
                        className="text-muted mb-4"
                        style={{ fontSize: "15px" }}
                      >
                        <i className="mdi mdi-calendar me-1"></i> {month} {date}
                        {"  "}
                        At {eventData?.startTime}
                        {" - "}
                        {eventData?.requiredTime}
                      </p>
                    </div>
                    <hr />
                    <div className="text-center">
                      <Row className="justify-content-center">
                        <Col sm={12}>
                          <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <div className="my-2 ms-5">
                              {eventData?.status == "completed" ? (
                                <span className="badge rounded-pill badge-soft-success font-size-12">
                                  Completed
                                </span>
                              ) : (
                                <span className="badge rounded-pill badge-soft-warning font-size-12">
                                  {eventData?.status}
                                </span>
                              )}
                            </div>
                            {eventData?.featured ? (
                              <div style={{ color: "black" }} className="ms-5">
                                <i className="mdi mdi-bookmark h5 me-1 text-success"></i>
                                Featured
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="text-muted ms-5">
                              <i className="text-secondary mdi mdi-timer-sand h5 "></i>
                              <span>{eventData?.requiredTime}</span>
                            </div>
                            <div className="ms-5">
                              <i className="text-secondary mdi mdi-clock h5 mx-1"></i>
                              {eventData?.startTime}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <Row>
                      <Col sm={12}>
                        <div className="my-3">
                          {eventData?.attachments?.length > 0 && (
                            <Slider images={eventData.attachments} />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 mb-5">
                      <h4 className="my-3" style={{ color: "black" }}>
                        Event Description
                      </h4>
                      <div className="text-muted" style={{ fontSize: "15px" }}>
                        <p>{eventData.description}</p>
                      </div>
                    </div>
                    <div className="mt-5">
                      <Row>
                        <Col md={3}>
                          <div className="d-flex flex-column me-5">
                            <h4 className="mb-4" style={{ color: "black" }}>
                              Details
                            </h4>
                            <div>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Event Type
                              </p>
                              <p> {eventData.eventType}</p>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                Event Level
                              </p>
                              <p>{eventData.eventLevel}</p>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                seats
                              </p>
                              <p>{eventData.requiredSeats}</p>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="d-flex flex-column">
                            <h4 className="mb-4" style={{ color: "black" }}>
                              Venue
                            </h4>
                            <p
                              style={{
                                marginBottom: "2px",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              Name
                            </p>
                            <p>
                              {" "}
                              {eventData?.venue
                                ? eventData?.venue?.venueName
                                : eventData?.selectedVenue[0]?.venue?.venueName}
                            </p>
                            <p
                              style={{
                                marginBottom: "2px",
                                fontWeight: "bold",
                              }}
                            >
                              Address
                            </p>
                            <p>
                              {eventData?.venue
                                ? eventData?.venue?.address
                                : eventData?.selectedVenue[0]?.venue?.address}
                            </p>
                          </div>
                        </Col>
                        <Col md={6}>
                          {" "}
                          <div>
                            <MapsGoogle
                              loc={
                                eventData?.venue?.location
                                  ? eventData?.venue?.location
                                  : eventData?.venue.address
                              }
                              address={eventData?.address}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {/* pricing packages */}
                    <div className="mt-4">
                      <h4 className="mb-3">Ticket Packages</h4>
                      <Row>
                        {eventData?.packages.map((ticket, index) => (
                          <Col
                            xl="3"
                            md="4"
                            sm="6"
                            className="me-md-3 me-lg-5"
                            key={index}
                          >
                            <Card
                              className="plan-box"
                              style={{ borderRadius: "20px" }}
                            >
                              <CardBody className="px-3 pt-3 pb-1">
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1">
                                    <h5>{ticket.detail}</h5>
                                  </div>
                                  <div className="">
                                    <i
                                      className={
                                        "mdi mdi-ticket text-secondary"
                                      }
                                      style={{ fontSize: 30 }}
                                    />
                                  </div>
                                </div>
                                <div className="">
                                  <h2>
                                    <sup>
                                      <small>&#163; </small>
                                    </sup>{" "}
                                    {ticket.price}/{" "}
                                    <span className="font-size-13">ticket</span>
                                  </h2>
                                  <p>
                                    Tickets Ammount:{" "}
                                    <span className="fs-5">
                                      {ticket.ticketsAmmount}
                                    </span>
                                  </p>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {!eventData && (
                <div className="d-flex justify-content-center">
                  <h4>No Event Details</h4>
                </div>
              )}
              {role == "venueOwner" && (
                <VenueOwnerDetails
                  eventId={eventId}
                  eventDate={{ date: date, year: year, month: month }}
                  eventManagers={eventData?.eventManagers}
                />
              )}

              {!eventData.isManual && role == "eventManager" && (
                <EventManagerDetails
                  venue={eventData.venue}
                  eventDate={{ date: date, year: year, month: month }}
                />
              )}
              {eventData.isManual && role == "eventManager" && (
                <ManualVenueDetails venue={eventData?.venue} />
              )}
              {/* tickets */}
              <Tickets eventId={eventData?.id} eventData={eventData} />
            </CardBody>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

export default EventDetails
