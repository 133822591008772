export default [
  {
    label: "Social Dance",
    value: 1,
  },
  {
    label: "Salsa",
    value: 2,
  },
  {
    label: "Bachata",
    value: 3,
  },

  {
    label: "Yoga",
    value: 4,
  },
  {
    label: "Hot Yoga",
    value: 5,
  },
  {
    label: "Dance Fest",
    value: 6,
  },
  {
    label: "SBK",
    value: 7,
  },
  {
    label: "Kizomba",
    value: 8,
  },
]
