import React, { useEffect, useState } from "react"
import EventManagerDetails from "./EventManagerDetails"
import VenueOwnerDetails from "./VenueOwnerDetails"
import EventDetails from "./EventDetails"
import { useLocation } from "react-router"

const OrderDetails = () => {
  const location = useLocation()
  const [role, setRole] = useState("")
  const [orderData, setOrderData] = useState(location.state)
  useEffect(() => {
    const manager = JSON.parse(localStorage.getItem("authUser"))
    setRole(manager?.role)
  }, [])

  console.log(orderData)

  return (
    <div>
      <EventDetails
        eventData={orderData?.eventDetails}
        venueData={orderData?.venue}
      />
      {role == "eventManager" && (
        <EventManagerDetails
          booking={orderData?.slot}
          bookingDate={orderData.bookingDate}
          createdBy={orderData?.createdByData}
        />
      )}
      {role == "venueOwner" && (
        <VenueOwnerDetails
          booking={orderData?.slot}
          bookingDate={orderData.bookingDate}
          createdBy={orderData?.createdByData}
          venueData={orderData?.venue}
        />
      )}
    </div>
  )
}

export default OrderDetails
