export default [
  {
    label: "January",
    value: 1,
    flag: "Jan",
  },
  {
    label: "February",
    value: 2,
    flag: "Feb",
  },
  {
    label: "March",
    value: 3,
    flag: "Mar",
  },
  {
    label: "April",
    value: 4,
    flag: "Apr",
  },
  {
    label: "May",
    value: 5,
    flag: "May",
  },
  {
    label: "June",
    value: 6,
    flag: "June",
  },
  {
    label: "July",
    value: 7,
    flag: "July",
  },
  {
    label: "August",
    value: 8,
    flag: "Aug",
  },
  {
    label: "September",
    value: 9,
    flag: "Sep",
  },
  {
    label: "October",
    value: 10,
    flag: "Oct",
  },
  {
    label: "November",
    value: 11,
    flag: "Nov",
  },
  {
    label: "December",
    value: 12,
    flag: "Dec",
  },
]
