import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import Loader from "../../components/Common/Loader"
import { getEvents } from "../../store/events/actions"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "reactstrap"
import EventCard from "./EventCards2"
import NoData from "pages/Utility/NoData"
import { apiError } from "../../store/events/actions"
import SweetAlert from "react-bootstrap-sweetalert"

const BasicTable = () => {
  const dispatch = useDispatch()
  const { data, loading, error } = useSelector(state => ({
    data: state.Events.data,
    loading: state.Events.loading,
    error: state.Events.error,
  }))

  useEffect(() => {
    dispatch(getEvents())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>My Events</title>
      </MetaTags>

      {error ? (
        <SweetAlert
          error
          title={"Error"}
          onConfirm={() => {
            dispatch(apiError(""))
          }}
        >
          {error}
        </SweetAlert>
      ) : null}

      <Row>
        <Col sm={12}>
          {data?.length > 0 && !loading ? (
            data.map((event, index) => {
              return (
                <EventCard
                  key={index}
                  id={event?.id}
                  title={event?.title}
                  attachments={event?.attachments}
                  status={event?.status}
                  requiredSeats={event?.requiredSeats}
                  requiredTime={event?.requiredTime}
                  description={event?.description}
                  startTime={event?.startTime}
                  packages={event?.packages}
                  eventDate={event.eventDate}
                  eventLocation={event.eventLocation}
                  data={event}
                />
              )
            })
          ) : (
            <>{!loading ? <NoData /> : ""}</>
          )}
        </Col>
      </Row>

      {loading && <Loader />}
    </React.Fragment>
  )
}

export default BasicTable
