import {
  ADD_EVENT,
  ON_ADD_EVENT,
  GET_EVENTS,
  ON_GET_EVENTS,
  API_ERROR,
  API_SUCCESS,
  ADD_SLOT_BOOKING,
  UPDATE_EVENT,
  ON_UPDATE_EVENT,
  GET_EVENT_STATISTICS,
  ON_GET_EVENT_STATISTICS,
  ADD_EVENT_BY_EVENT_ORGANIZER,
  ON_ADD_EVENT_BY_EVENT_ORGANIZER,
  GET_EVENTS_BY_EVENT_ORGANIZER,
  ON_GET_EVENTS_BY_EVENT_ORGANIZER,
  ADD_EVENT_MANUAL_VENUE,
  ON_ADD_EVENT_MANUAL_VENUE,
  ADD_EVENT_BY_EVENT_MANAGER,
  ON_ADD_EVENT_BY_EVENT_MANAGER,
  ADD_EVENT_BY_VENUE_OWNER,
  ON_ADD_EVENT_BY_VENUE_OWNER,
  DELETE_EVENT,
  ON_DELETE_EVENT,
} from "./actionTypes"

const initialState = {
  btnLoading: false,
  loading: false,
  data: [],
  success: "",
  error: "",
  slotBooking: [],
  eventStatistics: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EVENT: {
      state = { ...state, loading: true }
      break
    }
    case ON_ADD_EVENT: {
      state = {
        ...state,
        loading: false,
        data: [state.data, action.payload],
      }
      break
    }
    case ADD_EVENT_MANUAL_VENUE: {
      state = { ...state, loading: true }
      break
    }
    case ON_ADD_EVENT_MANUAL_VENUE: {
      state = {
        ...state,
        loading: false,
        data: [state.data, action.payload],
      }
      break
    }
    case ADD_EVENT_BY_EVENT_MANAGER: {
      state = { ...state, loading: true }
      break
    }
    case ON_ADD_EVENT_BY_EVENT_MANAGER: {
      state = {
        ...state,
        loading: false,
        data: [state.data, action.payload],
      }
      break
    }
    case ADD_EVENT_BY_VENUE_OWNER: {
      state = { ...state, loading: true }
      break
    }
    case ON_ADD_EVENT_BY_VENUE_OWNER: {
      state = {
        ...state,
        loading: false,
        data: [state.data, action.payload],
      }
      break
    }
    case ADD_EVENT_BY_EVENT_ORGANIZER: {
      state = { ...state, loading: true }
      break
    }
    case ON_ADD_EVENT_BY_EVENT_ORGANIZER: {
      state = {
        ...state,
        loading: false,
        data: [state.data, action.payload],
      }
      break
    }
    case GET_EVENTS: {
      state = { ...state, loading: true, data: [] }
      break
    }

    case ON_GET_EVENTS: {
      state = { ...state, loading: false, data: action.payload }
      break
    }

    case GET_EVENTS_BY_EVENT_ORGANIZER: {
      state = { ...state, loading: true, data: [] }
      break
    }

    case ON_GET_EVENTS_BY_EVENT_ORGANIZER: {
      state = { ...state, loading: false, data: action.payload }
      break
    }

    case GET_EVENT_STATISTICS: {
      state = { ...state, loading: true, eventStatistics: [] }
      break
    }
    case ON_GET_EVENT_STATISTICS: {
      state = { ...state, loading: false, eventStatistics: action.payload }
      break
    }

    case UPDATE_EVENT: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_UPDATE_EVENT: {
      state = {
        ...state,
        btnLoading: false,
        data: [state.data, action.payload],
      }
      break
    }

    case DELETE_EVENT: {
      state = { ...state, btnLoading: true, loading: true }
      break
    }
    case ON_DELETE_EVENT: {
      state = {
        ...state,
        btnLoading: false,
        loading: false,
        data: state.data.filter(p => p.id != action.payload),
      }
      break
    }

    case ADD_SLOT_BOOKING: {
      state = { ...state, slotBooking: action.payload }
      break
    }

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        btnLoading: false,
      }
      break

    case API_SUCCESS: {
      state = {
        ...state,
        success: action.payload,
        loading: false,
        btnLoading: false,
      }
      break
    }
    default:
      state = { ...state }
  }
  return state
}

export default reducer
