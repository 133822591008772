import React, { useState, useEffect } from "react"
import Slider from "./Slider"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Row } from "reactstrap"
// import simple map
import MapsGoogle from "components/Common/MapsGoogle"
// import week days and month array
import weekDays from "common/weekDays"
import months from "common/months"

const EventDetails = ({ eventData }) => {
  //
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  useEffect(() => {
    let dateObj = new Date(eventData?.eventDate)
    let day = dateObj.getDay()
    let month = dateObj.getMonth() + 1
    let year = dateObj.getFullYear()
    let date = dateObj.getDate()
    //
    day = weekDays.find(d => d.value == day)
    month = months.find(m => m.value == month)
    //
    setDay(day?.flag)
    setDate(date)
    setMonth(month?.label)
    setYear(year)
  }, [day, date, month, year])

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Order Details</title>
        </MetaTags>
        <Row>
          <Col lg={12}>
            <Card className="px-md-5">
              <CardBody>
                <div className="pt-3">
                  <div className="row justify-content-center">
                    <div>
                      <div className="text-center" style={{ color: "black" }}>
                        <h1>{eventData.title}</h1>
                        <p
                          className="text-muted mb-4"
                          style={{ fontSize: "15px" }}
                        >
                          <i className="mdi mdi-calendar me-1"></i> {month}{" "}
                          {date}
                          {"  "}
                          At {eventData?.startTime}
                        </p>
                      </div>
                      <hr />
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col md={6} lg={4}>
                            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                              <div className="my-2">
                                {eventData?.status == "completed" ? (
                                  <span className="badge rounded-pill badge-soft-success font-size-12">
                                    Completed
                                  </span>
                                ) : (
                                  <span className="badge rounded-pill badge-soft-warning font-size-12">
                                    {eventData?.status}
                                  </span>
                                )}
                              </div>
                              <div className="text-muted ms-5">
                                <i className="text-secondary mdi mdi-timer-sand h5 "></i>
                                <span>{eventData?.requiredTime}</span>
                              </div>
                              <div className="ms-5">
                                <i className="text-secondary mdi mdi-clock h5 mx-1"></i>
                                {eventData?.startTime}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <Row>
                        <div className="my-5">
                          {eventData?.attachments?.length > 0 && (
                            <Slider images={eventData.attachments} />
                          )}
                        </div>
                      </Row>
                      <hr />
                      <div className="mt-4">
                        <h4 className="my-3">Event Description</h4>
                        <div className="text-muted font-size-14">
                          <p>{eventData.description}</p>
                        </div>
                      </div>
                      {/*  */}
                      <div className="mt-5">
                        <Row>
                          <Col md={3}>
                            <div className="d-flex flex-column me-5">
                              <h4 className="mb-4" style={{ color: "black" }}>
                                Details
                              </h4>
                              <div>
                                <p
                                  style={{
                                    marginBottom: "2px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  Event Type
                                </p>
                                <p> {eventData.eventType}</p>
                                <p
                                  style={{
                                    marginBottom: "2px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Event Level
                                </p>
                                <p>{eventData.eventLevel}</p>
                                <p
                                  style={{
                                    marginBottom: "2px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  seats
                                </p>
                                <p>{eventData.requiredSeats}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="d-flex flex-column">
                              <h4 className="mb-4" style={{ color: "black" }}>
                                Venue
                              </h4>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Name
                              </p>
                              <p>
                                {" "}
                                {eventData?.venue
                                  ? eventData?.venue?.venueName
                                  : eventData?.selectedVenue[0]?.venue
                                      ?.venueName}
                              </p>
                              <p
                                style={{
                                  marginBottom: "2px",
                                  fontWeight: "bold",
                                }}
                              >
                                Address
                              </p>
                              <p>
                                {eventData?.venue
                                  ? eventData?.venue?.address
                                  : eventData?.selectedVenue[0]?.venue?.address}
                              </p>
                            </div>
                          </Col>
                          <Col md={6}>
                            {" "}
                            <div>
                              <MapsGoogle
                                loc={
                                  eventData?.venue?.location
                                    ? eventData?.venue?.location
                                    : eventData?.venue.address
                                }
                                address={eventData?.address}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
                {!eventData && (
                  <div className="d-flex justify-content-center">
                    <h4>No Event Details</h4>
                  </div>
                )}
              </CardBody>
            </Card>
            {eventData.length == 0 && (
              <div className="d-flex justify-content-center">
                <h4>No Event Details</h4>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default EventDetails
