import React from "react"
import { Row, Col } from "reactstrap"

const ManualVenueDetails = ({ venue }) => {
  return (
    <Row>
      <Col lg={12}>
        <div className="p-4 border my-3 row justify-content-center">
          <div className="my-2">
            <h4 className="fw-bold fs-5">Booked Venue :</h4>
            <div className="my-2">
              <div className="my-1">
                Venue Name : <span className="mx-2">{venue?.venueName}</span>
              </div>
              <div className="my-1">
                Capacity : <span className="mx-2">{venue?.seats}</span>
              </div>
              <div className="my-1">
                Location : <span className="mx-2">{venue?.address}</span>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default ManualVenueDetails
