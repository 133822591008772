export function addDays(date, freq) {
  var curDate = date
  var dates = []

  for (let i = 0; i < freq; i++) {
    var result = new Date(curDate)
    if (i == 0) result.setDate(result.getDate())
    else result.setDate(result.getDate() + 7)
    dates.push(result)
    curDate = result
  }
  return dates
}

export function getDayName(date) {
  var firstDate = new Date(date)
  return firstDate.toLocaleString("en-us", { weekday: "long" })
}
export function getShowDate(date) {
  var result = new Date(date)
  return (
    result.getFullYear() +
    "/" +
    (result.getMonth() + 1) +
    "/" +
    result.getDate()
  )
}

export function getTimeDiff(time1, time2) {
  let now = new Date()
  let t1 = new Date(now.toDateString() + " " + time1)
  let t2 = new Date(now.toDateString() + " " + time2)
  let diff = t2.getTime() - t1.getTime()
  let hour = Math.floor(diff / (1000 * 60 * 60))
  let min = Math.floor((diff / (1000 * 60)) % 60)
  return {
    hour,
    min,
  }
}

export function settleTime(hour, min) {
  let hr = hour
  if (min >= 60) {
    hr = hr + 1
    min = min - 60
  }
  if (min < 0) {
    hr = hr - 1
    min = 60 + min
  }
  return {
    hour: hr,
    min: min,
  }
}
