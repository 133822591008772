import React, { useState, useEffect } from "react"
import { Col, Row, Table } from "reactstrap"

const EventManagerProfilt = props => {
  // states
  const [cal, setCal] = useState({
    IGPFee: "",
    Tax: "",
    Tickets: "",
    managerPayment: "",
    Profit: "",
  })

  const manager = JSON.parse(localStorage.getItem("authUser"))

  // managers payment
  useEffect(() => {
    let venueFee = 0
    let ticketFee = 0
    let tax = 0
    let igpFee = 0
    let profit = 0

    function calCulate() {
      props.eventData.venue.bookedSlots.map(slot => {
        venueFee = venueFee + parseInt(slot?.price)
      })
      props?.tickets.map(ticket => {
        ticketFee += ticket?.amountPaid
        tax += (ticket.amountPaid / 100) * manager?.config?.perTicketPercentage
        igpFee += (ticket.amountPaid / 100) * manager?.config?.platformFees
      })

      profit = ticketFee - (venueFee + tax + igpFee)

      setCal({
        IGPFee: igpFee,
        Tax: tax,
        Tickets: ticketFee,
        managerPayment: venueFee,
        Profit: profit,
      })
    }
    calCulate()
  }, [props.tickets])
  return (
    <Row className="d-flex justify-content-end">
      <Col xs="12" md="6">
        <div className="py-2 mt-3">
          <h3 className="font-size-15 fw-bold">Profit summary</h3>
        </div>
        <div className="table-responsive">
          <Table className="table-nowrap">
            <thead>
              <tr>
                <th style={{ width: "70px" }}>No.</th>
                <th>Name</th>
                <th className="text-end">Payment</th>
              </tr>
            </thead>
            <tbody>
              {props?.eventData?.venue?.bookedSlots?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td> {props?.eventData?.venue?.venueName}</td>
                  <td className="text-end"> &#163; {item.price}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="2" className="border-0 text-end">
                  <strong>IGP Fee</strong>
                </td>
                <td className="border-0 text-end">
                  &#163; {Math.round(cal.IGPFee * 100) / 100}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="border-0 text-end">
                  <strong>Tax</strong>
                </td>
                <td className="border-0 text-end">
                  {" "}
                  &#163; {Math.round(cal.Tax * 100) / 100}{" "}
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="text-end">
                  <h5>
                    <strong>Tickets Sold</strong>
                  </h5>
                </td>
                <td className="text-end">
                  <h5>&#163; {Math.round(cal.Tickets * 100) / 100}</h5>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="border-0 text-end">
                  <h2>
                    <strong>Total Profit</strong>
                  </h2>
                </td>
                <td className="border-0 text-end text-secondary">
                  <h2 className="m-0">
                    &#163; {Math.round(cal.Profit * 100) / 100}
                  </h2>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  )
}

export default EventManagerProfilt
