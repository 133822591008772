import React, { useEffect } from "react"
import { Row, Col, Card, Container } from "reactstrap"

const Configurations = props => {
  var user = JSON.parse(localStorage.getItem("authUser"))

  return (
    <div
      className="account-pages mt-sm-5 pb-5"
      style={{ width: "100%", backgroundColor: "#ffffff" }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col sm={12} md={8} lg={6} xl={6}>
            <Card>
              <Row>
                <Col xs={12}>
                  <div className="py-3 px-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-bold">Configurations</h5>
                    </div>
                    <hr className="mt-0 mb-4" />
                    <div className="d-flex justify-content-between">
                      <p className="text-muted fs-6">Cash Payment</p>

                      {user?.config?.isCashPaymenyAllowed ? (
                        <i
                          className="mdi mdi-check fw-bold text-success font-size-20"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <i
                          className="mdi mdi-cancel text-danger font-size-20"
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                    <div className="d-flex  justify-content-between">
                      <p className="text-muted fs-6">Free Events</p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config.noFreeEvents}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted fs-6">Orders Percantage</p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config.ordersPercantage}%
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted ">Ticket Percentage</p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config.perTicketPercentage}%
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted fs-6">Platform Fees</p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config.platformFees}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted fs-6">
                        No of Featured events for free
                      </p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config?.noOfFeaturedEventsAllowed || 0}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="text-muted fs-6">
                        Featured events tax percentage
                      </p>
                      <p className="fw-bold fs-4 text-secondary">
                        {user.config?.featuredEventsTaxPercentage || 0}%
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Configurations
