import {
  GET_MY_ORDERS,
  ON_GET_MY_ORDERS,
  GET_ORDERS,
  ON_GET_ORDERS,
  API_ERROR,
  API_SUCCESS,
  CREATE_ORDER_BY_EVENT_MANAGER,
  ON_CREATE_ORDER_BY_EVENT_MANAGER,
  CREATE_ORDER_BY_VENUE_OWNER,
  ON_CREATE_ORDER_BY_VENUE_OWNER,
  GET_ORDER_BY_EVENT_ID,
  ON_GET_ORDER_BY_EVENT_ID,
  GET_ORDER_BY_EVENT_ID_VO,
  ON_GET_ORDER_BY_EVENT_ID_VO,
  GET_ORDERS_STATISTICS,
  ON_GET_ORDERS_STATISTICS,
  CREATE_ORDER_BY_EVENT_ORGANIZER,
  ON_CREATE_ORDER_BY_EVENT_ORGANIZER,
  CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE,
  ON_CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE,
} from "./actionTypes"

const initialState = {
  orders: [],
  orderDetails: {},
  myOrders: [],
  loading: false,
  btnLoading: false,
  ordersStatistics: {},
  error: "",
  success: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORDER_BY_EVENT_MANAGER: {
      state = { ...state, loading: true }
      break
    }
    case ON_CREATE_ORDER_BY_EVENT_MANAGER: {
      state = {
        ...state,
        loading: false,
        myOrders: [...state.orders, action.payload],
      }
      break
    }
    case CREATE_ORDER_BY_EVENT_ORGANIZER: {
      state = { ...state, loading: true }
      break
    }
    case ON_CREATE_ORDER_BY_EVENT_ORGANIZER: {
      state = {
        ...state,
        loading: false,
        myOrders: [...state.orders, action.payload],
      }
      break
    }
    case CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE: {
      state = { ...state, loading: true }
      break
    }
    case ON_CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE: {
      state = {
        ...state,
        loading: false,
        myOrders: [...state.orders, action.payload],
      }
      break
    }
    case CREATE_ORDER_BY_VENUE_OWNER: {
      state = {
        ...state,
        loading: true,
        myOrders: [...state.orders, action.payload],
      }
      break
    }
    case ON_CREATE_ORDER_BY_VENUE_OWNER: {
      state = {
        ...state,
        loading: false,
        myOrders: [...state.orders, action.payload],
      }
      break
    }
    case GET_ORDERS: {
      state = { ...state, loading: true, orders: [] }
      break
    }
    case ON_GET_ORDERS: {
      state = { ...state, loading: false, orders: action.payload }
      break
    }

    case GET_MY_ORDERS: {
      state = { ...state, loading: true, orders: [] }
      break
    }
    case ON_GET_MY_ORDERS: {
      state = { ...state, laoding: false, orders: action.payload }
      break
    }

    case GET_ORDERS_STATISTICS: {
      state = { ...state, loading: true, ordersStatistics: [] }
      break
    }
    case ON_GET_ORDERS_STATISTICS: {
      state = { ...state, loading: false, ordersStatistics: action.payload }
      break
    }

    case GET_ORDER_BY_EVENT_ID: {
      state = { ...state, loading: true, orderDetails: [] }
      break
    }
    case ON_GET_ORDER_BY_EVENT_ID: {
      state = { ...state, loading: false, orderDetails: action.payload }
      break
    }

    case GET_ORDER_BY_EVENT_ID_VO: {
      state = { ...state, loading: true, orderDetails: [] }
      break
    }
    case ON_GET_ORDER_BY_EVENT_ID_VO: {
      state = { ...state, loading: false, orderDetails: action.payload }
      break
    }

    case API_ERROR: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        error: action.payload,
      }
      break
    }
    case API_SUCCESS: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        success: action.payload,
      }
      break
    }
  }
  return state
}
