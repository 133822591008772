import React, { useState } from "react"
import { Button, Modal } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { updateTickets } from "../../../store/tickets/actions"
import { apiError, apiSuccess } from "../../../store/tickets/actions"

const CommentModal = ({ openCommentModal, openCommentModalHandler, order }) => {
  const dispatch = useDispatch()
  const [feedback, setFeedback] = useState("")

  // get data from tickets saga
  const { btnLoading } = useSelector(state => ({
    btnLoading: state.Tickets.loading,
  }))

  function submitCommentHanler() {
    dispatch(
      updateTickets({
        ...order,
        feedbackNotes: feedback,
      })
    )
    openCommentModalHandler()
    setFeedback("")
  }

  return (
    <Modal
      isOpen={openCommentModal}
      toggle={() => {
        openCommentModalHandler()
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add Feedback Note</h5>
        <button
          type="button"
          onClick={() => {
            openCommentModalHandler()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body text-center">
        <div className="mb-3">
          <textarea
            className="form-control"
            required
            rows="6"
            placeholder="Enter your feedback"
            onChange={e => setFeedback(e.target.value)}
            value={feedback}
          />
          {btnLoading ? (
            <Button
              type="button"
              color="secondary"
              className="btn-lg mt-3"
              disabled
              onClick={() => submitCommentHanler()}
            >
              Submitting...
            </Button>
          ) : (
            <Button
              type="button"
              color="secondary"
              className="btn-lg mt-3"
              onClick={() => submitCommentHanler()}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default CommentModal
