export default [
  {
    label: "Monday",
    value: 2,
    flag: "Mon",
  },
  {
    label: "Tuesday",
    value: 3,
    flag: "Tue",
  },
  {
    label: "Wednesday",
    value: 4,
    flag: "Wed",
  },
  {
    label: "Thursday",
    value: 5,
    flag: "Thu",
  },
  {
    label: "Friday",
    value: 6,
    flag: "Fri",
  },
  {
    label: "Saturday",
    value: 7,
    flag: "Sat",
  },
  {
    label: "Sunday",
    value: 1,
    flag: "Sun",
  },
]
