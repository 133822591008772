import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
// import week days and month array
import weekDays from "common/weekDays"
import months from "common/months"

const OrderCard = ({ order }) => {
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  useEffect(() => {
    let dateObj = new Date(order?.bookingDate)
    let day = dateObj.getDay()
    let month = dateObj.getMonth() + 1
    let year = dateObj.getFullYear()
    let date = dateObj.getDate()
    //
    day = weekDays.find(d => d.value == day)
    month = months.find(m => m.value == month)
    //
    setDay(day?.flag)
    setDate(date)
    setMonth(month?.label)
    setYear(year)
  }, [day, date, month, year])

  return (
    <>
      <Row className="mb-5 mt-5">
        <Col sm={1} md={1} lg={1} className="order-1 mb-2  mb-lg-0">
          <div className="d-flex flex-column align-items-center">
            <p style={{ marginBottom: "2px" }}>{day}</p>
            <h6
              style={{ fontWeight: "bold", fontSize: "20px", color: "black" }}
            >
              {date}
            </h6>
          </div>
        </Col>
        <Col
          sm={12}
          md={12}
          lg={6}
          className="order-3 order-md-2 mt-sm-3 mb-3 mb-lg-0 mt-lg-0 "
        >
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div
                className="d-flex align-items-center text-muted"
                style={{ fontSize: "14px" }}
              >
                <div className="me-4">
                  {order?.eventDetails?.status == "completed" ? (
                    <span className="badge rounded-pill badge-soft-success font-size-12">
                      Completed
                    </span>
                  ) : (
                    <span className="badge rounded-pill badge-soft-warning font-size-12">
                      {order?.eventDetails?.status}
                    </span>
                  )}
                </div>

                <div style={{ color: "#afafc0" }}>
                  <i className="mdi mdi-calendar-check h5 me-2 text-success"></i>
                  {month}
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  {date}
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  at
                </div>
                <div className="ms-1" style={{ color: "#afafc0" }}>
                  {order?.eventDetails?.startTime}
                </div>
              </div>
            </div>
            <div className="mt-2 mb-1">
              <Link
                to={{
                  pathname: `/order-details`,
                  state: order,
                }}
                style={{ cursor: "pointer" }}
              >
                <h1
                  style={{
                    fontSize: "22px",
                    color: "black",
                  }}
                >
                  {order?.eventDetails?.title}
                </h1>
              </Link>
            </div>
            <div>
              <p
                style={{
                  fontSize: "16px",
                }}
              >
                {order?.eventDetails?.eventLocation}
              </p>
            </div>
            <div
              className="d-flex align-items-center text-muted"
              style={{ fontSize: "14px" }}
            >
              <div>
                <i className="mdi mdi-timetable h5 me-2 text-success"></i>
              </div>
              <div className="" style={{ color: "#afafc0" }}>
                {order?.slot?.startTime}
                {" - "}
                {order?.slot?.endTime}
              </div>
            </div>
            <div
              className="d-flex align-items-center text-muted my-1"
              style={{ fontSize: "14px" }}
            >
              <div>
                <i className="mdi mdi-timer-sand h5 me-2 text-success"></i>
              </div>
              <div className="" style={{ color: "#afafc0" }}>
                {order?.eventDetails?.requiredTime}
              </div>
            </div>
            <p style={{ fontSize: "16px" }} className="text-muted">
              {order?.eventDetails?.description?.substring(0, 250)}
              {" ..."}
            </p>
            <p
              className="fs-3 fw-bold text-secondary"
              style={{ color: "black" }}
            >
              &#163; {order?.slot?.price}
            </p>
          </div>
        </Col>
        <Col sm={11} md={12} lg={4} className="mb-3 order-2 order-md-3 ms-lg-5">
          <Link
            to={{
              pathname: `/order-details`,
              state: order,
            }}
          >
            <img
              src={order?.eventDetails?.attachments?.[0]}
              alt="event"
              style={{
                display: "block",
                minWidth: "100%",
                width: "100%",
                minHeight: "350px",
                maxHeight: "350px",
                height: "100%",
                borderRadius: "20px",
              }}
            />
          </Link>
        </Col>
      </Row>
      <hr className="mt-3" />
    </>
  )
}

export default OrderCard
