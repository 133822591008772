import React, { useEffect, useState } from "react"

import { datesDiffList } from "common/date"
import { getDay } from "common/getDay"
import Slider from "../../venues/Slider"

import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useLocation, useParams } from "react-router"

function EventManagerDetails() {
  const location = useLocation()
  const params = useParams()

  const [availabilities, setAvailablities] = useState([])
  const [filterDate, setFilterDate] = useState("")

  const manager = location.state?.manager || {}

  const handleClearFilters = () => setFilterDate("")

  useEffect(() => {
    if (!location.state?.manager) return
    const availabilities = manager?.availabilities || []
    const bookings = manager?.bookings || {}
    let minDate
    for (let i = 0; i < availabilities.length; i++) {
      const cDate = availabilities[i].startDate
      if (!minDate) {
        minDate = cDate
      } else {
        const date = new Date(cDate)
        const _minDate = new Date(minDate)
        if (_minDate > date) {
          minDate = cDate
        }
      }
    }

    let maxDate
    for (let j = 0; j < availabilities.length; j++) {
      for (let k = 0; k < availabilities[j].slots.length; k++) {
        const cDate = availabilities[j].slots[k].availableTill
        if (!maxDate) {
          maxDate = cDate
        } else {
          const date = new Date(cDate)
          const eDate = new Date(maxDate)
          if (date > eDate) {
            maxDate = cDate
          }
        }
      }
    }
    const datesList = datesDiffList(minDate, maxDate)
    let availData = datesList.map(date => {
      const day = getDay(date)
      let slots = []
      const availability = availabilities
        .filter(avail => avail.day == day)
        .shift()
      if (availability) {
        const availStartDate = new Date(availability.startDate)
        const currDate = new Date(date)
        if (availStartDate <= currDate) {
          const availSlots = availability.slots || []
          const bookedSlots = bookings[date] || []
          availSlots.forEach(slot => {
            const findSlot = bookedSlots.find(bSlot => bSlot == slot.id)
            if (findSlot) return
            const availTill = new Date(slot.availableTill)
            if (availTill >= currDate) {
              slots.push(slot)
            }
          })
        }
      }

      return {
        date,
        day,
        slots,
      }
    })
    if (filterDate) {
      availData = availData.filter(
        availability => availability.date == filterDate
      )
    }
    setAvailablities(availData)
  }, [manager, filterDate])
  return (
    <Card>
      <CardBody>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ alignContent: "center" }}>
              <img
                src={manager?.img}
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: "50%",
                }}
              />
              <p className="h6 my-3 ">{manager?.name}</p>
            </div>
          </div>

          <Row>
            <div className="my-5">
              {manager?.prevAttachments?.length > 0 && (
                <Slider images={manager.prevAttachments} />
              )}
            </div>
          </Row>
          <hr />
          <div className="mt-4">
            <div className="text-muted font-size-14">
              <p>{manager?.bio}</p>
            </div>
          </div>
          <div className="mt-5 mb-4">
            <h5>Availabilities:</h5>
            <Row>
              <Col md="4">
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <Label for="basicpill-price-input2">Search by Date</Label>
                    <span
                      className="mx-2 text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={handleClearFilters}
                    >
                      Clear
                    </span>
                  </div>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="Select date"
                    onChange={e => {
                      var date = new Date(e[0])
                      var fDate =
                        date.getFullYear() +
                        "/" +
                        (date.getMonth() + 1) +
                        "/" +
                        date.getDate()
                      setFilterDate(fDate)
                    }}
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      minDate: "today",
                    }}
                    value={filterDate}
                  />
                </div>
              </Col>
            </Row>
            <div>
              <div className="table-responsive">
                <Table className="align-middle table-bordered table-striped mb-0">
                  <thead className="bg-secondary text-white">
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Day</th>
                      <th>Title</th>
                      <th>Slots Time</th>
                      {/* <th>Valid Till</th> */}
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {availabilities?.length > 0
                      ? availabilities.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{item?.date}</td>
                              <td>{item?.day}</td>
                              <td>
                                {item.slots.map((slot, i) => {
                                  return (
                                    <div className="mb-2">
                                      {slot.title}
                                      <br />
                                    </div>
                                  )
                                })}
                                {!item.slots.length > 0 && (
                                  <p className="text-danger">Not Available</p>
                                )}
                              </td>
                              <td>
                                {item.slots.map((slot, i) => {
                                  return (
                                    <div className="">
                                      <i className="mdi mdi-clock-outline  me-2 fs-5"></i>
                                      {slot.startTime}
                                      {" - "}
                                      {slot.endTime}
                                      <br />
                                    </div>
                                  )
                                })}
                                {!item.slots.length > 0 && (
                                  <p className="text-danger">Not Available</p>
                                )}
                              </td>
                              {/* <td>
                            {item.slots.map((slot, i) => {
                              return (
                                <div className="mb-1">
                                  {slot.availableTill}
                                  <br />
                                </div>
                              )
                            })}
                          </td> */}
                              <td>
                                {item.slots.map((slot, i) => {
                                  return (
                                    <div className="d-flex  align-items-center">
                                      <i className="mdi mdi-cash me-1 fs-5 text-primary"></i>
                                      <span> &#163; {slot.price}</span>
                                      <br />
                                    </div>
                                  )
                                })}
                                {!item.slots.length > 0 && (
                                  <p className="text-danger">Not Available</p>
                                )}
                              </td>
                            </tr>
                          )
                        })
                      : null}
                  </tbody>
                </Table>

                {!availabilities?.length > 0 && (
                  <div className="d-flex justify-content-center p-2">
                    <h5 className="text-secondry">Empty</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default EventManagerDetails
