import PropTypes from "prop-types"
import React from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { connect } from "react-redux"

const LoadingContainer = () => <div>Loading...</div>

const MapsGoogle = props => {
  const selectedPlace = {}

  return (
    <React.Fragment>
      <div
        id="gmaps-markers"
        className="gmaps"
        style={{ position: "relative" }}
      >
        <Map
          google={props.google}
          zoom={16}
          style={{ width: "100%", height: "100%" }}
          initialCenter={{
            lat: props?.loc.latitude,
            lng: props?.loc.longitude,
          }}
        >
          <Marker
            title={props.address}
            name={"SOMA"}
            position={{ lat: props?.loc.latitude, lng: props?.loc.longitude }}
          />
          <Marker name={"Dolores park"} />
          <InfoWindow>
            <div>
              <h1>{selectedPlace.name}</h1>
            </div>
          </InfoWindow>
        </Map>
      </div>
    </React.Fragment>
  )
}

MapsGoogle.propTypes = {
  google: PropTypes.object,
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBL4JbKL4SotWhSAnoYflXy9fnHrmT52Lg",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(MapsGoogle)
)
