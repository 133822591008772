import {
  GET_MY_ORDERS,
  ON_GET_MY_ORDERS,
  GET_ORDERS,
  ON_GET_ORDERS,
  API_ERROR,
  API_SUCCESS,
  CREATE_ORDER_BY_EVENT_MANAGER,
  ON_CREATE_ORDER_BY_EVENT_MANAGER,
  CREATE_ORDER_BY_EVENT_ORGANIZER,
  ON_CREATE_ORDER_BY_EVENT_ORGANIZER,
  CREATE_ORDER_BY_VENUE_OWNER,
  ON_CREATE_ORDER_BY_VENUE_OWNER,
  GET_ORDER_BY_EVENT_ID,
  ON_GET_ORDER_BY_EVENT_ID,
  GET_ORDER_BY_EVENT_ID_VO,
  ON_GET_ORDER_BY_EVENT_ID_VO,
  GET_ORDERS_STATISTICS,
  ON_GET_ORDERS_STATISTICS,
  CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE,
  ON_CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE,
  CREATE_ORDER_FOR_EVENT_MANAGER,
  ON_CREATE_ORDER_FOR_EVENT_MANAGER,
  CREATE_ORDER_FOR_VENUE_OWNER,
  ON_CREATE_ORDER_FOR_VENUE_OWNER,
} from "./actionTypes"

export const getOrders = data => ({
  type: GET_ORDERS,
  payload: data,
})

export const onGetOrders = data => ({
  type: ON_GET_ORDERS,
  payload: data,
})

export const getMyOrders = data => ({
  type: GET_MY_ORDERS,
  payload: data,
})

export const onGetMyOrders = data => ({
  type: ON_GET_MY_ORDERS,
  payload: data,
})

export const getOrdersStatistics = data => ({
  type: GET_ORDERS_STATISTICS,
  payload: data,
})

export const onGetOrdersStatistics = data => ({
  type: ON_GET_ORDERS_STATISTICS,
  payload: data,
})

export const createOrderByEventManager = data => ({
  type: CREATE_ORDER_BY_EVENT_MANAGER,
  payload: data,
})

export const onCreateOrderByEventManager = data => ({
  type: ON_CREATE_ORDER_BY_EVENT_MANAGER,
  payload: data,
})

export const createOrderByEventManagervenue = data => ({
  type: CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE,
  payload: data,
})

export const onCreateOrderByEventManagerVenue = data => ({
  type: ON_CREATE_ORDER_BY_EVENT_ORGANIZER_VENUE,
  payload: data,
})

export const createOrderByEventOrganizer = data => ({
  type: CREATE_ORDER_BY_EVENT_ORGANIZER,
  payload: data,
})

export const onCreateOrderByEventOrganizer = data => ({
  type: ON_CREATE_ORDER_BY_EVENT_ORGANIZER,
  payload: data,
})

export const createOrderByVenueOwner = data => ({
  type: CREATE_ORDER_BY_VENUE_OWNER,
  payload: data,
})

export const onCreateOrderByVenueOwner = data => ({
  type: ON_CREATE_ORDER_BY_VENUE_OWNER,
  payload: data,
})

export const getOrderByEventId = data => ({
  type: GET_ORDER_BY_EVENT_ID,
  payload: data,
})

export const onGetOrderByEventIdVO = data => ({
  type: ON_GET_ORDER_BY_EVENT_ID_VO,
  payload: data,
})

export const getOrderByEventIdVO = data => ({
  type: GET_ORDER_BY_EVENT_ID_VO,
  payload: data,
})

export const onGetOrderByEventId = data => ({
  type: ON_GET_ORDER_BY_EVENT_ID,
  payload: data,
})

export const apiSuccess = msg => ({
  type: API_SUCCESS,
  payload: msg,
})

export const apiError = error => ({
  type: API_ERROR,
  payload: error,
})

export const createOrderForEventManager = data => ({
  type: CREATE_ORDER_FOR_EVENT_MANAGER,
  payload: data,
})

export const onCreateOrderForEventManager = data => ({
  type: ON_CREATE_ORDER_FOR_EVENT_MANAGER,
  payload: data,
})

export const createOrderForVenueOwner = data => ({
  type: CREATE_ORDER_FOR_VENUE_OWNER,
  payload: data,
})

export const onCreateOrderForVenueOwner = data => ({
  type: ON_CREATE_ORDER_FOR_VENUE_OWNER,
  payload: data,
})
