import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap"
import Slider from "../../venues/Slider"
import { Link, useParams } from "react-router-dom"
import {
  getVenueDetails,
  deleteVenue,
  updateVenueStatus,
} from "../../../store/venues/actions"
import { useDispatch, useSelector } from "react-redux"
import Loader from "components/Common/Loader"
import { datesDiffList } from "common/date"
import { getDay } from "common/getDay"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

const VenueDetail = () => {
  const [availabilities, setAvailablities] = useState([])
  const [filterDate, setFilterDate] = useState("")
  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => ({
    data: state.Venues.venueDetails,
    loading: state.Venues.loading,
  }))
  let { id } = useParams()

  const handleClearFilters = () => setFilterDate("")

  useEffect(() => {
    dispatch(getVenueDetails(id))
  }, [])

  useEffect(() => {
    if (!data) return
    const availabilities = data.availability || []
    const bookings = data.bookings || {}
    let minDate
    for (let i = 0; i < availabilities.length; i++) {
      const cDate = availabilities[i].startDate
      if (!minDate) {
        minDate = cDate
      } else {
        const date = new Date(cDate)
        const _minDate = new Date(minDate)
        if (_minDate > date) {
          minDate = cDate
        }
      }
    }

    let maxDate
    for (let j = 0; j < availabilities.length; j++) {
      for (let k = 0; k < availabilities[j].slots.length; k++) {
        const cDate = availabilities[j].slots[k].availableTill
        if (!maxDate) {
          maxDate = cDate
        } else {
          const date = new Date(cDate)
          const eDate = new Date(maxDate)
          if (date > eDate) {
            maxDate = cDate
          }
        }
      }
    }
    const datesList = datesDiffList(minDate, maxDate)
    let availData = datesList.map(date => {
      const day = getDay(date)
      let slots = []
      const availability = availabilities
        .filter(avail => avail.day == day)
        .shift()
      if (availability) {
        const availStartDate = new Date(availability.startDate)
        const currDate = new Date(date)
        if (availStartDate <= currDate) {
          const availSlots = availability.slots || []
          const bookedSlots = bookings[date] || []
          availSlots.forEach(slot => {
            const findSlot = bookedSlots.find(bSlot => bSlot == slot.id)
            if (findSlot) return
            const availTill = new Date(slot.availableTill)
            if (availTill >= currDate) {
              slots.push(slot)
            }
          })
        }
      }
      return {
        date,
        day,
        slots,
      }
    })

    if (filterDate) {
      availData = availData.filter(
        availability => availability.date == filterDate
      )
    }
    setAvailablities(availData)
  }, [data, filterDate])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Venue Details</title>
      </MetaTags>
      <div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <div>
            <Card>
              <CardBody>
                <div className="pt-3">
                  <div className="row justify-content-between">
                    <div className="d-flex justify-content-start align-items-center">
                      <h1
                        className="text-center"
                        style={{
                          flex: "0 1 auto",
                          position: "absolute",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        {data.venueName}
                      </h1>
                    </div>
                    <div>
                      <hr />
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col sm={12}>
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                              <div className="my-2 ms-0 ms-md-5">
                                {data?.isOnline ? (
                                  <Badge
                                    className="fs-6 bg-success"
                                    style={{ Width: "60px" }}
                                  >
                                    Active
                                  </Badge>
                                ) : (
                                  <Badge
                                    className="fs-6 bg-danger "
                                    style={{ Width: "60px" }}
                                  >
                                    Offline
                                  </Badge>
                                )}
                              </div>
                              <div className=" ms-5">Seats: {data?.seats}</div>
                              <div className="text-muted ms-5">
                                <i className="text-secondary mdi mdi-map-marker h4 me-1"></i>
                                <span>{data?.address}</span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <Row>
                        <div className="my-5">
                          {data?.attachments?.length > 0 && (
                            <Slider images={data.attachments} />
                          )}
                        </div>
                      </Row>
                      <hr />
                      <div className="mt-4">
                        <div className="text-muted font-size-14">
                          <p>{data.description}</p>
                        </div>
                      </div>
                      <div className="mt-5 mb-4">
                        <h4>Venue Availability</h4>
                        <Row>
                          <Col md="4">
                            <div className="mb-3">
                              <div className="d-flex justify-content-between">
                                <Label for="basicpill-price-input2">
                                  Search by Date
                                </Label>
                                <span
                                  className="mx-2 text-primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClearFilters}
                                >
                                  Clear
                                </span>
                              </div>
                              <Flatpickr
                                className="form-control d-block"
                                placeholder="Select date"
                                onChange={e => {
                                  var date = new Date(e[0])
                                  var fDate =
                                    date.getFullYear() +
                                    "/" +
                                    (date.getMonth() + 1) +
                                    "/" +
                                    date.getDate()
                                  setFilterDate(fDate)
                                }}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  minDate: "today",
                                }}
                                value={filterDate}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div>
                          <div className="table-responsive">
                            <Table className="align-middle table-bordered table-striped mb-0">
                              <thead className="bg-secondary text-white">
                                <tr>
                                  <th>#</th>
                                  <th>Date</th>
                                  <th>Day</th>
                                  <th>Title</th>
                                  <th>Slots Time</th>
                                  {/* <th>Valid Till</th> */}
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {availabilities?.length > 0
                                  ? availabilities.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <th scope="row">{index + 1}</th>
                                          <td>{item?.date}</td>
                                          <td>{item?.day}</td>
                                          <td>
                                            {item.slots.map((slot, i) => {
                                              return (
                                                <div className="mb-2">
                                                  {slot.title}
                                                  <br />
                                                </div>
                                              )
                                            })}
                                            {!item.slots.length > 0 && (
                                              <p className="text-danger">
                                                Not Available
                                              </p>
                                            )}
                                          </td>
                                          <td>
                                            {item.slots.map((slot, i) => {
                                              return (
                                                <div className="">
                                                  <i className="mdi mdi-clock-outline  me-2 fs-5"></i>
                                                  {slot.startTime}
                                                  {" - "}
                                                  {slot.endTime}
                                                  <br />
                                                </div>
                                              )
                                            })}
                                            {!item.slots.length > 0 && (
                                              <p className="text-danger">
                                                Not Available
                                              </p>
                                            )}
                                          </td>
                                          {/* <td>
                                            {item.slots.map((slot, i) => {
                                              return (
                                                <div className="mb-1">
                                                  {slot.availableTill}
                                                  <br />
                                                </div>
                                              )
                                            })}
                                          </td> */}
                                          <td>
                                            {item.slots.map((slot, i) => {
                                              return (
                                                <div className="d-flex  align-items-center">
                                                  <i className="mdi mdi-cash me-1 fs-5 text-primary"></i>
                                                  <span>
                                                    {" "}
                                                    &#163; {slot.price}
                                                  </span>
                                                  <br />
                                                </div>
                                              )
                                            })}
                                            {!item.slots.length > 0 && (
                                              <p className="text-danger">
                                                Not Available
                                              </p>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })
                                  : null}
                              </tbody>
                            </Table>
                            {!availabilities?.length > 0 && !loading ? (
                              <div className="d-flex justify-content-center p-2">
                                <h5 className="text-secondry">Empty</h5>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!data && (
                  <div className="d-flex justify-content-center">
                    <h4>No Venue Data</h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default VenueDetail
