import { v4 } from "uuid"
import { storage, db, auth } from "../config/firebaseConfig"
import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage"

export async function uploadFiles(files, path) {
  try {
    if (!files) {
      return
    }
    return await Promise.all(
      files?.map(async file => {
        const upload = await uploadBytes(ref(storage, `${path}/${v4()}`), file)
        return await getDownloadURL(upload.ref)
      })
    )
  } catch (err) {
    throw new Error(err.message)
  }
}

export async function uploadImg(file, path) {
  if (!file) {
    return
  }
  try {
    const upload = await uploadBytes(ref(storage, `${path}/${v4()}`), file)
    const url = await getDownloadURL(upload.ref)
    return url
  } catch (err) {
    throw new Error(err.message)
  }
}
