import React, { useEffect, useState, useRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as moment from "moment"
import { Link } from "react-router-dom"
// loader
import Loader from "../../../components/Common/Loader"
import { Button, Card, CardBody, Col, Row, Badge, Modal } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import EventManagerProfilt from "./EventManagerProfilt"
import { getTickets, tickedCheckedIn } from "../../../store/tickets/actions"
import { apiError, apiSuccess } from "../../../store/tickets/actions"
// qr scanner
import ScanQR from "./ScanQR"
// comment modal
import CommentModal from "./CommentModal"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

const Tickets = props => {
  const dispatch = useDispatch()
  const [openCommentModal, setOpenCommentModal] = useState(false)
  //
  const [selectedOrder, setSelectedOrder] = useState("")
  //current user role
  const [role, setRole] = useState("")
  //
  const [openQR, setOpenQR] = useState(false)

  // get data from tickets saga
  const { orders, loading, success, error } = useSelector(state => ({
    orders: state.Tickets.data,
    loading: state.Tickets.loading,
    success: state.Tickets.success,
    error: state.Tickets.error,
  }))

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search

  //openCommentModalHandler
  function openCommentModalHandler() {
    setOpenCommentModal(!openCommentModal)
  }
  //set order handler
  function setOrderHandler(order) {
    setSelectedOrder(order)
    openCommentModalHandler()
  }

  // table columns
  const EcommerceOrderColumns = toggleModal => [
    {
      dataField: "ticketId",
      text: "Ticket ID",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link
          to="#"
          className="
        fw-bold"
          style={{ color: "black" }}
        >
          #{row?.ticketId?.substring(0, 7)}
        </Link>
      ),
    },
    {
      dataField: "userName",
      text: "Buyer Name",
      sort: true,
    },
    {
      dataField: "purchaseDate",
      text: "Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.purchaseDate),
    },
    {
      dataField: "amountPaid",
      text: "Amount",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={"#"} className="text-muted" style={{ color: "black" }}>
          &#163;{row?.amountPaid}
        </Link>
      ),
    },
    {
      dataField: "isPaid",
      text: "Payment Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 badge-soft-" + ${
            row?.isPaid ? "success" : "warning"
          }`}
          color={`${row?.isPaid ? "success" : "warning"}`}
          pill
        >
          {row?.isPaid ? "Paid" : "Pending"}
        </Badge>
      ),
    },
    {
      dataField: "paymentMethod",
      text: "Payment Method",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <i
            className={
              row.paymentMethod == "PayPal"
                ? "fab fa-cc-paypal" + " me-1"
                : "fab fas fa-money-bill me-1"
            }
          />{" "}
          {row.paymentMethod}
        </>
      ),
    },
    {
      dataField: "ticketType",
      text: "Package",
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Comment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <Button
          type="button"
          color="secondary"
          className="btn-sm btn-rounded"
          onClick={() => setOrderHandler(order)}
        >
          Add Comment
        </Button>
      ),
    },
  ]
  // tables columns end

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  // table helpers
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const defaultSorted = [
    {
      dataField: "ticketId",
      order: "desc",
    },
  ]

  // tables helpers end

  // qr handlers
  function openQrHandler() {
    setOpenQR(!openQR)
  }
  function handleScanQr(decodedText) {
    dispatch(tickedCheckedIn(decodedText))
  }
  //

  // useEffects

  useEffect(() => {
    dispatch(getTickets(props?.eventId))
  }, [])

  useEffect(() => {
    const manager = JSON.parse(localStorage.getItem("authUser"))
    setRole(manager.role)
  }, [])

  return (
    <React.Fragment>
      {!loading ? (
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={EcommerceOrderColumns()}
                  data={orders}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={EcommerceOrderColumns()}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                            <Col sm="8">
                              <div className="text-sm-end">
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded  mb-2 me-2"
                                  onClick={() => openQrHandler()}
                                >
                                  <i className="mdi mdi-plus me-1" />
                                  QR Scanner
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField="id"
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  ref={node}
                                />
                              </div>
                              {!orders.length > 0 && (
                                <h5 className="mt-2 text-muted text-center">
                                  No Tickets Sold Yet!
                                </h5>
                              )}
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
                {/* billing tools */}
                <EventManagerProfilt
                  eventData={props.eventData}
                  tickets={orders}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      <Modal
        isOpen={openQR}
        toggle={() => {
          openQrHandler()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">QR Scan Ticket</h5>
          <button
            type="button"
            onClick={() => {
              openQrHandler()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <ScanQR openQrHandler={openQrHandler} handleScanQr={handleScanQr} />
        </div>
      </Modal>
      <CommentModal
        openCommentModal={openCommentModal}
        openCommentModalHandler={openCommentModalHandler}
        order={selectedOrder}
      />
      {success ? (
        <SweetAlert
          success
          title={success}
          onConfirm={() => {
            dispatch(apiSuccess(""))
          }}
        ></SweetAlert>
      ) : null}

      {error ? (
        <SweetAlert
          error
          title={error}
          onConfirm={() => {
            dispatch(apiError(""))
          }}
        ></SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default Tickets
