import React from "react"

import { Table, Row, Col } from "reactstrap"
import Loader from "components/Common/Loader"

function PreviousTransactions() {
  const data = []
  const loading = false
  return (
    <div>
      <div className="table-responsive">
        <h4 className="my-3">Transactions</h4>
        <div className="table-responsive">
          <Table className="align-middle table-bordered table-striped table-responsive mb-0">
            <thead className="bg-secondary text-white">
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Title</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0
                ? props?.data?.map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item?.startDate}</td>
                      <td>{item?.day}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </div>
        {data.length == 0 && !loading ? (
          <p className="mt-4 text-center h5">No Previous Transactions</p>
        ) : null}
      </div>
    </div>
  )
}

export default PreviousTransactions
