import {
  ADD_AVAILABILITIES,
  ON_ADD_AVAILABILITIES,
  DELETE_AVAILABILITIES,
  ON_DELETE_AVAILABILITIES,
  GET_AVAILABILITIES,
  ON_GET_AVAILABILITIES,
  ADD_VENUE_DETAILS,
  ON_ADD_VENUE_DETAILS,
  GET_VENUE_DETAILS,
  ON_GET_VENUE_DETAILS,
  GET_MANAGERS,
  ON_GET_MANAGERS,
  GET_MANAGER,
  ON_GET_MANAGER,
  GET_ALL_MANAGERS,
  ON_GET_ALL_MANAGERS,
  API_ERROR,
  API_SUCCESS,
  GET_EVENT_MANAGERS,
  ON_GET_EVENT_MANAGERS,
} from "./actionTypes"

export const addAvailabilities = data => ({
  type: ADD_AVAILABILITIES,
  payload: data,
})

export const onAddAvailabilities = data => ({
  type: ON_ADD_AVAILABILITIES,
  payload: data,
})

export const deleteAvailabilities = data => ({
  type: DELETE_AVAILABILITIES,
  payload: data,
})

export const onDeleteAvailabilities = data => ({
  type: ON_DELETE_AVAILABILITIES,
  payload: data,
})

export const getAvailabilities = data => ({
  type: GET_AVAILABILITIES,
  payload: data,
})

export const onGetAvailabilities = data => ({
  type: ON_GET_AVAILABILITIES,
  payload: data,
})

export const addVenueDetails = data => ({
  type: ADD_VENUE_DETAILS,
  payload: data,
})

export const onAddVenueDetails = data => ({
  type: ON_ADD_VENUE_DETAILS,
  payload: data,
})

export const getVenueDetails = data => ({
  type: GET_VENUE_DETAILS,
  payload: data,
})

export const onGetVenueDetails = data => ({
  type: ON_GET_VENUE_DETAILS,
  payload: data,
})

export const getManagers = data => ({
  type: GET_MANAGERS,
  payload: data,
})

export const onGetManagers = data => ({
  type: ON_GET_MANAGERS,
  payload: data,
})

export const getManager = data => ({
  type: GET_MANAGER,
  payload: data,
})

export const onGetManager = data => ({
  type: ON_GET_MANAGER,
  payload: data,
})

export const getAllManagers = data => ({
  type: GET_ALL_MANAGERS,
  payload: data,
})

export const onGetAllManagers = data => ({
  type: ON_GET_ALL_MANAGERS,
  payload: data,
})

export const apiSuccess = success => ({
  type: API_SUCCESS,
  payload: success,
})

export const apiError = error => ({
  type: API_ERROR,
  payload: error,
})

export const getEventManagers = data => ({
  type: GET_EVENT_MANAGERS,
  payload: data,
})

export const onGetEventManagers = data => ({
  type: ON_GET_EVENT_MANAGERS,
  payload: data,
})
