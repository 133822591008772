import styled from "styled-components"

export const InfoBtn = styled.div`
  margin: 7px;
  border-radius: 7px;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  color: #212121;
  fontsize: 13px;
  padding: 4px 18px;
  text-decoration: none;
  &:hover {
    background-color: #f5f5f5;
    border: 1px solid #eeeeee;
  }
`
export const Desc = styled.div`
  &:hover {
    color: #f0876f;
  }
`
