import React from "react"

import SweetAlert from "react-bootstrap-sweetalert"
function Loader({ text }) {
  return (
    <SweetAlert
      title=""
      onConfirm={() => {}}
      showConfirm={false}
      style={{ background: "transparent" }}
    >
      <div>
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden">Loader...</span>
        </div>
        <div className="my-2" style={{ color: "#ffffff" }}>
          {text || "Loading..."}
        </div>
      </div>
    </SweetAlert>
  )
}

export default Loader
