import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import AvailabilityTable from "./AvailabilityTable"
import {
  getAvailabilities,
  deleteAvailabilities,
} from "../../store/managers/actions"
import { useDispatch, useSelector } from "react-redux"

const AvailabilityMain = props => {
  const dispatch = useDispatch()
  const { availabilities, btnLoading, loading } = useSelector(state => ({
    availabilities: state.Managers.availabilities,
    btnLoading: state.Managers.btnLoading,
    loading: state.Managers.loading,
  }))

  const handleRemove = (index, i) => {
    dispatch(deleteAvailabilities({ index, i }))
  }

  useEffect(() => {
    dispatch(getAvailabilities())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Your Availability</title>
      </MetaTags>

      <Row className="justify-content-center">
        <Col xl={12}>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-end ">
                <Link to="add-availability">
                  <Button color="primary" className="btn btn-primary">
                    Add Availability
                  </Button>
                </Link>
              </div>
              <div>
                <AvailabilityTable
                  data={availabilities}
                  remove={handleRemove}
                  loading={loading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AvailabilityMain
