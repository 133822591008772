export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const UPDATE_NEW_PASSWORD = "UPDATE_NEW_PASSWORD"
export const ON_UPDATE_NEW_PASSWORD = "ON_UPDATE_NEW_PASSWORD"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"

export const GET_LATEST_PROFILE = "GET_LATEST_PROFILE"

// export const EVENT_MANAGER_ADD_AVAILABILITIES =
//   "EVENT_MANAGER_ADD_AVAILABILITIES"
// export const ON_EVENT_MANAGER_ADD_AVAILABILITIES =
//   "ON_EVENT_MANAGER_ADD_AVAILABILITIES"

// export const API_SUCCESS = "API_SUCCESS"
// export const API_ERROR = "API_ERROR"
