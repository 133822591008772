import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
// pages
import VenueOwnerProfile from "./VenueOwnerProfile/index"
import EventManagerProfile from "./EventManagerProfile/index"
import EventOrganizerProfile from "./EventOrganizerProfile/index"

const UserProfile = props => {
  var user = JSON.parse(localStorage.getItem("authUser"))
  return (
    <>
      <MetaTags>
        <title>Profile</title>
      </MetaTags>

      {user.role == "venueOwner" ? (
        <VenueOwnerProfile />
      ) : user.role == "eventManager" ? (
        <EventManagerProfile />
      ) : (
        <EventOrganizerProfile />
      )}
    </>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {
    editProfile,
    resetProfileFlag,
  })(UserProfile)
)
