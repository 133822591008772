import PropTypes from "prop-types"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import logoLightPng from "../../assets/images/logo-light.png"
import logoDark from "../../assets/images/logo-dark.png"
import emanager from "../../assets/images/emanager.jpg"
import vowner from "../../assets/images/vowner.jpg"
import EventOrganizer3 from "../../assets/images/EventOrganizer3.jpg"

// css
import "./SignUp.css"

const Signup = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.registerUser(values)
  }

  useEffect(() => {
    props.apiError("")
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register</title>
      </MetaTags>

      <div className="account-pages my-2 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-3 mb-1">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="70"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLightPng}
                        alt=""
                        height="70"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Free Register
                    </h4>
                    <p className="text-muted text-center mb-5">
                      Choose your role.
                    </p>
                    <div className="mb-3 row mt-4 ">
                      <div className="col-6 text-center m-auto">
                        <Link to="/register-venue-owner">
                          <div className="card venue">
                            <img src={vowner} className="card-img" alt="..." />
                            <div className="card-img-top">
                              <p className="card-text mt-2 mb-2 h5">
                                Venue Owner
                              </p>
                              <p className="text-secondary fs-6">
                                Venue Owners create a profile for their venue,
                                add availability, create events and hire event
                                managers. Can be booked for events by managers
                                and organisers.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-6 text-center m-auto ">
                        <Link to="/register-event-manager">
                          <div className="card event">
                            <img
                              src={emanager}
                              className="card-img"
                              alt="..."
                            />
                            <div className="card-img-top">
                              <p className="card-text mt-2 mb-2 h5">
                                Event Manager
                              </p>
                              <p className="text-secondary fs-6">
                                Event Managers create their own events to teach
                                and can book a venue to host the event. Can be
                                booked by venues and organisers.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="col-6 text-center mt-3 m-auto ">
                        <Link to="/register-event-organizer">
                          <div className="card organizer">
                            <img
                              src={EventOrganizer3}
                              className="card-img"
                              alt="..."
                            />
                            <div className="card-img-top">
                              <p className="card-text mt-2 mb-2 h5">
                                Event Organizer
                              </p>
                              <p className="text-secondary fs-6">
                                Event Organisers focus on organising and hiring
                                managers and venues for events and classes,
                                creating events, and managing the event with
                                someone else leading it.
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Signup.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Signup)
