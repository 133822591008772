import React, { useState, useEffect } from "react"
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
// import week days and month array
import weekDays from "common/weekDays"
import months from "common/months"
const EventManagerDetails = ({ booking, bookingDate }) => {
  const user = JSON.parse(localStorage.getItem("authUser"))
  const [day, setDay] = useState()
  const [date, setDate] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()

  useEffect(() => {
    let dateObj = new Date(bookingDate)
    let day = dateObj.getDay()
    let month = dateObj.getMonth() + 1
    let year = dateObj.getFullYear()
    let date = dateObj.getDate()
    //
    day = weekDays.find(d => d.value == day)
    month = months.find(m => m.value == month)
    //
    setDay(day.flag)
    setDate(date)
    setMonth(month.label)
    setYear(year)
  }, [day, date, month, year])
  return (
    <Row>
      <Col sm={12}>
        <Card>
          <CardBody>
            <div className="p-4 border my-3 row justify-content-center">
              <div className="h4 text-primary mb-4"># Order Details #</div>
              <Row>
                <Col xl="4" sm="6">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="4">
                          <div className="text-lg-center">
                            {user?.img ? (
                              <img
                                src={user.img}
                                className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle"
                                alt="img"
                              />
                            ) : (
                              <div className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle">
                                <span
                                  className={
                                    "avatar-title rounded-circle bg-soft bg-" +
                                    data.color +
                                    " text-primary font-size-16"
                                  }
                                >
                                  {user.name.charAt(0)}
                                </span>
                              </div>
                            )}

                            <h5 className="mb-1 font-size-15 text-truncate">
                              {user.name}
                            </h5>
                            {user.category}
                          </div>
                        </Col>
                        <Col lg="8">
                          <div>
                            <Link
                              to={"/invoices-detail/"}
                              className="d-block text-primary mb-2"
                            >
                              {month} {date}
                              {", "}
                              {year}
                            </Link>
                            <h5 className="text-truncate mb-4 mb-lg-5">
                              {user.email}
                            </h5>
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item me-3">
                                <h5 className="font-size-14" id="amountTooltip">
                                  <i className="bx bx-money me-1 text-muted" />
                                  &#163;
                                  {booking.price}
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="amountTooltip"
                                  >
                                    Amount
                                  </UncontrolledTooltip>
                                </h5>
                              </li>
                              <li className="list-inline-item">
                                <h5
                                  className="font-size-14"
                                  id="duedateTooltip"
                                >
                                  <i className="bx bx-calendar me-1 text-muted" />
                                  {booking?.startTime} - {booking?.endTime}
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="duedateTooltip"
                                  >
                                    Booked Slot
                                  </UncontrolledTooltip>
                                </h5>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default EventManagerDetails
