import { takeEvery, put, call } from "redux-saga/effects"

import {
  ADD_AVAILABILITIES,
  ADD_VENUE_DETAILS,
  DELETE_AVAILABILITIES,
  GET_AVAILABILITIES,
  GET_VENUE_DETAILS,
  GET_MANAGERS,
  GET_ALL_MANAGERS,
  GET_MANAGER,
  GET_EVENT_MANAGERS,
} from "./actionTypes"
import {
  onAddAvailabilities,
  onAddVenueDetails,
  onGetAvailabilities,
  onGetVenueDetails,
  onGetManagers,
  apiError,
  apiSuccess,
  onGetAllManagers,
  onGetManager,
  onDeleteAvailabilities,
} from "./actions"

import { db } from "../../config/firebaseConfig"
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore"
import getAddressFromLngLat from "components/Common/getAddressFromLngLat"
import { dateToDateStr, dateStrToDate } from "common/date"

const collectionName = "managers"

const colRef = collection(db, collectionName)

export function* getManagerProfile(managerId) {
  try {
    const docRef = doc(db, collectionName, managerId)
    const userDoc = yield call(getDoc, docRef)
    return userDoc.data()
  } catch (err) {
    console.log("get manager profile error", err.message)
  }
}
export function* updateManagerProfile(managerId, data) {
  try {
    const docRef = doc(db, collectionName, managerId)
    yield call(updateDoc, docRef, data)
    return data
  } catch (err) {
    console.log("update profile error ", err.message)
  }
}

function* addAvailabilities({ payload: { data, history } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const { day, slots, startDate } = data
    const docRef = doc(db, collectionName, user.id)
    let userData = (yield call(getDoc, docRef))?.data()
    let existAvailabilities = userData.availabilities || []
    const isFound = existAvailabilities.find(
      avail => avail?.startDate == startDate
    )
    if (isFound) {
      userData.availabilities = existAvailabilities.map(avail => {
        if (avail.startDate == startDate) {
          return {
            day,
            startDate,
            slots: [...avail.slots, ...slots],
          }
        }
        return avail
      })
    } else {
      userData.availabilities = userData?.availabilities || []
      userData.availabilities.push(data)
    }
    yield call(updateDoc, docRef, userData)
    yield put(onAddAvailabilities(data))
    yield put(apiSuccess("Added Successfully"))
    history.push("/availability")
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getAvailabilities() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const docRef = doc(db, collectionName, user.id)

    const response = yield call(getDoc, docRef)
    const data = response.data()
    yield put(onGetAvailabilities(data?.availabilities || []))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* deleteAvailabilities({ payload }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const { index, i } = payload
    const docRef = doc(db, collectionName, user.id)
    const response = yield call(getDoc, docRef)
    const manager = response.data()
    const { availabilities = [], bookings = {} } = manager
    const slot = availabilities[index].slots[i]

    const currentDate = new Date()

    let isBookingFound = false

    for (let dateStr in bookings) {
      const date = dateStrToDate(dateStr)
      if (date >= currentDate) {
        const currentBookings = bookings[date] || []
        for (let i = 0; i < currentBookings.length; i++) {
          if (currentBookings[i] == slot.id) {
            isBookingFound = true
          }
        }
      }
    }
    if (isBookingFound) {
      throw new Error("Sorry! Unable to delete. Slot is already booked.")
    }
    const filteredArray = availabilities
      .map((item, ind) => {
        if (payload.index == ind) {
          var newSlots = item.slots.filter(
            (slot, slotIndex) => slotIndex !== payload.i
          )
          return { ...item, slots: newSlots }
        }
        return item
      })
      .filter(item => item.slots.length !== 0)
    yield call(updateDoc, docRef, {
      availabilities: filteredArray,
    })
    yield put(onDeleteAvailabilities(filteredArray || []))
  } catch (err) {
    console.log("delete availability error ", err.message)
    yield put(apiError(err.message))
  }
}

function* addVenueDetails({ payload }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const docRef = doc(db, collectionName, user.id)
    yield call(updateDoc, docRef, {
      venueDetails: payload,
    })
    yield put(onAddVenueDetails(payload))
    yield put(apiSuccess("Venue Details Updated"))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getVenueDetails() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const docRef = doc(db, collectionName, user.id)
    const response = yield call(getDoc, docRef)
    const data = response.data()
    yield put(onGetVenueDetails(data?.venueDetails || ""))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getManagers() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const search = user.role == "venueOwner" ? "eventManager" : "venueOwner"
    const q = query(colRef, where("role", "==", search))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc.data())
    yield put(onGetManagers(data))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getEventManagers({ payload }) {
  try {
    const search = "eventManager"
    const q = query(colRef, where("role", "==", search))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc.data())
    yield put(onGetManagers(data))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

// get manager detail by id

function* getManagerById({ payload: data }) {
  try {
    const docRef = doc(db, collectionName, data.id)
    const docData = yield call(getDoc, docRef)
    const managerDetails = docData.data()
    yield put(onGetManager(managerDetails))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

// get all event managers

function* getAllManagers() {
  try {
    const q = query(colRef, where("role", "==", "eventManager"))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())
    const resolveData = yield Promise.all(
      data?.map(async d => {
        let startingFrom = Infinity
        if (!d?.availabilities) {
          startingFrom = 0
        }
        d?.availabilities?.map(item => {
          item.slots.map(s => {
            if (s?.price < startingFrom) {
              startingFrom = s?.price
            }
          })
        })
        d.startingFrom = startingFrom
        return d
      })
    )

    const resolveLocation = yield Promise.all(
      resolveData.map(async d => {
        const { latitude, longitude } = d.location
        const address = await getAddressFromLngLat(latitude, longitude)
        d.address = address
        return d
      })
    )

    yield put(onGetAllManagers(resolveLocation))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* root() {
  yield takeEvery(ADD_AVAILABILITIES, addAvailabilities)
  yield takeEvery(GET_AVAILABILITIES, getAvailabilities)
  yield takeEvery(ADD_VENUE_DETAILS, addVenueDetails)
  yield takeEvery(GET_VENUE_DETAILS, getVenueDetails)
  yield takeEvery(GET_MANAGERS, getManagers)
  yield takeEvery(GET_ALL_MANAGERS, getAllManagers)
  yield takeEvery(GET_MANAGER, getManagerById)
  yield takeEvery(DELETE_AVAILABILITIES, deleteAvailabilities)
  yield takeEvery(GET_EVENT_MANAGERS, getEventManagers)
}

export default root
