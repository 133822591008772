import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore"
// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"
import { db } from "../../../config/firebaseConfig"

const colRef = collection(db, "managers")

function* forgetUser({ payload: { email, history } }) {
  const auth = getAuth()
  try {
    const q = query(colRef, where("email", "==", email.email))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc.data())
    if (data.length > 0) {
      yield call(sendPasswordResetEmail, auth, email.email)
      const res = yield call(sendPasswordResetEmail, auth, email.email)
      yield put(
        userForgetPasswordSuccess("Reset password settings send to your email.")
      )
    } else {
      return yield put(userForgetPasswordError("Email not found!"))
    }
  } catch (error) {
    yield put(userForgetPasswordError("Something went wrong!"))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
