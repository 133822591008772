import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
//
import Events from "./Events"
import Orders from "./Orders"
// saga
import { useDispatch, useSelector } from "react-redux"
import { getEventStatistics } from "../../store/events/actions"
import { getOrdersStatistics } from "../../store/orders/actions"
import { getLatestProfile } from "../../store/auth/profile/actions"
// card
//
import Loader from "components/Common/Loader"
import { useHistory } from "react-router-dom"

const Dashboard = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  var user = JSON.parse(localStorage.getItem("authUser"))

  const { eventStatistics, eventLoading } = useSelector(state => ({
    eventStatistics: state.Events.eventStatistics,
    eventLoading: state.Events.loading,
  }))

  const { ordersStatistics, ordersLoading } = useSelector(state => ({
    ordersStatistics: state.Orders.ordersStatistics,
    ordersLoading: state.Orders.loading,
  }))

  useEffect(() => {
    dispatch(getLatestProfile({ history }))
    dispatch(getEventStatistics())
    if (user.role != "eventOrganizer") {
      dispatch(getOrdersStatistics())
    }
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Dashboard</title>
      </MetaTags>
      <div
        style={{ height: "auto", marginBottom: "20px", paddingBottom: "100px" }}
      >
        {!eventLoading ? (
          <Events eventStatistics={eventStatistics} />
        ) : (
          <Loader />
        )}
        {!ordersLoading && user.role != "eventOrganizer" ? (
          <Orders ordersStatistics={ordersStatistics} />
        ) : (
          ""
        )}
        {ordersLoading ? <Loader /> : ""}
      </div>
    </React.Fragment>
  )
}

export default Dashboard
