import {
  ADD_EVENT,
  ON_ADD_EVENT,
  GET_EVENTS,
  ON_GET_EVENTS,
  API_ERROR,
  API_SUCCESS,
  ADD_SLOT_BOOKING,
  UPDATE_EVENT,
  ON_UPDATE_EVENT,
  GET_EVENT_STATISTICS,
  ON_GET_EVENT_STATISTICS,
  ADD_EVENT_BY_EVENT_ORGANIZER,
  ON_ADD_EVENT_BY_EVENT_ORGANIZER,
  GET_EVENTS_BY_EVENT_ORGANIZER,
  ON_GET_EVENTS_BY_EVENT_ORGANIZER,
  ADD_EVENT_MANUAL_VENUE,
  ON_ADD_EVENT_MANUAL_VENUE,
  ADD_EVENT_BY_EVENT_MANAGER,
  ON_ADD_EVENT_BY_EVENT_MANAGER,
  ADD_EVENT_BY_VENUE_OWNER,
  ON_ADD_EVENT_BY_VENUE_OWNER,
  DELETE_EVENT,
  ON_DELETE_EVENT,
} from "./actionTypes"

export const addEvents = data => ({
  type: ADD_EVENT,
  payload: data,
})

export const onAddEvent = data => ({
  type: ON_ADD_EVENT,
  payload: data,
})

export const addEventManualVenue = data => ({
  type: ADD_EVENT_MANUAL_VENUE,
  payload: data,
})

export const onAddEventManualVenue = data => ({
  type: ON_ADD_EVENT_MANUAL_VENUE,
  payload: data,
})

export const addEventByEventManager = data => ({
  type: ADD_EVENT_BY_EVENT_MANAGER,
  payload: data,
})

export const onAddEventByEventManager = data => ({
  type: ON_ADD_EVENT_BY_EVENT_MANAGER,
  payload: data,
})

export const addEventByVneueOwner = data => ({
  type: ADD_EVENT_BY_VENUE_OWNER,
  payload: data,
})

export const onAddEventByVenueOwner = data => ({
  type: ON_ADD_EVENT_BY_VENUE_OWNER,
  payload: data,
})

export const addEventByEventOrganizer = data => ({
  type: ADD_EVENT_BY_EVENT_ORGANIZER,
  payload: data,
})

export const onAddEventByEventOrganizer = data => ({
  type: ON_ADD_EVENT_BY_EVENT_ORGANIZER,
  payload: data,
})

export const getEventsByEventOrganizer = data => ({
  type: GET_EVENTS_BY_EVENT_ORGANIZER,
  payload: data,
})

export const onGetEventsByEventOrganizer = data => ({
  type: ON_GET_EVENTS_BY_EVENT_ORGANIZER,
  payload: data,
})

export const getEvents = data => ({
  type: GET_EVENTS,
  payload: data,
})

export const onGetEvents = data => ({
  type: ON_GET_EVENTS,
  payload: data,
})

export const updateEvent = data => ({
  type: UPDATE_EVENT,
  payload: data,
})

export const onUpdateEvent = data => ({
  type: ON_UPDATE_EVENT,
  payload: data,
})

export const getEventStatistics = data => ({
  type: GET_EVENT_STATISTICS,
  payload: data,
})

export const onGetEventStatistics = data => ({
  type: ON_GET_EVENT_STATISTICS,
  payload: data,
})

export const apiSuccess = success => ({
  type: API_SUCCESS,
  payload: success,
})

export const apiError = error => ({
  type: API_ERROR,
  payload: error,
})

export const addSlotBooking = data => ({
  type: ADD_SLOT_BOOKING,
  payload: data,
})

export const deleteEvent = data => {
  return {
    type: DELETE_EVENT,
    payload: data,
  }
}

export const onDeleteEvent = data => {
  return {
    type: ON_DELETE_EVENT,
    payload: data,
  }
}
