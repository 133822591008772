import React, { useEffect, useState } from "react"

import PreviousTransactions from "./PreviousTransactions"
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Badge,
  Alert,
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux"

import firebaseService from "services/firebaseService"
import { COLLECTIONS } from "../../constants/firebase.constants"
import AddCardModal from "./AddCardModal"
import Loader from "components/Common/Loader"
import { getEvents } from "../../store/events/actions"

import * as API from "../../services/api.services"
import { Link } from "react-router-dom"
import PaymentCard from "react-payment-card-component"

function Payments() {
  const dispatch = useDispatch()
  const [addCardModal, setAddCardModal] = useState(false)
  const [userBalance, setUserBalance] = useState(0)
  const [loadingEvents, setLoadingEvents] = useState(false)
  const [attachPaymentMethods, setAttachPaymentMethods] = useState([])
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState("")
  const [error, setError] = useState("")
  const reports = [
    {
      title: "Withdrawable",
      description: userBalance,
      iconClass: "mdi-cash-100",
    },
    // {
    //   title: "Total Revenue",
    //   description: 0,
    //   iconClass: "mdi-cash-100",
    // },
    // {
    //   title: "Previous Transactions",
    //   description: 0,
    //   iconClass: "mdi-cash-100",
    // },
  ]

  const handleWithdraw = async () => {
    try {
      setLoading(true)
      let localUser = JSON.parse(localStorage.getItem("authUser"))
      const userId = localUser.id
      const user = await firebaseService.getDocumentById(
        COLLECTIONS.MANAGERS,
        userId
      )
      let connectId = user.connectAccountId
      if (!connectId) {
        connectId = await API.createConnectAccount(user.email)
        await firebaseService.updateDocument(COLLECTIONS.MANAGERS, userId, {
          connectAccountId: connectId,
        })
        localStorage.setItem(
          "authUser",
          JSON.stringify({ ...localUser, connectAccountId: connectId })
        )
      }

      const account = await API.getConnectAccount(connectId)
      const externelAccounts = account.external_accounts.data

      setLoading(false)
      if (!externelAccounts.length > 0) {
        setAddCardModal(true)
        return
      }
      const cardPayments = account.capabilities.card_payments
      const platformPayments = account.capabilities.platform_payments
      const pendingVerifications = account.requirements.pending_verification
      if (cardPayments == "active" && platformPayments == "active") {
        if (user.currentBalance > 0) {
          setError("Error! Not enough balance available")
          setLoading(false)
          return
        }
        await API.createTransfer(connectId, user.currentBalance * 100)
        await API.createPayout(connectId, user.currentBalance * 100)
        await firebaseService.updateDocument(COLLECTIONS.MANAGERS, userId, {
          currentBalance: 0,
        })
        setSuccess(
          "Congratulations! Your payment is transfers to your provided account"
        )
      } else if (pendingVerifications?.length > 0) {
        setError("Your verifications are in process. Try again later!")
      } else {
        const generateLink = await API.generateLink(connectId)
        window.open(generateLink, "_blank")
      }
      setLoading(false)
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  const handleSubmitCard = async values => {
    setLoading(true)
    if (error) setError("")
    try {
      const { cardNumber, cvc, expiry, name } = values
      const expiryYear = expiry.getFullYear()
      const expiryMonth = expiry.getMonth()
      const card = {
        cardNumber,
        expiryMonth,
        expiryYear,
        cvc,
      }
      const cardToken = await API.createdCardToken(card)
      let localUser = JSON.parse(localStorage.getItem("authUser"))
      const connectId = localUser.connectAccountId
      if (!connectId) return setError("Sorry! Try again")
      const attachCardRes = await API.attachCard(connectId, cardToken)
      const connectAccount = attachCardRes.data?.account || {}
      const paymentMethods = connectAccount?.external_accounts?.data || []
      setAttachPaymentMethods(paymentMethods)
      setLoading(true)
      setAddCardModal(false)
      handleWithdraw()
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("authUser"))
    const userId = localUser.id
    const fetchEvents = async () => {
      setLoadingEvents(true)
      try {
        let events = await firebaseService.queryDocuments(
          COLLECTIONS.EVENTS,
          "createdBy",
          "==",
          userId
        )
        events = await Promise.all(
          events.map(async event => {
            const tickets = await firebaseService.queryDocuments(
              COLLECTIONS.TICKETS,
              "eventId",
              "==",
              event.id
            )
            return {
              ...event,
              tickets,
            }
          })
        )
        setEvents(events)

        const user = await firebaseService.getDocumentById(
          COLLECTIONS.MANAGERS,
          userId
        )
        setUserBalance(user?.currentBalance || 0)
        if (user?.connectAccountId) {
          const connectAccount = await API.getConnectAccount(
            user.connectAccountId
          )
          const paymentMethods = connectAccount?.external_accounts?.data || []
          setAttachPaymentMethods(paymentMethods)
        }
        setLoadingEvents(false)
      } catch (err) {
        setLoadingEvents(false)
      }
    }

    fetchEvents()
  }, [])

  return (
    <div>
      {loadingEvents && <Loader text={"Loading..."} />}
      {loading && <Loader text={"Please Wait..."} />}
      {success ? (
        <Alert color="success" isOpen={success} toggle={() => setSuccess("")}>
          {success}
        </Alert>
      ) : null}
      {error ? (
        <Alert color="danger" isOpen={error} toggle={() => setError("")}>
          {error}
        </Alert>
      ) : null}
      <AddCardModal
        showModal={addCardModal}
        setShowModal={setAddCardModal}
        onSubmit={handleSubmitCard}
      />
      <Row>
        <Col xl="12">
          <Row>
            {reports.map((report, key) => (
              <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-bold fs-6">
                          {report?.title}
                        </p>
                        <h2 className="mb-0">{report?.description}</h2>
                      </div>
                      <div className="avatar-sm rounded-circle bg-secondary align-self-center">
                        <span className="avatar-title rounded-circle border-0 ">
                          <i
                            className={
                              "mdi " +
                              report?.iconClass +
                              " text-white font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Button
        className="my-4"
        type="button"
        color="secondary"
        onClick={handleWithdraw}
      >
        Withdraw Cash
      </Button>
      {attachPaymentMethods.length > 0 ? (
        <Row>
          <Col md={12}>
            <div className="m-2">
              <Card>
                <CardBody>
                  <p className="h6">Connected Cards</p>
                  <Row>
                    {attachPaymentMethods?.map(pm => {
                      return (
                        <Col md={4}>
                          <PaymentCard
                            // bank="itau"
                            // model="personnalite"
                            // type="white"
                            brand={pm?.brand?.toLowerCase()}
                            number={`**** **** **** ${pm?.last4}`}
                            cvv="202"
                            holderName=""
                            expiration={`${pm?.exp_month}/${pm?.exp_year}`}
                            //flipped={true}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      ) : null}
      <div className="table-responsive">
        <h4 className="my-3">Events</h4>
        <div className="table-responsive">
          <Table className="align-middle table-bordered table-striped table-responsive mb-0">
            <thead className="bg-secondary text-white">
              <tr>
                <th>Date</th>
                <th>Title</th>
                <th>Status</th>
                <th>Sold Tickets</th>
                <th>Total of Sold Tickets</th>
                <th>Payments Transfer</th>
                <th>Action</th>
                {/* <th>Online Sold</th>
                <th>Collect Cash</th> */}
              </tr>
            </thead>
            <tbody>
              {events && events.length > 0
                ? events.map((event, index) => {
                    let title = event.title || ""
                    title =
                      title.length > 50 ? `${title.slice(0, 50)}...` : title
                    const date = event.eventDate
                    const eventStatus = event.status
                    const isPaymentClear = event.isPaymentClear
                    // tickets
                    const tickets = event.tickets || []
                    const soldTickets = tickets.length
                    let totalOfTickets = 0,
                      onlineSold = 0,
                      collectCash = 0
                    for (let i = 0; i < tickets.length; i++) {
                      const ticket = tickets[i]
                      totalOfTickets += ticket.amountPaid
                      if (ticket.isPaid) onlineSold += ticket.amountPaid
                      else collectCash += ticket.amountPaid
                    }

                    return (
                      <tr key={index}>
                        <td>{date}</td>
                        <td>{title}</td>
                        <td>
                          {eventStatus == "active" ? (
                            <Badge
                              className={`font-size-12 badge-soft-secondary`}
                              color="#fff"
                              pill
                            >
                              {eventStatus}
                            </Badge>
                          ) : eventStatus == "completed" ? (
                            <Badge
                              className={`font-size-12 badge-soft-success`}
                              color="#fff"
                              pill
                            >
                              {eventStatus}
                            </Badge>
                          ) : null}
                        </td>
                        <td>{soldTickets}</td>
                        <td>£ {totalOfTickets}</td>
                        <td>
                          {!isPaymentClear && eventStatus == "active" ? (
                            <Badge
                              className={`font-size-12 badge-soft-secondary`}
                              color="#fff"
                              pill
                            >
                              Pending
                            </Badge>
                          ) : isPaymentClear && eventStatus == "completed" ? (
                            <Badge
                              className={`font-size-12 badge-soft-success`}
                              color="#fff"
                              pill
                            >
                              Transfered
                            </Badge>
                          ) : (
                            <Badge
                              className={`font-size-12 badge-soft-danger`}
                              color="#fff"
                              pill
                            >
                              Failed
                            </Badge>
                          )}
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: `/payments/${event.id}/details`,
                            }}
                          >
                            <Button type="button" color="primary">
                              Details
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    )
                  })
                : null}
            </tbody>
          </Table>
        </div>
        {events.length == 0 && !loadingEvents && (
          <h6 className="mt-4 text-center">No Data Available Yet!</h6>
        )}
      </div>
    </div>
  )
}

export default Payments
