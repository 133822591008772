import { takeEvery, put, call } from "redux-saga/effects"

import {
  ADD_VENUE,
  DELETE_VENUE,
  GET_ALL_VENUES,
  GET_VENUES,
  GET_VENUE_DETAILS,
  UPDATE_VENUE,
  UPDATE_VENUE_STATUS,
  ACTIVE_SLOT,
  IN_ACTIVE_SLOT,
} from "./actionTypes"

import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore"
const collectionName = "venues"
const colRef = collection(db, collectionName)

import { v4 } from "uuid"
import {
  apiSuccess,
  apiError,
  onGetVenues,
  onGetAllVenues,
  onGetVenueDetails,
  onDeleteVenue,
  onUpdateVenueStatus,
} from "./actions"

import getAddressFromLngLat from "components/Common/getAddressFromLngLat"

function* addVenues({ payload: { data, history } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const uploadAttachments = yield Promise.all(
      data?.attachments?.map(attch => {
        return new Promise((resolve, reject) => {
          uploadBytes(ref(storage, `venues/${v4()}`), attch)
            .then(res => {
              getDownloadURL(res.ref)
                .then(res => {
                  resolve(res)
                })
                .catch(err => {
                  reject(err)
                })
            })
            .catch(err => {
              reject(err)
            })
        })
      })
    )
    const docRef = doc(colRef)
    yield call(setDoc, docRef, {
      ...data,
      attachments: uploadAttachments,
      id: docRef.id,
      createdBy: user.id,
      bookings: {},
    })
    history.push("/venues")
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getVenues() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const q = query(colRef, where("createdBy", "==", user.id))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())
    const resolveData = yield Promise.all(
      data.map(async d => {
        const { latitude, longitude } = d.location
        const address = await getAddressFromLngLat(latitude, longitude)
        d.address = address
        return d
      })
    )
    yield put(onGetVenues(resolveData))
  } catch (err) {
    yield put(apiError(err.message))
  }
}
// get all venues
function* getAllVenues() {
  try {
    const response = yield call(getDocs, colRef)
    const data = response.docs.map(doc => doc?.data())

    const resolveData = yield Promise.all(
      data.map(async d => {
        const { latitude, longitude } = d.location
        const address = await getAddressFromLngLat(latitude, longitude)
        d.address = address
        let startingFrom = Infinity
        d?.availability?.map(item => {
          item?.slots?.map(s => {
            if (s?.price < startingFrom) {
              startingFrom = s?.price
            }
          })
        })
        d.startingFrom = startingFrom
        if (!d?.availability?.length) {
          d.startingFrom = 0
        }
        return d
      })
    )
    yield put(onGetAllVenues(resolveData))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

// update venue
function* updateVenue({ payload: { data, history } }) {
  try {
    // update venue attachments
    const uploadAttachments = yield Promise.all(
      data?.attachments?.map(attch => {
        if (typeof attch == "object") {
          return new Promise((resolve, reject) => {
            uploadBytes(ref(storage, `venues/${v4()}`), attch)
              .then(res => {
                getDownloadURL(res.ref)
                  .then(res => {
                    resolve(res)
                  })
                  .catch(err => {
                    reject(err)
                  })
              })
              .catch(err => {
                reject(err)
              })
          })
        } else {
          return attch
        }
      })
    )

    //
    const docRef = doc(db, collectionName, data.id)

    yield call(updateDoc, docRef, {
      ...data,
      attachments: uploadAttachments,
    })

    yield put(apiSuccess("Updated Successfully"))
    history.push("/venues")
  } catch (error) {
    yield put(apiError(error.message))
  }
}

// update venue status

function* updateVenueStatus({ payload: { id, isOnline } }) {
  try {
    const docRef = doc(db, collectionName, id)

    yield call(updateDoc, docRef, {
      isOnline: isOnline,
    })
    yield put(onUpdateVenueStatus(id))
    yield put(apiSuccess("Updated Successfully"))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

// delete venue

function* deleteVenue({ payload: { deleteId, history } }) {
  try {
    const docRef = doc(db, collectionName, deleteId)

    yield call(deleteDoc, docRef, deleteId)
    yield put(onDeleteVenue(deleteId))
    yield put(apiSuccess("Deleted Successfully"))
    history.push("/venues")
  } catch (error) {
    yield put(apiError(error.message))
  }
}

// get venue details

function* getVenueDetails({ payload: id }) {
  try {
    const docRef = doc(db, collectionName, id)
    const docData = yield call(getDoc, docRef)
    const data = docData.data()
    const { latitude, longitude } = data.location
    const address = yield getAddressFromLngLat(latitude, longitude)
    data.address = address
    yield put(onGetVenueDetails(data))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

// active slot

function* activeSlot({ payload }) {
  try {
    let bookingDate = payload?.bookingDate
    let venueId = payload?.venueId
    let bookedSlot = payload?.bookedSlot
    /*   Add booking to Venue */
    const venueRef = doc(db, "venues", venueId)
    const venueData = (yield call(getDoc, venueRef))?.data()
    let bookings = venueData?.bookings || {}
    if (bookings[bookingDate]) {
      // if date is exists
      const arrSlots = bookings[bookingDate]
      bookings[bookingDate] = arrSlots.filter(slotId => slotId !== bookedSlot)
    }
    // update venue after removing booked slot
    yield call(updateDoc, venueRef, {
      bookings,
    })
    put(apiSuccess("Slot is active"))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

// in active slot

function* inActiveSlot({ payload }) {
  try {
    let bookingDate = payload?.bookingDate
    let venueId = payload?.venueId
    let bookedSlot = payload?.bookedSlot

    /*   Add booking to Venue */
    const venueRef = doc(db, "venues", venueId)
    const venueData = (yield call(getDoc, venueRef))?.data()

    let bookings = venueData?.bookings || {}
    if (bookings[bookingDate]) {
      // if date is exists
      const arrSlots = bookings[bookingDate]
      const isBooking = arrSlots.find(slotId => slotId == bookedSlot)
      if (isBooking) {
        throw new Error("Booking aleady exists for this slot")
      }
      bookings[bookingDate] = [...arrSlots, bookedSlot]
    } else {
      // if date do not exists
      bookings = {
        ...bookings,
        [bookingDate]: [bookedSlot.id],
      }
    }
    // update venue after adding new booked slot
    yield call(updateDoc, venueRef, {
      bookings,
    })

    put(apiSuccess("Slot is in-active"))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* root() {
  yield takeEvery(ADD_VENUE, addVenues)
  yield takeEvery(UPDATE_VENUE, updateVenue)
  yield takeEvery(UPDATE_VENUE_STATUS, updateVenueStatus)
  yield takeEvery(GET_VENUES, getVenues)
  yield takeEvery(GET_ALL_VENUES, getAllVenues)
  yield takeEvery(GET_VENUE_DETAILS, getVenueDetails)
  yield takeEvery(DELETE_VENUE, deleteVenue)
  yield takeEvery(ACTIVE_SLOT, activeSlot)
  yield takeEvery(IN_ACTIVE_SLOT, inActiveSlot)
}
export default root
