import React, { useEffect } from "react"

import { getAllManagers } from "../../../store/managers/actions"
import Loader from "components/Common/Loader"
import SectionsNoData from "../../Utility/SectionsNoData"
import EventManagerCard from "pages/OrganizerEvents/AddEvent/Hire/EventManager/EventManagerCard"

import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "reactstrap"
import { useHistory } from "react-router"

function EventManagersList() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loading, data } = useSelector(state => ({
    loading: state.Managers.loading,
    data: state.Managers.data || [],
  }))

  const onClickDetails = manager => {
    history.push(`/event-managers/${manager?.id}`, { manager })
  }

  useEffect(() => {
    dispatch(getAllManagers())
  }, [])

  return (
    <div>
      {loading && <Loader text={"Loading..."} />}
      <Row>
        {data.length > 0
          ? data.map((manager, index) => {
              return (
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  xl={3}
                  className="mb-4 mt-5"
                  key={index}
                >
                  <EventManagerCard
                    key={index}
                    {...manager}
                    onClickDetails={() => onClickDetails(manager)}
                    manager={manager}
                  />
                </Col>
              )
            })
          : null}
        {!loading && !data.length > 0 ? (
          <SectionsNoData text="No Event Manager Available" />
        ) : null}
      </Row>
    </div>
  )
}

export default EventManagersList
