import {
  collection,
  getDocs,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  where,
  query,
  Timestamp,
  deleteDoc,
} from "firebase/firestore"
import { db, auth } from "../config/firebaseConfig"
import {
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth"
import axios from "axios"

const firebaseService = {
  signIn: async (email, password) => {
    try {
      return await signInWithEmailAndPassword(auth, email, password)
    } catch (err) {
      let { message } = err
      if (
        message == "Firebase: Error (auth/user-not-found)." ||
        message == "Firebase: Error (auth/wrong-password)."
      ) {
        message = "Invalid Email or Password"
      } else {
        message = "Something went wrong. Try again!."
      }
      throw new Error(message)
    }
  },

  signUp: async (email, password) => {
    try {
      return await createUserWithEmailAndPassword(auth, email, password)
    } catch (err) {
      let { message } = err
      if (
        message == "Firebase: Error (auth/user-not-found)." ||
        message == "Firebase: Error (auth/wrong-password)."
      ) {
        message = "Invalid Email or Password"
      }
      if (message == "Firebase: Error (auth/email-already-in-use).") {
        message = "Email already exists."
      }
      throw new Error(message)
    }
  },
  logout: async () => {
    try {
      return await signOut(auth)
    } catch (err) {
      throw new Error(err.message)
    }
  },

  getDocumentById: async (collectionName, id) => {
    const response = await getDoc(doc(db, collectionName, id))
    return response.data()
  },
  deleteDocumentById: async (collectionName, id) => {
    try {
      return await deleteDoc(doc(db, collectionName, id))
    } catch (err) {}
  },
  getDocuments: async collectionName => {
    try {
      const response = await getDocs(collection(db, collectionName))
      const data = response.docs.map(doc => doc.data())
      return data
    } catch (err) {
      throw new Error(err.message)
    }
  },
  addDocument: async (collectionName, data) => {
    try {
      const colRef = collection(db, collectionName)
      const docRef = doc(colRef)
      const newData = { ...data, id: docRef.id, createdAt: Timestamp.now() }
      return await setDoc(docRef, newData)
    } catch (err) {
      console.log(err)
      throw new Error(err.message)
    }
  },
  addDocumentWithCustomId: async (collectionName, customId, data) => {
    try {
      return await setDoc(doc(db, collectionName, customId), data)
    } catch (err) {
      throw new Error(err.message)
    }
  },
  updateDocument: async (collectionName, id, data) => {
    try {
      return await updateDoc(doc(db, collectionName, id), data)
    } catch (err) {
      throw new Error(err.message)
    }
  },

  queryDocuments: async (collectionName, key, operator, value) => {
    try {
      const colRef = collection(db, collectionName)
      const q = query(colRef, where(key, operator, value))
      // execute query
      const response = await getDocs(q)
      const data = response.docs.map(doc => doc.data())
      return data
    } catch (err) {
      console.log(err)
      throw new Error(err.message)
    }
  },
  addDocumentWithNestedCollection: async (
    collectionName,
    subCollectionName,
    userID,
    eventData
  ) => {
    try {
      const calAppColRef = collection(
        db,
        collectionName,
        userID,
        subCollectionName
      )
      const calAppRef = doc(calAppColRef)
      return await setDoc(calAppRef, eventData)
    } catch (err) {
      console.log(err.message)
      throw new Error(err.message)
    }
  },
  getdocumentsWithNestedCollection: async (
    collectionName,
    userId,
    subCollectionName
  ) => {
    try {
      const response = await getDocs(
        collection(db, collectionName, userId, subCollectionName)
      )
      const data = response.docs.map(doc => doc.data())
      return data
    } catch (err) {
      throw new Error(err.message)
    }
  },
  generatePushNotification: async (
    fckToken,
    notificationTitle,
    notificationText
  ) => {
    try {
      const response = await axios.post(
        "https://fcm.googleapis.com/fcm/send",
        {
          notification: {
            title: notificationTitle,
            body: notificationText,
          },
          to: fckToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `key=${"AAAAUFWg864:APA91bGlNu0QhSBJ6-sXpf9PZFX33UbMvX7nZjUuBqCP-bv0KsposxLtrseTs9pZWYkwaURmdgHJUK5jEs0YrrBFCM8UuXKW_K29uI1RGY3gyzRYFIHxfbFCIRp3RJbBo-SCgm3z08yM"}`,
          },
        }
      )
      console.log(response)
      return response.data
    } catch (err) {
      console.log(err.message)
    }
  },
}

export default firebaseService
