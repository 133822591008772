import React, { useEffect, useState, lazy, Suspense } from "react"
import MetaTags from "react-meta-tags"

import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

const EventDetails = lazy(() => import("./EventDetails"))
const EventPackages = lazy(() => import("./EventPackages"))
const HireVenueOwnerMain = lazy(() =>
  import("./Hire/VenueOwner/HireVenueOwnerMain")
)
const HireEventManagerMain = lazy(() =>
  import("./Hire/EventManager/HireEventManagerMain")
)
import Loader from "components/Common/Loader"
import { addEvents } from "../../../store/events/actions"
import { useDispatch, useSelector } from "react-redux"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
// payment
const PaymentEventManager = lazy(() => import("./PaymentEventManager"))
const PayementVenueOwner = lazy(() => import("./PaymentVenueOwner"))

const AddEvent = React.memo(() => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [events, setEvents] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)

  const manager = JSON.parse(localStorage.getItem("authUser"))

  const { loading, error, orderLoading } = useSelector(state => ({
    loading: state.Events.loading,
    orderLoading: state.Orders.loading,
  }))

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function next() {
    toggleTab(activeTab + 1)
  }
  function prev() {
    toggleTab(activeTab - 1)
  }

  // function to set events data
  function handleSetEventsData(data) {
    setEvents(data)
  }

  // to get and set sections data (event details, event packages page)
  function handleSubmit(data) {
    if (activeTab === 1) {
      const eventsData = data.map((d, index) => ({
        ...(events[index] || {}),
        ...d,
      }))
      setEvents(eventsData)
    }

    if (activeTab === 2) {
      const packages = data
      setEvents(prev => prev.map(event => ({ ...event, packages })))
    }

    if (activeTab === 3) {
      setEvents(data)
    }
    if (activeTab !== 4) return next()

    setShowConfirm(true)
  }

  // add event after confirming create event
  function handleSubmitEvent() {
    dispatch(addEvents({ data: events, history }))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Add Event</title>
      </MetaTags>
      {loading && <Loader text="Please Wait..." />}
      {orderLoading && <Loader text="Creating Order..." />}
      {showConfirm && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Submit"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setShowConfirm(false)
            handleSubmitEvent()
          }}
          onCancel={() => setShowConfirm(false)}
        >
          You won't be able to change this!
        </SweetAlert>
      )}

      <Row>
        <Col>
          <Card>
            <CardBody>
              <h4 className="mb-4">Add Event</h4>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({
                          current: activeTab === 1,
                        })}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number fs-4 fw-bold">1</span> Event
                        Details
                      </NavLink>
                    </NavItem>

                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({
                          active: activeTab === 2,
                        })}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number fs-4 fw-bold">2</span>Packages
                        Pricing
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({
                          active: activeTab === 3,
                        })}
                        disabled={!(passedSteps || []).includes(3)}
                      >
                        <span className="number fs-4 fw-bold">3</span>{" "}
                        {manager.role === "eventManager"
                          ? "Book Venue"
                          : "Book event manager"}
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 4 })}
                    >
                      <NavLink
                        className={classnames({
                          active: activeTab === 4,
                        })}
                        disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number fs-4 fw-bold">4</span>
                        Payment
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix mt-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <EventDetails
                          handleNext={next}
                          handlePrev={prev}
                          activeTab={activeTab}
                          handleSubmit={handleSubmit}
                          data={events}
                          handleSetEventsData={handleSetEventsData}
                        />
                      </Suspense>
                    </TabPane>
                    <TabPane tabId={2}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <EventPackages
                          handleNext={next}
                          handlePrev={prev}
                          activeTab={activeTab}
                          handleSubmit={handleSubmit}
                          requiredSeats={events?.[0]?.requiredSeats || 0}
                          packages={events?.[0]?.packages || []}
                          data={events}
                        />
                      </Suspense>
                    </TabPane>
                    <TabPane tabId={3}>
                      <Suspense fallback={<div>Loading...</div>}>
                        {activeTab === 3 && manager.role === "eventManager" ? (
                          <HireVenueOwnerMain
                            handlePrev={prev}
                            activeTab={activeTab}
                            handleNext={next}
                            handleSubmit={handleSubmit}
                            handleSetEventsData={handleSetEventsData}
                            events={events}
                          />
                        ) : activeTab === 3 && manager.role === "venueOwner" ? (
                          <HireEventManagerMain
                            handlePrev={prev}
                            activeTab={activeTab}
                            handleNext={next}
                            handleSetEventsData={handleSetEventsData}
                            events={events}
                          />
                        ) : (
                          ""
                        )}
                      </Suspense>
                    </TabPane>
                    <TabPane tabId={4}>
                      <Suspense fallback={<div>Loading...</div>}>
                        {activeTab === 4 && manager.role === "eventManager" ? (
                          <PaymentEventManager
                            handlePrev={prev}
                            activeTab={activeTab}
                            handleNext={next}
                            data={events}
                            eventsData={events}
                          />
                        ) : activeTab === 4 && manager.role === "venueOwner" ? (
                          <PayementVenueOwner
                            handlePrev={prev}
                            activeTab={activeTab}
                            handleNext={next}
                            data={events}
                            eventsData={events}
                          />
                        ) : (
                          ""
                        )}
                      </Suspense>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
})

export default AddEvent
