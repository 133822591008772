export const GET_TICKETS = "GET_TICKETS"
export const ON_GET_TICKETS = "ON_GET_TICKETS"

export const UPDATE_TICKETS = "UPDATE_TICKETS"
export const ON_UPDATE_TICKETS = "ON_UPDATE_TICKETS"

export const TICKET_CHECKED_IN = "TICKET_CHECKED_IN"
export const ON_TICKET_CHECKED_IN = "ON_TICKED_CHECKED_IN"

export const API_SUCCESS = "API_SUCCESS"
export const API_ERROR = "API_ERROR"
