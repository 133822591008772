import {
  GET_TICKETS,
  ON_GET_TICKETS,
  UPDATE_TICKETS,
  ON_UPDATE_TICKETS,
  TICKET_CHECKED_IN,
  ON_TICKET_CHECKED_IN,
  API_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const getTickets = data => ({
  type: GET_TICKETS,
  payload: data,
})

export const onGetTickets = data => ({
  type: ON_GET_TICKETS,
  payload: data,
})

export const updateTickets = data => ({
  type: UPDATE_TICKETS,
  payload: data,
})

export const onUpdateTickets = data => ({
  type: ON_UPDATE_TICKETS,
  payload: data,
})

export const tickedCheckedIn = data => ({
  type: TICKET_CHECKED_IN,
  payload: data,
})

export const onTickedCheckedIn = data => ({
  type: ON_TICKET_CHECKED_IN,
  payload: data,
})

export const apiSuccess = success => ({
  type: API_SUCCESS,
  payload: success,
})

export const apiError = error => ({
  type: API_ERROR,
  payload: error,
})
