import {
  GET_TICKETS,
  ON_GET_TICKETS,
  UPDATE_TICKETS,
  ON_UPDATE_TICKETS,
  TICKET_CHECKED_IN,
  ON_TICKET_CHECKED_IN,
  API_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  btnLoading: false,
  loading: false,
  data: [],
  success: "",
  error: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TICKETS: {
      state = { ...state, loading: true }
      break
    }
    case ON_GET_TICKETS: {
      state = { ...state, loading: false, data: action.payload }
      break
    }

    case UPDATE_TICKETS: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_UPDATE_TICKETS: {
      state = {
        ...state,
        btnLoading: false,
        data: [state.data, action.payload],
      }
      break
    }

    case TICKET_CHECKED_IN: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_TICKET_CHECKED_IN: {
      state = {
        ...state,
        btnLoading: false,
      }
      break
    }

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        btnLoading: false,
      }
      break

    case API_SUCCESS: {
      state = {
        ...state,
        success: action.payload,
        loading: false,
        btnLoading: false,
      }
      break
    }
    default:
      state = { ...state }
  }
  return state
}

export default reducer
