// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "@firebase/firestore"
import { getStorage } from "firebase/storage"
import { getFunctions } from "firebase/functions"
import { getAuth } from "firebase/auth"
const firebaseConfig = {
  apiKey: "AIzaSyAhr_U9srJNX0bc_yfGYVsPklssl5nOII0",
  authDomain: "event-management-ca88e.firebaseapp.com",
  projectId: "event-management-ca88e",
  storageBucket: "event-management-ca88e.appspot.com",
  messagingSenderId: "345033995182",
  appId: "1:345033995182:web:419d231bfc43f77e9b8e3f",
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const functions = getFunctions(app)
