import React from "react"
import {
  CardBody,
  CardImg,
  CardText,
  Button,
  Badge,
  Card,
  Row,
  Col,
} from "reactstrap"

import Slider from "./Slider"

function VenueOwnerCard({
  attachments,
  venueName,
  isOnline,
  address,
  seats,
  description,
  startingFrom,
  ...props
}) {
  return (
    <Card
      onClick={() => props.onClickDetails(props.id)}
      style={{ cursor: "pointer" }}
    >
      <Slider images={attachments} />
      <div className="p-3">
        <div className="mb-2">
          <h1
            style={{
              fontSize: "20px",
              color: "black",
            }}
          >
            {venueName}{" "}
          </h1>
        </div>
        <div>
          <p
            style={{
              fontSize: "14px",
            }}
          >
            <i className="text-secondary mdi mdi-map-marker"></i>
            <span className="">{address}</span>
          </p>
        </div>

        <div className="d-flex flex-column flex-md-row">
          <div>
            {isOnline ? (
              <Badge
                className="me-3 fs-6 bg-success mb-2"
                style={{ maxWidth: "60px" }}
              >
                Online
              </Badge>
            ) : (
              <Badge
                className="me-3 fs-6 bg-danger mb-2"
                style={{ maxWidth: "60px" }}
              >
                Offline
              </Badge>
            )}
          </div>
          <div className="mb-2">
            <span className="fw-normal  ms-5 fs-6">
              Capacity :<span className="text-secondary"> {seats}</span>
            </span>
          </div>
        </div>
        <CardText className="desc" style={{ minHeight: "60px" }}>
          {description?.slice(0, 100)}
        </CardText>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <Button
              color="primary"
              className="btn btn-primary"
              onClick={() => props.onClickDetails(props.id)}
            >
              View Details
            </Button>
          </div>
          <div>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "0",
              }}
            >
              Starting At{" "}
              <span
                className="text-secondary fw-bold"
                style={{ fontSize: "16px" }}
              >
                &#163; {startingFrom}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default VenueOwnerCard
