import React from "react"
import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import EventManager from "../pages/Authentication/register/EventManager"
import VenueOwner from "../pages/Authentication/register/VenueOwner"
import SignUp from "../pages/Authentication/SignUp"
import EventOrganizer from "pages/Authentication/register/EventOrganizer"
// Dashboard
import Dashboard from "../pages/Dashboard/index"
import EventManagersList from "pages/Managers/EventManagers/EventManagersList"
// Event Organizer
import OrganizerAddEvent from "../pages/OrganizerEvents/AddEvent/index"
import EditOrganizerEvent from "..//pages/OrganizerEvents/EditEvent/EditEvent"
import OrganizerEvents from "../pages/OrganizerEvents/Events"
import EventOrganizerDetails from "../pages/OrganizerEvents/EventOrganizerDetails"

// Venues
import Venues from "../pages/venues/Venues"
import AddVenue from "pages/venues/addVenue/AddVenue"
import EditVenue from "pages/venues/editVenue/EditVenue"
import VenueDetails from "pages/venues/VenueDetails"
import VenueOwnersList from "pages/Managers/VenueOwners/VenueOwnersList"
import VenueOwnersDetails from "pages/Managers/VenueOwners/VenueOwnersDetails"
// Events
import Events from "../pages/Events/Events"
import AddEvent from "../pages/Events/AddEvent"
import EditEvent from "../pages/Events/EditEvent/EditEvent"
import EventDetails from "pages/Events/EventDetails"
//Availability
import AvailabilityMain from "../pages/Availability/AvailabilityMain"
import AddAvailability from "pages/Availability/AddAvailability"
// Orders and order details
import Orders from "pages/Orders/Orders"
import OrderDetails from "pages/Orders/OrderDetails"
// 404 page
import Pages404 from "pages/Utility/Error404"
import EventManagerDetails from "pages/Managers/EventManagers/EventManagerDetails"

import Announcements from "pages/Announcements"
import Payments from "pages/Payments"
import PaymentsDetail from "pages/Payments/PaymentsDetail"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // // // //profile
  { path: "/profile", component: UserProfile },

  // Venues routes
  { path: "/venues", component: Venues },
  { path: "/add-venue", component: AddVenue },
  { path: "/edit-venue/:id", component: EditVenue },
  { path: "/venue-details/:id", component: VenueDetails },

  // Events routes
  { path: "/events-list", component: Events },
  { path: "/new-event", component: AddEvent },
  { path: "/edit-event/:id", component: EditEvent },
  { path: "/event-details/:id", component: EventDetails },

  /* Event Organizer */

  { path: "/organizer-events", component: OrganizerEvents },
  { path: "/organizer-new-event", component: OrganizerAddEvent },
  { path: "/organizer-edit-event/:id", component: EditOrganizerEvent },
  { path: "/organizer-event-details/:id", component: EventOrganizerDetails },

  /* Event Managers */

  { path: "/event-managers", component: EventManagersList },

  { path: "/event-managers/:id", component: EventManagerDetails },

  // Availability Routes
  { path: "/availability", component: AvailabilityMain },
  { path: "/add-availability", component: AddAvailability },
  { path: "/venue-owners", component: VenueOwnersList },
  { path: "/venue-owners/:id", component: VenueOwnersDetails },

  { path: "/announcements", component: Announcements },
  { path: "/payments", component: Payments },
  { path: "/payments/:eventId/details", component: PaymentsDetail },

  // Order and Order Details routes
  { path: "/orders", component: Orders },
  {
    path: "/order-details",
    component: OrderDetails,
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  // this route should be at the end of all other routes
  { path: "*", exact: true, component: Pages404 },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/Signup", component: SignUp },
  { path: "/register-event-manager", component: EventManager },
  { path: "/register-venue-owner", component: VenueOwner },
  { path: "/register-event-organizer", component: EventOrganizer },
]

export { userRoutes, authRoutes }
