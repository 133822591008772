import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useSelector } from "react-redux"
// action
import { userForgetPassword } from "../../../store/actions"

const ForgetPasswordPage = props => {
  function handleValidSubmit(event, values) {
    props.userForgetPassword(values, props.history)
  }

  const { loading } = useSelector(state => ({
    loading: state.ForgetPassword.loading,
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">
                      Reset Password
                    </h4>
                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : (
                      <div className="alert alert-info" role="alert">
                        Enter your Email and instructions will be sent to you!
                      </div>
                    )}

                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          {!loading ? (
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Send
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              disabled
                            >
                              Sending...
                            </button>
                          )}
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
