import React, { useEffect } from "react"
import { getOrders } from "../../store/orders/actions"
import { useDispatch, useSelector } from "react-redux"
import Loader from "components/Common/Loader"

// order List
import OrderList from "./OrderList"

const Orders = () => {
  const dispatch = useDispatch()

  const { orders, loading } = useSelector(state => ({
    orders: state.Orders.orders,
    loading: state.Orders.loading,
  }))

  useEffect(() => {
    dispatch(getOrders())
  }, [])

  return <div>{!loading ? <OrderList orders={orders} /> : <Loader />}</div>
}

export default Orders
