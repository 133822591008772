import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import Loader from "components/Common/Loader"
import { getVenues } from "../../store/venues/actions"
import { useDispatch, useSelector } from "react-redux"
import VenueList from "./VenueList"
import NoData from "pages/Utility/NoData"

const Venues = () => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => ({
    data: state.Venues.data,
    loading: state.Venues.loading,
  }))

  useEffect(() => {
    dispatch(getVenues())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Venues</title>
      </MetaTags>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <div className="justify-content-center">
          {data?.length > 0 ? (
            data.map((venue, index) => <VenueList venue={venue} key={index} />)
          ) : (
            <NoData />
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default Venues
