import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import managersSaga from "./managers/saga"
import eventsSaga from "./events/saga"
import venuesSaga from "./venues/saga"
import ordersSaga from "./orders/saga"
import ticketsSaga from "./tickets/saga"
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    ticketsSaga(),
    fork(calendarSaga),
    fork(managersSaga),
    fork(eventsSaga),
    fork(venuesSaga),
    fork(ordersSaga),
  ])
}
