import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  EDIT_PROFILE,
  UPDATE_NEW_PASSWORD,
  GET_LATEST_PROFILE,
} from "./actionTypes"
import { profileSuccess, profileError } from "./actions"
import { storage, db } from "../../../config/firebaseConfig"
import { logoutUser } from "../login/actions"

import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore"
import { uploadImg } from "helpers/uploadFiles"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import getAddressFromLngLat from "components/Common/getAddressFromLngLat"
import { v4 } from "uuid"

const collectionName = "managers"

const colRef = collection(db, collectionName)

function* getLatestProfile({ payload: { history } }) {
  try {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const docRef = doc(db, collectionName, user.id)
    const res = yield call(getDoc, docRef)
    const data = res.data()
    if (!data || data.status == "suspended") {
      return yield put(logoutUser(history))
    }
    localStorage.setItem("authUser", JSON.stringify(data))
    return data
  } catch (err) {}
}

function* editProfile({ payload: { user, history } }) {
  const userId = JSON.parse(localStorage.getItem("authUser"))
  try {
    const docRef = doc(db, collectionName, userId.id)
    var data = {
      ...user.user,
    }

    if (typeof data?.img == "object") {
      const uploadImage = yield uploadImg(user.user.img, "profileImage")
      data.img = uploadImage
    }

    if (data?.prevAttachments) {
      // update venue attachments
      const uploadAttachments = yield Promise.all(
        data?.prevAttachments?.map(attch => {
          if (typeof attch == "object") {
            return new Promise((resolve, reject) => {
              uploadBytes(ref(storage, `venues/${v4()}`), attch)
                .then(res => {
                  getDownloadURL(res.ref)
                    .then(res => {
                      resolve(res)
                    })
                    .catch(err => {
                      reject(err)
                    })
                })
                .catch(err => {
                  reject(err)
                })
            })
          } else {
            return attch
          }
        })
      )
      if (uploadAttachments) {
        data.prevAttachments = uploadAttachments
      }
      //
    }
    yield call(updateDoc, docRef, data)
    const managerRes = yield call(getDoc, docRef)
    const managerData = managerRes.data()
    if (managerData.role == "eventManager") {
      const { latitude, longitude } = managerData.location
      const address = yield getAddressFromLngLat(latitude, longitude)
      managerData.address = address
    }
    localStorage.setItem("authUser", JSON.stringify(managerData))
    yield put(profileSuccess("Profile Update Successfully"))
  } catch (error) {
    yield put(profileError("Something went wrong"))
  }
}

function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeLatest(GET_LATEST_PROFILE, getLatestProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
