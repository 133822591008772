import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormFeedback,
  Container,
  Input,
  Spinner,
} from "reactstrap"
import category from "common/category"
// Formik import * as Yup from "yup"
import { Formik } from "formik"
import DropAttachments from "components/Common/DropAttachments"
import Error from "components/Common/Error"
import * as Yup from "yup"
import Select from "react-select"
import { useHistory } from "react-router-dom"
// import images
import logoImg from "../../../assets/images/Default.png"
// actions
import {
  editProfile,
  resetProfileFlag,
} from "../../../store/auth/profile/actions"
import { useDispatch, useSelector } from "react-redux"
// goole auto place
import GooglePlacesAutoComplete from "../GoogleMap/GooglePlacesAutoComplete"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
// Alert
import { UncontrolledAlert } from "reactstrap"
//
import Loader from "components/Common/Loader"
// css
import "./Profile.css"

const validationSchema = Yup.object({
  number: Yup.string().required("Number is required"),
  name: Yup.string().required("Name is required"),
  bio: Yup.string().required("Bio/Description is required"),
  category: Yup.object().required("Category is required"),
  location: Yup.object().required("Location is required"),
})

const EventManagerProfile = props => {
  const [isFits, setisFits] = useState(false)
  const [index, setIndex] = useState("")
  const history = useHistory()
  const dispatch = useDispatch()
  const [newImage, setNewImage] = useState(null)
  const { loading, success, error } = useSelector(state => ({
    loading: state.Profile.loading,
    success: state.Profile.success,
    error: state.Profile.error,
  }))

  var user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      user = JSON.parse(localStorage.getItem("authUser"))
      setTimeout(() => {
        dispatch(resetProfileFlag())
      }, 3000)
    }
  }, [success, error])

  const onImageChange = event => {
    if (event.target.files[0]) {
      setNewImage(URL.createObjectURL(event.target.files[0]))
    }
  }

  return (
    <>
      {isFits ? (
        <Lightbox
          mainSrc={user?.prevAttachments[index]}
          enableZoom={false}
          onCloseRequest={() => {
            setisFits(!isFits)
          }}
        />
      ) : null}
      {!loading ? (
        <div>
          <Formik
            initialValues={{
              img: user?.img || {},
              name: user?.name,
              number: user?.number,
              bio: user?.bio,
              years: user?.experience?.years,
              months: user?.experience?.months,
              category: { label: user?.category } || "",
              location: user?.location,
              prevAttachments: user?.prevAttachments,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
              const user = {
                img: values.img,
                name: values.name,
                number: values.number,
                location: values.location,
                bio: values.bio,
                prevAttachments: values.prevAttachments,
                category: values.category.label,
                experience: { years: values.years, months: values.months },
              }
              dispatch(editProfile({ user: user, history }))
            }}
          >
            {({
              errors,
              setFieldValue,
              values,
              handleBlur,
              handleChange,
              touched,
              handleSubmit,
            }) => {
              function onChangeLocation(loc) {
                setFieldValue("location", loc)
              }
              function removeImage(i) {
                setFieldValue(
                  "prevAttachments",
                  values.prevAttachments.filter((f, index) => index !== i)
                )
              }
              function handleSelectedFiles(files) {
                setFieldValue("prevAttachments", [
                  ...values.prevAttachments,
                  ...files,
                ])
              }
              return (
                <>
                  <div
                    className=" pt-sm-5 pb-5"
                    style={{ width: "100%", backgroundColor: "#ffffff" }}
                  >
                    <Container>
                      <Row className="justify-content-center">
                        <Col sm={12} md={12} lg={10} xl={8}>
                          <Card>
                            <Row>
                              <Col sm={12} md={5} xl={4}>
                                <div
                                  className="bg py-4 d-flex flex-column align-items-center"
                                  style={{ height: "100%", color: "#fff" }}
                                >
                                  <div className="d-flex flex-column mb-4">
                                    {newImage ? (
                                      <img
                                        className="rounded-circle"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                        src={newImage}
                                        alt="Header Avatar"
                                      />
                                    ) : (
                                      <img
                                        className="rounded-circle"
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                        src={user?.img ? user?.img : logoImg}
                                        alt="Header Avatar"
                                      />
                                    )}
                                    <div>
                                      <Label className="form-label" for="img">
                                        <i
                                          className="mdi mdi-camera h5"
                                          style={{
                                            cursor: "pointer",
                                            color: "#fff",
                                          }}
                                        ></i>
                                      </Label>
                                      <Input
                                        id="img"
                                        name="img"
                                        className="form-control"
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        style={{ display: "none" }}
                                        onChange={e => {
                                          setFieldValue(
                                            "img",
                                            e.target.files[0]
                                          )
                                          onImageChange(e)
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <h5>{user.name}</h5>
                                  <h4>Event Manager</h4>
                                </div>
                              </Col>
                              <Col sm={12} md={7} xl={8}>
                                <div className="py-3 ps-md-0 ps-3 pe-3 pe-lg-5">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="fw-bold">Update Profile</h5>
                                  </div>
                                  <hr className="mt-0" />
                                  <div className="p-2">
                                    <div className="mb-3">
                                      <Label className="form-label">Name</Label>
                                      <Input
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                      />
                                      {touched.name && errors.name ? (
                                        <FormFeedback type="invalid">
                                          {errors.name}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Number
                                      </Label>
                                      <Input
                                        name="number"
                                        type="text"
                                        placeholder="Enter Phone Number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.number || ""}
                                      />
                                      {touched.number && errors.number ? (
                                        <FormFeedback type="invalid">
                                          {errors.number}
                                        </FormFeedback>
                                      ) : null}
                                    </div>

                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Bio/Description
                                      </Label>
                                      <Input
                                        name="bio"
                                        type="textarea"
                                        rows="8"
                                        placeholder="Enter bio / Description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bio || ""}
                                      />
                                      {touched.bio && errors.bio ? (
                                        <Error text={errors.bio} />
                                      ) : null}
                                    </div>

                                    <div className="mb-3">
                                      <Label for="basicpill-hours-input2">
                                        Experience
                                      </Label>
                                      <div>
                                        {" "}
                                        <small>(year's)</small>
                                      </div>
                                      <Input
                                        type="number"
                                        className="form-control mt-1"
                                        placeholder="Enter Year"
                                        name="years"
                                        min={0}
                                        onChange={handleChange}
                                        value={values.years}
                                        onBlur={handleBlur}
                                      />
                                    </div>

                                    <div className="mb-3">
                                      <small>(month's)</small>
                                      <Input
                                        type="number"
                                        className="form-control mt-1"
                                        placeholder="Enter Month"
                                        name="months"
                                        min={0}
                                        max={12}
                                        onChange={handleChange}
                                        value={values.months}
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label htmlFor="horizontal-day-Input">
                                        Category
                                      </Label>

                                      <Select
                                        options={category}
                                        getOptionLabel={option => (
                                          <div className="text-capitalize">
                                            {option.label}
                                          </div>
                                        )}
                                        getOptionValue={option => option}
                                        classNamePrefix="select2-selection"
                                        value={values.category}
                                        onChange={e => {
                                          setFieldValue("category", e)
                                        }}
                                      />
                                      {touched.category && errors.category ? (
                                        <Error text={errors.category} />
                                      ) : null}
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">
                                        Email
                                      </Label>
                                      <Input
                                        name="email"
                                        type="text"
                                        readOnly
                                        value={user.email}
                                      />
                                    </div>
                                    <div className="mb-4">
                                      <Label for="basicpill-address-input1">
                                        Address
                                      </Label>
                                      <GooglePlacesAutoComplete
                                        setLocation={onChangeLocation}
                                        preAddress={user.address}
                                      />
                                    </div>
                                    <div>
                                      {values?.prevAttachments?.length < 5 ? (
                                        <DropAttachments
                                          handleDropFiles={handleSelectedFiles}
                                        />
                                      ) : null}
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {values.prevAttachments.map(
                                          (f, index) => {
                                            return (
                                              <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete m-3"
                                                key={index + "-file"}
                                              >
                                                <div className="p-2">
                                                  <div className="d-flex justify-content-between align-items-center">
                                                    <Row className="align-items-center">
                                                      <Col className="col-auto">
                                                        <img
                                                          data-dz-thumbnail=""
                                                          height="80"
                                                          className="avatar-sm rounded bg-light"
                                                          alt="attachments"
                                                          src={f?.preview || f}
                                                          onClick={() => {
                                                            setisFits(true)
                                                            setIndex(index)
                                                          }}
                                                        />
                                                      </Col>
                                                      <Col
                                                        style={{
                                                          wordBreak:
                                                            "break-word",
                                                        }}
                                                      >
                                                        <div
                                                          to="#"
                                                          className="text-muted font-weight-bold"
                                                        >
                                                          {f.name}
                                                        </div>
                                                        <p className="mb-0">
                                                          <strong>
                                                            Attachments
                                                          </strong>
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                    <i
                                                      className="mdi mdi-delete text-danger h3 mx-4"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        removeImage(index)
                                                      }
                                                    ></i>
                                                  </div>
                                                </div>
                                              </Card>
                                            )
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="text-center mt-2 mb-3">
                                    {loading ? (
                                      <Button
                                        disabled
                                        className="w-100"
                                        color="primary"
                                      >
                                        Updating...
                                      </Button>
                                    ) : (
                                      <Button
                                        className="w-100"
                                        onClick={handleSubmit}
                                        color="primary"
                                      >
                                        Update
                                      </Button>
                                    )}
                                  </div>
                                  <div>
                                    {success && (
                                      <UncontrolledAlert color="success">
                                        {success}
                                      </UncontrolledAlert>
                                    )}
                                    {error && (
                                      <UncontrolledAlert color="danger">
                                        {error}
                                      </UncontrolledAlert>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </>
              )
            }}
          </Formik>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default EventManagerProfile
