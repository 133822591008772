export const COLLECTIONS = {
  USERS: "users",
  MANAGERS: "managers",
  NOTIFICATIONS: "notifications",
  EVENTS: "events",
  TICKETS: "tickets",
  ORDERS: "orders",
  CONFIGURATIONS: "configurations",
  VENUES: "venues",
}
