import axios from "axios"

const call = axios.create({
  baseURL: "https://igp-payment.herokuapp.com/v1",
})

export const createConnectAccount = async email => {
  try {
    const response = await call.post("/connect/accounts", { email })
    const data = response.data
    return data.account.id
  } catch (err) {
    throw new Error(err.response.data.error)
  }
}

export const getConnectAccount = async accountId => {
  try {
    const response = await call.get(`/connect/accounts?account=${accountId}`)
    return response.data.account
  } catch (err) {
    throw new Error(err.response.data.error)
  }
}
export const createdCardToken = async card => {
  try {
    const response = await call.post(`/tokens/create_card`, card)
    return response.data.token.id
  } catch (err) {
    throw new Error(err.response.data.error)
  }
}
export const attachCard = async (accountId, token) => {
  try {
    const response = await call.post(`/connect/accounts/attach_card`, {
      external_account: token,
      account: accountId,
    })
    return response
  } catch (err) {
    throw new Error(err.response.data.error)
  }
}

export const generateLink = async accountId => {
  try {
    const response = await call.post(`/connect/account_links`, {
      account: accountId,
    })
    return response.data.accountLink.url
  } catch (err) {
    throw new Error(err.response.data.error)
  }
}

export const createTransfer = async (accountId, amount) => {
  try {
    const response = await call.post(`/transfers/create`, {
      account: accountId,
      amount: amount,
    })
    return response.data.transfer
  } catch (err) {
    throw new Error(err.response.data.error)
  }
}

export const createPayout = async (accountId, amount) => {
  try {
    const response = await call.post(`/payouts`, {
      account: accountId,
      amount: amount,
    })
    return response.data.payout
  } catch (err) {
    throw new Error(err.response.data.error)
  }
}
