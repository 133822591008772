import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import noData from "../../assets/images/NoData.png"

const NoData = ({ text }) => {
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{ height: "200px", width: "220px" }}
          src={noData}
          alt=""
          className="img-fluid"
        />
        <p className="fs-6 text-grey">{text}</p>
      </div>
    </React.Fragment>
  )
}
export default NoData
