import Geocode from "react-geocode"

Geocode.setApiKey("AIzaSyBL4JbKL4SotWhSAnoYflXy9fnHrmT52Lg")
Geocode.setLanguage("en")

const getAddressFromLngLat = (lat, long) => {
  const latlng = {
    lat,
    long,
  }
  return new Promise((resolve, reject) => {
    try {
      Geocode.fromLatLng(lat, long).then(
        response => {
          const address = response.results[0].formatted_address
          resolve(address)
        },
        error => {
          console.error(error)
        }
      )
    } catch (err) {
      console.log(err.message)
    }
  })
}

export default getAddressFromLngLat
