export const ADD_VENUE = "ADD_VENUE"
export const ON_ADD_VENUE = "ON_ADD_VENUE"

export const UPDATE_VENUE = "UPDATE_VENUE"
export const ON_UPDATE_VENUE = "ON_UPDATE_VENUE"

export const UPDATE_VENUE_STATUS = "UPDATE_VENUE_STATUS"
export const ON_UPDATE_VENUE_STATUS = "ON_UPDATE_VENUE_STATUS"

export const DELETE_VENUE = "DELETE_VENUE"
export const ON_DELETE_VENUE = "ON_DELETE_VENUE"

export const GET_VENUES = "GET_VENUES"
export const ON_GET_VENUES = "ON_GET_VENUES"

export const GET_ALL_VENUES = "GET_ALL_VENUES"
export const ON_GET_ALL_VENUES = "ON_GET_ALL_VENUES"

export const GET_VENUE_DETAILS = "GET_VENUE_DETAILS"
export const ON_GET_VENUE_DETAILS = "ON_GET_VENUE_DETAILS"

export const ACTIVE_SLOT = "ACTIVE_SLOT"
export const ON_ACTIVE_SLOT = "ON_ACTIVE_SLOT"

export const IN_ACTIVE_SLOT = "IN_ACTIVE_SLOT"
export const ON_IN_ACTIVE_SLOT = "ON_IN_ACTIVE_SLOT"

export const API_SUCCESS = "API_SUCCESS"
export const API_ERROR = "API_ERROR"
