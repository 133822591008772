import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Table, Col, Row, Spinner, Button, Label } from "reactstrap"

import { getAllVenues, getVenueDetails } from "../../../store/venues/actions"
import VenueOwnerCard from "pages/OrganizerEvents/AddEvent/Hire/VenueOwner/VenueOwnerCard"
import Loader from "components/Common/Loader"
import { useHistory } from "react-router"

function VenueOwnersList() {
  const dispatch = useDispatch()
  const history = useHistory()

  const { data, loading } = useSelector(state => ({
    loading: state.Venues.loading,
    data: state.Venues.data,
  }))

  const onClickVenueDetails = venue => {
    history.push(`/venue-owners/${venue.id}`, {
      venue,
    })
  }

  useEffect(() => {
    dispatch(getAllVenues())
  }, [])

  return (
    <div>
      {loading && <Loader text={"Loading..."} />}
      <Row>
        {data?.length > 0
          ? data.map((venue, index) => {
              return (
                <Col sm={12} md={6} lg={4} className="mb-5" key={index}>
                  <VenueOwnerCard
                    key={index}
                    {...venue}
                    onClickDetails={() => onClickVenueDetails(venue)}
                  />
                </Col>
              )
            })
          : null}
      </Row>
    </div>
  )
}

export default VenueOwnersList
