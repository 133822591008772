import React, { useState } from "react"

import MasterCard from "../../assets/images/mastercard.png"
import Visa from "../../assets/images/visa.png"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Modal,
} from "reactstrap"

import { Formik } from "formik"
import * as Yup from "yup"
import Error from "../../components/Common/Error"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Cardholder Name required"),
  cardNumber: Yup.string()
    .required("Card Number required")
    .max(17, "Card Number must be less then 17 digits"),
  cvc: Yup.number()
    .required("Card CVC required")
    .min(3, "3 digits number required"),
  expiry: Yup.date().required("Card expiry required"),
})

function AddCardModal({
  showModal,
  setShowModal,
  btnLoading,
  onSubmit,
  values,
}) {
  return (
    <Modal isOpen={showModal} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add Card</h5>
        <button
          type="button"
          onClick={() => {
            setShowModal(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col xl="12">
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                name: "",
                cardNumber: "",
                cvc: "",
                expiry: "",
              }}
              onSubmit={onSubmit}
            >
              {({
                handleChange,
                setFieldValue,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                values,
              }) => {
                return (
                  <>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Cardholder Name
                          </Label>
                          <Input
                            name="name"
                            placeholder=""
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                          {errors.name && touched.name ? (
                            <Error text={errors.name} />
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Card Number
                          </Label>
                          <Input
                            name="cardNumber"
                            placeholder=""
                            type="number"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cardNumber}
                          />
                          {errors.cardNumber && touched.cardNumber ? (
                            <Error text={errors.cardNumber} />
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">CVC</Label>
                          <Input
                            name="cvc"
                            placeholder=""
                            type="number"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cvc}
                            inputMode="numeric"
                          />
                          {errors.cvc && touched.cvc ? (
                            <Error text={errors.cvc} />
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Card Expiry
                          </Label>
                          <DatePicker
                            selected={values.expiry}
                            onChange={date => setFieldValue("expiry", date)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                          />
                          {errors.expiry && touched.expiry ? (
                            <Error text={errors.expiry} />
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div className="my-2">
                      {!btnLoading ? (
                        <Button
                          color="primary"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button color="primary" disabled>
                          Submiting...
                        </Button>
                      )}
                    </div>
                  </>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default AddCardModal
