import {
  ADD_AVAILABILITIES,
  ON_ADD_AVAILABILITIES,
  DELETE_AVAILABILITIES,
  ON_DELETE_AVAILABILITIES,
  GET_AVAILABILITIES,
  ON_GET_AVAILABILITIES,
  ADD_VENUE_DETAILS,
  ON_ADD_VENUE_DETAILS,
  GET_VENUE_DETAILS,
  ON_GET_VENUE_DETAILS,
  GET_MANAGERS,
  ON_GET_MANAGERS,
  GET_ALL_MANAGERS,
  ON_GET_ALL_MANAGERS,
  GET_MANAGER,
  ON_GET_MANAGER,
  API_ERROR,
  API_SUCCESS,
  GET_EVENT_MANAGERS,
  ON_GET_EVENT_MANAGERS,
} from "./actionTypes"

const initialState = {
  data: [],
  availabilities: [],
  managerDetails: {},
  loading: false,
  btnLoading: false,
  error: "",
  success: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_AVAILABILITIES:
      state = { ...state, btnLoading: true }
      break
    case ON_ADD_AVAILABILITIES:
      state = {
        ...state,
        availabilities: [...state.availabilities, action.payload],
        btnLoading: false,
      }
      break
    case GET_AVAILABILITIES:
      state = { ...state, loading: true }
      break
    case ON_GET_AVAILABILITIES:
      state = {
        ...state,
        loading: false,
        availabilities: action.payload,
      }
      break
    case DELETE_AVAILABILITIES:
      state = {
        ...state,
        loading: true,
      }
      break
    case ON_DELETE_AVAILABILITIES:
      state = {
        ...state,
        loading: false,
        availabilities: action.payload,
      }
      break
    case ADD_VENUE_DETAILS:
      state = { ...state, btnLoading: true }
      break

    case ON_ADD_VENUE_DETAILS:
      state = { ...state, venueDetails: action.payload, btnLoading: false }
      break

    case GET_VENUE_DETAILS:
      state = { ...state, loading: true }
      break

    case ON_GET_VENUE_DETAILS:
      state = { ...state, loading: false, venueDetails: action.payload }
      break

    case GET_MANAGERS:
      state = { ...state, loading: true, data: [] }
      break

    case ON_GET_MANAGERS:
      state = { ...state, loading: false, data: action.payload }
      break

    case GET_MANAGER:
      state = { ...state, loading: true, btnLoading: true }
      break

    case ON_GET_MANAGER:
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        managerDetails: action.payload,
      }
      break

    case GET_ALL_MANAGERS:
      state = { ...state, loading: true, data: [] }
      break

    case ON_GET_ALL_MANAGERS:
      state = { ...state, loading: false, data: action.payload }
      break

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        btnLoading: false,
      }
      break

    case API_SUCCESS: {
      state = {
        ...state,
        success: action.payload,
        loading: false,
        btnLoading: false,
      }
      break
    }
    case GET_EVENT_MANAGERS: {
      state = { ...state, data: [], loading: true, error: "" }
      break
    }
    case ON_GET_EVENT_MANAGERS: {
      state = { ...state, data: action.payload || [], loading: false }
    }
    default:
      state = { ...state }
  }
  return state
}

export default reducer
