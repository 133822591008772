import {
  ADD_VENUE,
  ON_ADD_VENUE,
  UPDATE_VENUE,
  ON_UPDATE_VENUE,
  UPDATE_VENUE_STATUS,
  ON_UPDATE_VENUE_STATUS,
  DELETE_VENUE,
  ON_DELETE_VENUE,
  GET_VENUES,
  ON_GET_VENUES,
  GET_ALL_VENUES,
  ON_GET_ALL_VENUES,
  API_ERROR,
  API_SUCCESS,
  GET_VENUE_DETAILS,
  ON_GET_VENUE_DETAILS,
  ACTIVE_SLOT,
  ON_ACTIVE_SLOT,
  IN_ACTIVE_SLOT,
  ON_IN_ACTIVE_SLOT,
} from "./actionTypes"

const initialState = {
  btnLoading: false,
  loading: false,
  data: [],
  venueDetails: "",
  success: "",
  error: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_VENUE: {
      state = { ...state, loading: true, btnLoading: true }
      break
    }
    case ON_ADD_VENUE: {
      state = {
        ...state,
        loading: false,
        btnLoading: false,
        data: [state.data, action.payload],
      }
      break
    }

    case UPDATE_VENUE: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_UPDATE_VENUE: {
      state = {
        ...state,
        btnLoading: false,
        data: [state.data, action.payload],
      }
      break
    }

    case ACTIVE_SLOT: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_ACTIVE_SLOT: {
      state = {
        ...state,
        btnLoading: false,
        data: [state.data, action.payload],
      }
      break
    }

    case IN_ACTIVE_SLOT: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_IN_ACTIVE_SLOT: {
      state = {
        ...state,
        btnLoading: false,
        data: [state.data, action.payload],
      }
      break
    }

    case UPDATE_VENUE_STATUS: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_UPDATE_VENUE_STATUS: {
      state = {
        ...state,
        btnLoading: false,
        data: state.data.map((venue, index) => {
          if (venue.id == action.payload) {
            return {
              ...venue,
              isOnline: !venue.isOnline,
            }
          }
          return venue
        }),
      }
      break
    }

    case DELETE_VENUE: {
      state = { ...state, btnLoading: true }
      break
    }
    case ON_DELETE_VENUE: {
      state = {
        ...state,
        btnLoading: false,
        data: state.data.filter(venue => venue.id !== action.payload),
      }
      break
    }

    case GET_VENUES: {
      state = { ...state, loading: true, data: [] }
      break
    }

    case ON_GET_VENUES: {
      state = { ...state, loading: false, data: action.payload }
      break
    }
    case GET_ALL_VENUES: {
      state = { ...state, loading: true, data: [] }
      break
    }
    case ON_GET_ALL_VENUES: {
      state = { ...state, loading: false, data: action.payload }
      break
    }

    case GET_VENUE_DETAILS: {
      state = { ...state, btnLoading: true, loading: true, venueDetails: "" }
      break
    }

    case ON_GET_VENUE_DETAILS: {
      state = {
        ...state,
        btnLoading: false,
        loading: false,
        venueDetails: action.payload,
      }
      break
    }

    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        btnLoading: false,
      }
      break

    case API_SUCCESS: {
      state = {
        ...state,
        success: action.payload,
        loading: false,
        btnLoading: false,
      }
      break
    }
    default:
      state = { ...state }
  }
  return state
}

export default reducer
