import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import Loader from "../../components/Common/Loader"
import { getEventsByEventOrganizer } from "../../store/events/actions"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "reactstrap"
import NoData from "pages/Utility/NoData"
import { getShowDate } from "common/AddDaysForEvents"
import EventOrganizerCard from "./EventOrganizerCard"

const Events = () => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => ({
    data: state.Events.data,
    loading: state.Events.loading,
  }))

  useEffect(() => {
    dispatch(getEventsByEventOrganizer())
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>My Events</title>
      </MetaTags>
      <Row>
        <Col sm={12}>
          {data?.length > 0 && !loading ? (
            data.map((event, index) => {
              return (
                <EventOrganizerCard
                  key={index}
                  id={event?.id}
                  title={event?.title}
                  attachments={event?.attachments}
                  status={event?.status}
                  requiredSeats={event?.requiredSeats}
                  requiredTime={event?.requiredTime}
                  description={event?.description}
                  startTime={event?.startTime}
                  packages={event?.packages}
                  eventDate={getShowDate(event.eventDate)}
                  eventLocation={event.eventLocation}
                  data={event}
                />
              )
            })
          ) : (
            <> {!loading ? <NoData /> : ""}</>
          )}
        </Col>
      </Row>
      {loading && <Loader />}
    </React.Fragment>
  )
}

export default Events
