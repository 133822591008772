import moment from "moment"

function placeholder0(num) {
  if (num < 10) {
    return "0" + num
  }
  return num
}

const dayName = new Date().toLocaleDateString("en-us", {
  weekday: "long",
})
const year = new Date().toLocaleDateString("en-us", {
  year: "numeric",
})
const month = new Date().toLocaleDateString("en-us", {
  month: "numeric",
})
const dayDate = new Date().toLocaleDateString("en-us", {
  day: "numeric",
})

export const dateYMD = `${dayName} ${year}/${placeholder0(
  month
)}/${placeholder0(dayDate)}`

//--------------------- get array dates --------------------//

Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf())
  dat.setDate(dat.getDate() + days)
  return dat
}

export function getDates(startDate, stopDate) {
  var dateArray = new Array()
  var currentDate = startDate
  while (currentDate <= stopDate) {
    dateArray.push(currentDate.toISOString().split("T")[0])
    currentDate = currentDate.addDays(1)
  }
  return dateArray
}

export function getTillDates(endDate) {
  const dates = getDates(new Date(), new Date(endDate))
  const datesObj = new Object()
  dates.forEach(date => {
    datesObj[date] = []
  })
  return datesObj
}

////////////////////////////////////////////////

export function datesDiffList(start, end) {
  const startDate = moment(start)
  const endDate = moment(end)
  const numDays = endDate.diff(startDate, "days")
  const dateList = []
  for (let i = 0; i <= numDays; i++) {
    const date = startDate.clone().add(i, "days")
    const formattedDate = date.format("YYYY/M/D")
    dateList.push(formattedDate)
  }
  return dateList
}

export const dateToDateStr = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const formattedMonth = month < 10 ? `0${month}` : month
  const formattedDay = day < 10 ? `0${day}` : day
  return `${year}/${formattedMonth}/${formattedDay}`
}

export const dateStrToDate = dateStr => {
  const dateParts = dateStr.split("/")
  const year = parseInt(dateParts[0])
  const month = parseInt(dateParts[1]) - 1
  const day = parseInt(dateParts[2])
  const date = new Date(year, month, day)
  return date
}
