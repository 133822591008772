export const ADD_EVENT = "ADD_EVENT"
export const ON_ADD_EVENT = "ON_ADD_EVENT"

export const ADD_EVENT_MANUAL_VENUE = "ADD_EVENT_MANUAL_VENUE"
export const ON_ADD_EVENT_MANUAL_VENUE = "ADD_EVENT_MANUAL_VENUE"

export const ADD_EVENT_BY_EVENT_MANAGER = "ADD_EVENT_BY_EVENT_MANAGER"
export const ON_ADD_EVENT_BY_EVENT_MANAGER = "ON_ADD_EVENT_BY_EVENT_MANAGER"

export const ADD_EVENT_BY_VENUE_OWNER = "ADD_EVENT_BY_VENUE_OWNER"
export const ON_ADD_EVENT_BY_VENUE_OWNER = "ON_ADD_EVENT_BY_VENUE_OWNER"

export const ADD_EVENT_BY_EVENT_ORGANIZER = "ADD_EVENT_BY_EVENT_ORGANIZER"
export const ON_ADD_EVENT_BY_EVENT_ORGANIZER = "ON_ADD_EVENT_BY_EVENT_ORGANIZER"

export const GET_EVENTS_BY_EVENT_ORGANIZER = "GET_EVENTS_BY_EVENT_ORGANIZER"
export const ON_GET_EVENTS_BY_EVENT_ORGANIZER =
  "ON_GET_EVENTS_BY_EVENT_ORGANIZER"

export const GET_EVENTS = "GET_EVENTS"
export const ON_GET_EVENTS = "ON_GET_EVENTS"

export const API_SUCCESS = "API_SUCCESS"
export const API_ERROR = "API_ERROR"

export const ADD_SLOT_BOOKING = "ADD_SLOT_BOOKING"

export const UPDATE_EVENT = "UPDATE_EVENT"
export const ON_UPDATE_EVENT = "ON_UPDATE_EVENT"

export const GET_EVENT_STATISTICS = "GET_EVENT_STATISTICS"
export const ON_GET_EVENT_STATISTICS = "ON_GET_EVENT_STATISTICS"

export const DELETE_EVENT = "DELETE_EVENT"
export const ON_DELETE_EVENT = "ON_DELETE_EVENT"
