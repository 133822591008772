import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
  const [email, setemail] = useState(false)
  const [venue, setVenue] = useState(false)
  const [dashboard, setDashboard] = useState(false)
  const [events, setEvents] = useState(false)
  const [Availabilities, setAvailabilities] = useState(false)
  const [Orders, setOrders] = useState(false)

  // get current manager role
  const manager = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="topnav">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              {manager.role == "eventManager" ? (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      <i className="ti-dashboard"></i>
                      {props.t("Dashboard")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/events-list"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setemail(!email)
                      }}
                    >
                      <i className="ti-menu-alt"></i>
                      {props.t("Events")}
                    </Link>
                    <div
                      className={classname("dropdown-menu dropdown-menu-left", {
                        show: email,
                      })}
                    >
                      <Link to="/events-list" className="dropdown-item">
                        {props.t("Event List")}
                      </Link>
                      <Link to="/new-event" className="dropdown-item">
                        {props.t("Add Event")}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/availability">
                      <i className="ti-timer"></i>
                      {props.t("Availability")}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/orders">
                      <i className="ti-receipt"></i>
                      {props.t("Orders")}
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      <i className="ti-dashboard"></i>
                      {props.t("Dashboard")}
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/events-list"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setemail(!email)
                      }}
                    >
                      <i className="ti-menu-alt"></i>
                      {props.t("Events")}
                    </Link>
                    <div
                      className={classname("dropdown-menu dropdown-menu-left", {
                        show: email,
                      })}
                    >
                      <Link to="/events-list" className="dropdown-item">
                        {props.t("Event List")}
                      </Link>
                      <Link to="/new-event" className="dropdown-item">
                        {props.t("Add Event")}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/venues"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setVenue(!venue)
                      }}
                    >
                      <i className="ti-home"></i>
                      {props.t("Venues")}
                    </Link>
                    <div
                      className={classname("dropdown-menu dropdown-menu-left", {
                        show: venue,
                      })}
                    >
                      <Link to="/venues" className="dropdown-item">
                        {props.t("Venues")}
                      </Link>
                      <Link to="/add-venue" className="dropdown-item">
                        {props.t("Add Venue")}
                      </Link>
                    </div>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/orders">
                      <i className="ti-receipt"></i>
                      {props.t("Orders")}
                    </Link>
                  </li>
                </ul>
              )}
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
