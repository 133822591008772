import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import BarChart from "./barchart"
// Pages Components
import Earning from "./earning"

const Orders = props => {
  const reports = [
    {
      title: "Active Orders",
      iconClass: "mdi-buffer",
      description: props?.ordersStatistics?.activeOrders,
    },
    {
      title: "Total Orders",
      iconClass: "mdi-movie-roll",
      description: props?.ordersStatistics?.totalOrders,
    },
    {
      title: "Revenue",
      iconClass: "mdi-cash-100",
      description: `$ ${props?.ordersStatistics?.revenue}`,
    },
  ]

  return (
    <React.Fragment>
      <h2 className="mt-5 mb-4">Orders</h2>
      <Row>
        <Col xl="12">
          <Row>
            {reports.map((report, key) => (
              <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-bold">{report?.title}</p>
                        <h2 className="mb-0">{report?.description}</h2>
                      </div>
                      <div className="avatar-sm rounded-circle bg-secondary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle border-0">
                          <i
                            className={
                              "mdi " +
                              report?.iconClass +
                              " text-white font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col lg={6} style={{ minHeight: "320px", maxHeight: "320px" }}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Orders</CardTitle>
              <BarChart />
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} style={{ minHeight: "320px", maxHeight: "320px" }}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Revenue</CardTitle>
              <Earning />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Orders
