import { takeEvery, put, call } from "redux-saga/effects"

import {
  ADD_EVENT,
  ADD_EVENT_MANUAL_VENUE,
  ADD_EVENT_BY_EVENT_MANAGER,
  ADD_EVENT_BY_VENUE_OWNER,
  ADD_EVENT_BY_EVENT_ORGANIZER,
  GET_EVENTS,
  UPDATE_EVENT,
  GET_EVENT_STATISTICS,
  GET_EVENTS_BY_EVENT_ORGANIZER,
  DELETE_EVENT,
} from "./actionTypes"
import {
  onAddEvent,
  apiSuccess,
  apiError,
  onGetEvents,
  onGetEventStatistics,
  onGetEventsByEventOrganizer,
  onDeleteEvent,
} from "./actions"

import {
  createOrderForVenueOwner,
  createOrderForEventManager,
} from "../orders/saga"

import { getManagerProfile, updateManagerProfile } from "../managers/saga"

import getAddressFromLngLat from "components/Common/getAddressFromLngLat"

import { storage, db } from "../../config/firebaseConfig"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
  collection,
  getDocs,
  deleteDoc,
  orderBy,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore"
import { v4 } from "uuid"
import { getShowDate } from "common/AddDaysForEvents"

import firebaseService from "services/firebaseService"
import { COLLECTIONS } from "../../constants/firebase.constants"

// collections refs
const collectionName = "events"
const colRef = collection(db, collectionName)
const venueCollection = "venues"
const venueColRef = collection(db, venueCollection)

const eventManagersCollection = "managers"
const eventManagersColRef = collection(db, eventManagersCollection)

function* addEvents({ payload: { data, history, dispatch } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    let { event, booking } = data
    const uploadAttachments = yield Promise.all(
      event?.attachments?.map(async attch => {
        try {
          const res = await uploadBytes(ref(storage, `events/${v4()}`), attch)
          return await getDownloadURL(res.ref)
        } catch (err) {
          throw new Error(err.message)
        }
      })
    )

    const docRef = doc(colRef)
    yield call(setDoc, docRef, {
      ...event,
      attachments: uploadAttachments,
      id: docRef.id,
      createdBy: user.id,
      status: "inProgress",
    })
    yield put(apiSuccess("Event Created Successfully"))

    if (event.isManual) {
      let venue = event.venue
      const { latitude, longitude } = event.venue.location
      const address = yield getAddressFromLngLat(latitude, longitude)
      venue = {
        ...venue,
        address: address,
      }
      // Active Event
      yield call(updateDoc, docRef, {
        status: "active",
        venue: venue,
        eventLocation: address,
      })
      return history.push("/events-list")
    }
  } catch (err) {
    yield put(apiError(err.message))
  }
}
// ADD EVENET MANUAL VENUE
function* addEventManualVenue({ payload: { events, history } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const uploadAttachments = yield Promise.all(
      events[0]?.attachments?.map(async attch => {
        try {
          const res = await uploadBytes(ref(storage, `events/${v4()}`), attch)
          return await getDownloadURL(res.ref)
        } catch (err) {
          throw new Error(err.message)
        }
      })
    )
    for (const event of events) {
      const docRef = doc(colRef)
      let venue = event.venue
      const { latitude, longitude } = event.venue.location
      const address = yield getAddressFromLngLat(latitude, longitude)
      venue = {
        ...venue,
        address: address,
      }
      let isFeaturedTaxApply = false
      const isFeatured = event.featured
      if (isFeatured) {
        const getUser = yield call(getManagerProfile, user.id)
        const featuredCount = getUser?.config?.noOfFeaturedEventsAllowed || 0
        if (featuredCount > 0) {
          isFeaturedTaxApply = false
          yield call(updateManagerProfile, user.id, {
            config: {
              ...(getUser.config || {}),
              noOfFeaturedEventsAllowed: featuredCount - 1,
            },
          })
        } else {
          isFeaturedTaxApply = true
        }
      }
      const uploadEvent = {
        ...event,
        isFeaturedTaxApply,
        eventDate: getShowDate(event.eventDate),
        eventType: event?.eventType?.label,
        date: getShowDate(event.eventDate),
        eventLevel: event?.eventLevel?.label,
        requiredSeats: parseInt(event.requiredSeats),
        requiredTime:
          event?.requiredTime.hour + " hr " + event?.requiredTime.min + " min",
        attachments: uploadAttachments,
        managersId: [user.id],
        venue,
        isManual: true,
        id: docRef.id,
        createdBy: user.id,
        status: "active",
        venue: venue,
        eventLocation: address,
        createdAt: new Date(),
      }

      yield call(setDoc, docRef, uploadEvent)
    }
    history.push("/events-list")
  } catch (err) {
    yield put(apiError(err.message))
  }
}
// ADD EVENT BY EVENT MANAGER
function* addEventByEventManager({ payload: { events, history } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const uploadAttachments = yield Promise.all(
      events[0]?.attachments?.map(async attch => {
        try {
          const res = await uploadBytes(ref(storage, `events/${v4()}`), attch)
          return await getDownloadURL(res.ref)
        } catch (err) {
          throw new Error(err.message)
        }
      })
    )
    for (const event of events) {
      const docRef = doc(colRef)
      let isFeaturedTaxApply = false
      const isFeatured = event.featured
      if (isFeatured) {
        const getUser = yield call(getManagerProfile, user.id)
        const featuredCount = getUser?.config?.noOfFeaturedEventsAllowed || 0
        if (featuredCount > 0) {
          isFeaturedTaxApply = false
          yield call(updateManagerProfile, user.id, {
            config: {
              ...(getUser.config || {}),
              noOfFeaturedEventsAllowed: featuredCount - 1,
            },
          })
        } else {
          isFeaturedTaxApply = true
        }
      }
      const uploadEvent = {
        ...event,
        isFeaturedTaxApply,
        eventDate: getShowDate(event.eventDate),
        date: getShowDate(event.eventDate),
        eventType: event?.eventType?.label,
        requiredSeats: parseInt(event.requiredSeats),
        date: getShowDate(event.eventDate),
        eventLevel: event?.eventLevel?.label,
        requiredTime:
          event?.requiredTime.hour + " hr " + event?.requiredTime.min + " min",
        attachments: uploadAttachments,
        eventLocation: event.venue.address,
        managersId: [user.id],
        venue: {
          id: event?.venue?.id,
          bookedSlots: event?.venue?.bookedSlots,
        },
        isManual: false,
        id: docRef.id,
        createdBy: user.id,
        status: "inProgress",
        createdAt: new Date(),
      }
      yield call(setDoc, docRef, uploadEvent)
      const eventId = docRef.id
      const eventDate = getShowDate(event.eventDate)
      //create order for venue owner
      const venue = event.venue
      const venueBookedSlots = venue?.bookedSlots || []
      for (const slot of venueBookedSlots) {
        const data = {
          venue: venue.id,
          bookingDate: eventDate,
          slot: slot,
          event: eventId,
        }
        yield call(createOrderForVenueOwner, { data })
      }
    }
    history.push("/events-list")
  } catch (err) {
    yield put(apiError(err.message))
  }
}
// ADD EVENT BY VENUE OWNER
function* addEventByVenueOwner({ payload: { events, history } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const uploadAttachments = yield Promise.all(
      events[0]?.attachments?.map(async attch => {
        try {
          const res = await uploadBytes(ref(storage, `events/${v4()}`), attch)
          return await getDownloadURL(res.ref)
        } catch (err) {
          throw new Error(err.message)
        }
      })
    )
    for (const event of events) {
      const docRef = doc(colRef)
      let isFeaturedTaxApply = false
      const isFeatured = event.featured
      if (isFeatured) {
        const getUser = yield call(getManagerProfile, user.id)
        const featuredCount = getUser?.config?.noOfFeaturedEventsAllowed || 0
        if (featuredCount > 0) {
          isFeaturedTaxApply = false
          yield call(updateManagerProfile, user.id, {
            config: {
              ...(getUser.config || {}),
              noOfFeaturedEventsAllowed: featuredCount - 1,
            },
          })
        } else {
          isFeaturedTaxApply = true
        }
      }
      const uploadEvent = {
        ...event,
        isFeaturedTaxApply,
        eventDate: getShowDate(event.eventDate),
        date: getShowDate(event.eventDate),
        eventLevel: event?.eventLevel?.label,
        eventType: event?.eventType?.label,
        requiredSeats: parseInt(event.requiredSeats),
        requiredTime:
          event?.requiredTime.hour + " hr " + event?.requiredTime.min + " min",
        attachments: uploadAttachments,
        eventLocation: event.venue.address,
        managersId: [user.id],
        venue: {
          id: event?.venue?.id,
        },
        eventManagers: event?.eventManagers?.map(manager => {
          return {
            id: manager?.id,
            bookedSlots: manager?.bookedSlots,
          }
        }),
        isManual: false,
        id: docRef.id,
        createdBy: user.id,
        status: "inProgress",
        createdAt: new Date(),
      }
      yield call(setDoc, docRef, uploadEvent)
      const eventId = docRef.id
      const eventDate = getShowDate(event.eventDate)
      // create order for event manager
      const eventManagers = event.eventManagers
      for (const manager of eventManagers) {
        const managerId = manager.id
        const bookedSlots = manager.bookedSlots || []
        for (const slot of bookedSlots) {
          const data = {
            event: eventId,
            bookingDate: eventDate,
            slot,
            createdTo: managerId,
          }
          yield call(createOrderForEventManager, { data })
        }
      }
    }
    history.push("/events-list")
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* getEvents() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const q = query(colRef, where("createdBy", "==", user.id))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())
    const resolveData = yield Promise.all(
      data.map(async event => {
        if (event.venue.id) {
          var venueId = event.venue.id
          // get venue data
          const venueDocRef = doc(venueColRef, venueId)
          const venueDocData = await getDoc(venueDocRef)
          const venueData = venueDocData.data()
          if (!venueData) return
          const { latitude, longitude } = venueData.location
          const address = await getAddressFromLngLat(latitude, longitude)
          venueData.address = address
          // end get venue data
          event.venue = {
            ...venueData,
            bookedSlots: event?.venue?.bookedSlots || {},
          }
        }
        // get event managers data
        if (event.eventManagers) {
          var managersData = await Promise.all(
            event.eventManagers.map(async manager => {
              const eventManagerDocRef = doc(
                db,
                eventManagersCollection,
                manager.id
              )
              const docData = await getDoc(eventManagerDocRef)
              const managerData = docData.data()
              return {
                bookedSlots: manager.bookedSlots,
                ...managerData,
              }
            })
          )
          event.eventManagers = managersData
        }

        return event
      })
    )

    const sortedData = resolveData.sort(function (a, b) {
      return new Date(a.eventDate) - new Date(b.eventDate)
    })

    yield put(onGetEvents(sortedData))
  } catch (err) {
    console.log(err.message)
    yield put(apiError(err.message))
  }
}

function* updateEvent({ payload: { data, history } }) {
  try {
    // update venue attachments
    const uploadAttachments = yield Promise.all(
      data?.attachments?.map(attch => {
        if (typeof attch == "object") {
          return new Promise((resolve, reject) => {
            uploadBytes(ref(storage, `events/${v4()}`), attch)
              .then(res => {
                getDownloadURL(res.ref)
                  .then(res => {
                    resolve(res)
                  })
                  .catch(err => {
                    reject(err)
                  })
              })
              .catch(err => {
                reject(err)
              })
          })
        } else {
          return attch
        }
      })
    )

    //
    const docRef = doc(db, collectionName, data.id)

    yield call(updateDoc, docRef, {
      ...data,
      attachments: uploadAttachments,
    })
    history.push("/events-list")
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* getEventStatistics() {
  let statistics = {
    activeEvents: "0",
    totalEvents: "0",
    revenue: "0",
    monthlyEvents: "0",
    monthlyRevenue: "0",
  }
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const q = query(colRef, where("createdBy", "==", user.id))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc?.data())
    // filter active events
    let countActiveEvents = data.filter(item => item.status == "active")
    // set statistics
    statistics = {
      ...statistics,
      totalEvents: data?.length,
      activeEvents: countActiveEvents?.length,
    }

    yield put(onGetEventStatistics(statistics))
  } catch (err) {
    yield put(apiError(err.message))
  }
}

function* addEventByEventOrganizer({ payload: { events, history } }) {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const uploadAttachments = yield Promise.all(
      events[0]?.attachments?.map(async attch => {
        try {
          const res = await uploadBytes(ref(storage, `events/${v4()}`), attch)
          return await getDownloadURL(res.ref)
        } catch (err) {
          throw new Error(err.message)
        }
      })
    )
    for (const event of events) {
      const docRef = doc(colRef)
      let isFeaturedTaxApply = false
      const isFeatured = event.featured
      if (isFeatured) {
        const getUser = yield call(getManagerProfile, user.id)
        const featuredCount = getUser?.config?.noOfFeaturedEventsAllowed || 0
        if (featuredCount > 0) {
          isFeaturedTaxApply = false
          yield call(updateManagerProfile, user.id, {
            config: {
              ...(getUser.config || {}),
              noOfFeaturedEventsAllowed: featuredCount - 1,
            },
          })
        } else {
          isFeaturedTaxApply = true
        }
      }
      const uploadEvent = {
        ...event,
        isFeaturedTaxApply,
        eventDate: getShowDate(event.eventDate),
        eventType: event?.eventType?.label,
        requiredSeats: parseInt(event.requiredSeats),
        date: getShowDate(event.eventDate),
        eventLevel: event?.eventLevel?.label,
        requiredTime:
          event?.requiredTime.hour + " hr " + event?.requiredTime.min + " min",
        attachments: uploadAttachments,
        eventLocation: event.venue.address,
        managersId: [user.id],
        venue: {
          id: event?.venue?.id,
          bookedSlots: event?.venue?.bookedSlots,
        },
        eventManagers: event?.eventManagers?.map(manager => {
          return {
            id: manager?.id,
            bookedSlots: manager?.bookedSlots,
          }
        }),
        isManual: false,
        id: docRef.id,
        createdBy: user.id,
        status: "inProgress",
        createdAt: new Date(),
      }
      yield call(setDoc, docRef, uploadEvent)
      const eventId = docRef.id
      const eventDate = getShowDate(event.eventDate)

      // create order for event manager
      const eventManagers = event.eventManagers
      for (const manager of eventManagers) {
        const managerId = manager.id
        const bookedSlots = manager.bookedSlots || []
        for (const slot of bookedSlots) {
          const data = {
            event: eventId,
            bookingDate: eventDate,
            slot,
            createdTo: managerId,
          }
          yield call(createOrderForEventManager, { data })
        }
      }
      //create order for venue owner
      const venue = event.venue
      const venueBookedSlots = venue?.bookedSlots || []
      for (const slot of venueBookedSlots) {
        const data = {
          venue: venue.id,
          bookingDate: eventDate,
          slot: slot,
          event: eventId,
        }
        yield call(createOrderForVenueOwner, { data })
      }
    }
    history.push("/organizer-events")
  } catch (err) {
    yield put(apiError(err.message))
  }
}

// get event by event organizers
function* getEventsByEventOrganizer() {
  const user = JSON.parse(localStorage.getItem("authUser"))
  try {
    const q = query(colRef, where("createdBy", "==", user.id))
    const response = yield call(getDocs, q)
    const data = response.docs.map(doc => doc.data())
    const resolveData = yield Promise.all(
      data.map(async event => {
        var venueId = event.venue.id
        // get venue data
        const venueDocRef = doc(venueColRef, venueId)
        const venueDocData = await getDoc(venueDocRef)
        const venueData = venueDocData.data()
        if (!venueData) return
        const { latitude, longitude } = venueData.location
        const address = await getAddressFromLngLat(latitude, longitude)
        venueData.address = address
        // end get venue data

        // get event managers data
        var managersData = await Promise.all(
          event.eventManagers.map(async manager => {
            const eventManagerDocRef = doc(
              db,
              eventManagersCollection,
              manager.id
            )
            const docData = await getDoc(eventManagerDocRef)
            const managerData = docData.data()
            return {
              bookedSlots: manager.bookedSlots,
              ...managerData,
            }
          })
        )

        // end get event manager data
        return {
          ...event,
          eventManagers: managersData,
          venue: {
            ...venueData,
            bookedSlots: event.venue.bookedSlots,
          },
        }
      })
    )

    const sortedData = resolveData.sort(function (a, b) {
      return new Date(a.eventDate) - new Date(b.eventDate)
    })

    yield put(onGetEventsByEventOrganizer(sortedData))
  } catch (err) {
    yield put(apiError("Something Went Wrong! Try again"))
  }
}

// Delete Service

function* deleteEvent({ payload }) {
  try {
    const eventId = payload
    // check tickets
    const tickets = yield call(
      firebaseService.queryDocuments,
      COLLECTIONS.TICKETS,
      "eventId",
      "==",
      eventId
    )
    if (tickets.length > 0)
      throw new Error("Sorry! Unable to delete. Event contains tickets.")
    // check event connections.
    const orders = yield call(
      firebaseService.queryDocuments,
      COLLECTIONS.ORDERS,
      "event",
      "==",
      eventId
    )
    const validOrders = []
    orders.forEach(async order => {
      const { bookingDate } = order
      const eventDateStr = new Date(bookingDate)
      const eventDate = new Date(eventDateStr)
      const currentDate = new Date()
      let twoDaysBeforeEvent = new Date(eventDate)
      twoDaysBeforeEvent.setDate(eventDate.getDate() - 2)
      if (currentDate < twoDaysBeforeEvent) {
        validOrders.push(order.id)
      }
    })
    // check if orders are valid
    if (orders.length == validOrders.length) {
      const deleteOrders = orders.map(async order => {
        const { createdTo, bookingDate, slot } = order
        const manager = await firebaseService.getDocumentById(
          COLLECTIONS.MANAGERS,
          createdTo
        )
        if (manager && manager.role == "eventManager") {
          let { bookings = {} } = manager
          let bookedSlots = bookings[bookingDate] || []
          bookedSlots = bookedSlots.filter(slotId => slotId != slot.id)
          bookings[bookingDate] = bookedSlots
          await firebaseService.updateDocument(
            COLLECTIONS.MANAGERS,
            createdTo,
            {
              bookings,
            }
          )
        }
        if (manager && manager.role == "venueOwner") {
          const venueId = order.venue
          const venue = await firebaseService.getDocumentById(
            COLLECTIONS.VENUES,
            venueId
          )
          if (venue) {
            let { bookings = {} } = venue
            let bookedSlots = bookings[bookingDate] || []
            bookedSlots = bookedSlots.filter(slotId => slotId != slot.id)
            bookings[bookingDate] = bookedSlots
            await firebaseService.updateDocument(COLLECTIONS.VENUES, venueId, {
              bookings,
            })
          }
        }
        return await firebaseService.deleteDocumentById(
          COLLECTIONS.ORDERS,
          order.id
        )
      })
      yield Promise.all(deleteOrders)
      const remainingOrders = yield call(
        firebaseService.queryDocuments,
        COLLECTIONS.ORDERS,
        "event",
        "==",
        eventId
      )
      if (remainingOrders.length == 0) {
        yield call(
          firebaseService.deleteDocumentById,
          COLLECTIONS.EVENTS,
          eventId
        )
      } else {
        throw new Error("Sorry! Unable to delete. Something went wrong")
      }
    } else {
      throw new Error(
        "Sorry! Unable to delete. You can only delete 3 days before event."
      )
    }
    yield put(onDeleteEvent(eventId))
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* root() {
  yield takeEvery(ADD_EVENT, addEvents)
  yield takeEvery(ADD_EVENT_MANUAL_VENUE, addEventManualVenue)
  yield takeEvery(ADD_EVENT_BY_EVENT_MANAGER, addEventByEventManager)
  yield takeEvery(ADD_EVENT_BY_VENUE_OWNER, addEventByVenueOwner)
  yield takeEvery(ADD_EVENT_BY_EVENT_ORGANIZER, addEventByEventOrganizer)
  yield takeEvery(GET_EVENTS, getEvents)
  yield takeEvery(GET_EVENTS_BY_EVENT_ORGANIZER, getEventsByEventOrganizer)
  yield takeEvery(UPDATE_EVENT, updateEvent)
  yield takeEvery(GET_EVENT_STATISTICS, getEventStatistics)
  yield takeEvery(DELETE_EVENT, deleteEvent)
}

export default root
